import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import { useState } from "react";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import { SvgGetInfo } from "../../assets/SvgIcons";
import { Link } from "react-router-dom";
import InformationCustomPopup from "../CustomComponents/InformationCustomPopup/InformationCustomPopup";
const ReportAgentBusinessReport = ({ departureReport }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const extractedData = departureReport?.map((item) => ({
    unique_booking_id: item?.unique_booking_id,
    // guest_name: item?.guest[0]?.guest_name,
    // check_in: item?.check_in,
    // check_out: item?.check_out,
    // booking_source: item?.booking_source?.replace(/_/g, " "),
    // grand_total: item?.grand_total,
    // paid_amount: item?.paid_amount,
    // balance_amount: item?.balance_amount,
    // status: item?.status?.replace(/_/g, " "),
  }));

  // console.log("departureReport", departureReport);

  const [informationPopup, setInformationPopup] = useState(false);

  const names = [
    {
      unique_booking_id: "Booking ID",
      guest_name: "Guest Name",
      check_in: "Arrival Date",
      check_out: "Departure Date",
      booking_source: "Booking Source",
      grand_total: "Grand Total",
      paid_amount: "Paid Amount",
      grand_total: "Balance",
      status: "Status",
    },
  ];
  const rowMarkup = departureReport?.map((order) => (
    <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
      <CustomReportTableCell>
        {/* <Link style={{ color: "blue" , textDecoration:"underline"}} to={`/agents/city/Ledger/${order.agent_information_id}`}> </Link> */}
       
        <span className="">
        {order?.agent_name || "-"}
        </span>
        </CustomReportTableCell>
      {/* <CustomReportTableCell>
        {order?.guest[0]?.guest_name}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {" "}
        <TextDateFormatter as={"span"} showYear={true} date={order.check_in} />
      </CustomReportTableCell>
      <CustomReportTableCell>
        <TextDateFormatter as={"span"} showYear={true} date={order.check_out} />
      </CustomReportTableCell> */}
      <CustomReportTableCell>
        <div style={{ textTransform: "capitalize" }}>
          {order &&
            order.agent_category &&
            order?.agent_category.replace(/_/g, " ")}
        </div>
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.balance_amount ? (
          formatIndianCurrency(order.balance_amount)
        ) : (
          <>-</>
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order?.balance_amount ? (
          formatIndianCurrency(order.used_amount)
        ) : (
          <>-</>
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {" "}
        {order?.balance_amount ? (
          formatIndianCurrency(order.credit_amount)
        ) : (
          <>-</>
        )}
      </CustomReportTableCell>

      <CustomReportTableCell>
        {order && order.no_of_rooms}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {order && order.no_of_nights}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(order && order.used_amount)}
      </CustomReportTableCell>
    </CustomReportTableRow>
  ));

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell ><b>
      {"Totals"}</b></CustomReportTableCell>
      <CustomReportTableCell ></CustomReportTableCell>

      <CustomReportTableCell>
        <b>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "balance_amount")
        )}
        </b>
      </CustomReportTableCell>
      <CustomReportTableCell>
       <b> {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "used_amount")
        )}</b>

      </CustomReportTableCell>
      <CustomReportTableCell>
        <b>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "credit_amount")
        )}
        </b>
      </CustomReportTableCell>
      <CustomReportTableCell>
        <b>
        {
          departureReport.reduce((total, item) => Number(total) + Number(item.no_of_rooms), 0)
        }
        </b>
      </CustomReportTableCell>
      <CustomReportTableCell>
       <b>
       { departureReport.reduce((total, item) => Number(total) + Number(item.no_of_nights), 0)}
       </b>
      </CustomReportTableCell>
      <CustomReportTableCell>
        <b>
        {
          formatIndianCurrency(departureReport.reduce((total, item) => Number(total) + Number(item.used_amount), 0))
        }
        </b>
      </CustomReportTableCell>
      
    </CustomReportTableRow>
  );
  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
            <div className="room-type-heading">Consolidated Agent Business Report</div>
            <div
              className="hoverCursor"
              onClick={() => setInformationPopup(true)}
              id="ignoreFromPrint"
            >
              <SvgGetInfo />
            </div>
          </div>
          {departureReport?.length > 0 && (
            <div className="flex flex_gap_15" id="ignoreFromPrint">
              <ButtonToDownloadTablePDF
                itemToDownloadID={"receipt"}
                downloadFileName={"Agent Business Report"}
                ignoreFromPrint={"ignoreFromPrint"}
                longPdf={true}
              />
             

              <DownloadCSVButton
                downloadFileName="AccountBalanceReport"
                itemToDownloadID={"receipt"}
              />
            </div>
          )}
        </div>

        {departureReport && departureReport.length > 0 ? (
          <>
            {/* <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={departureReport.length}
                selectable={false}
                headings={[
                  { title: "Booking ID" },
                  { title: "Guest Name" },
                  { title: "Arrival Date" },
                  { title: "Departure Date" },
                  { title: "Source" },
                  { title: "Grand Total" },
                  { title: "Amount Paid" },
                  { title: "Balance" },
                  { title: "Status" },
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard> */}

            <CustomReportTable
              headings={[
                "Agent Name",
                "Category",
                "Present Credit Limit (₹)",
                "Opening Balance (₹)",
                "Closing Balance",
                "No. Of Rooms",
                "No. Of Nights",
                "Business (₹)",
                // "MTD",
                // "YTD",
                // "MTD",
                // "YTD",
                // "MTD",
                // "YTD",
              ]}
              // commonHeadings={[
              //   "",
              //   "",
              //   "",
              //   "",
              //   "",
              //   // "No. Of Rooms",
              //   "No. Of Rooms",
              //   "Room Nights",
              //   // "Room Nights",
              //   "Business (₹)",
              //   // "Business (₹)",
              // ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
                // textAlign: "left",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}
              {calculateTotals}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}

        {informationPopup && (
          <InformationCustomPopup
            setInformationPopup={setInformationPopup}
            title="Information of Agent Business Report"
          >
            <div className="mb-20">
              <AccordionComponent
                questions={[
                  {
                    question: "What is the Agent Business Report?",
                    answer: (
                      <p>
                        The <b>Agent Business Report</b> provides a detailed
                        overview of performance metrics related to various
                        booking agents over a specified period. It includes key
                        data points such as the agent name, category, present
                        credit limit, opening and closing balances, the number
                        of rooms booked, room nights, and business generated (in
                        monetary terms) for both month-to-date (MTD) and
                        year-to-date (YTD). This report is essential for
                        understanding the contributions of different agents to
                        the hotel’s overall performance.
                      </p>
                    ),
                  },
                  {
                    question:
                      "What is the Importance of the Agent Business Report?",
                    answer: (
                      <div>
                        <p>
                          The Agent Business Report is important for several
                          reasons:
                        </p>

                        <ul>
                          <li>
                            <b>Performance Tracking: </b>It helps in monitoring
                            the performance of different booking agents,
                            allowing management to identify top performers and
                            areas for improvement.
                          </li>
                          <li>
                            <b>Financial Management: </b>Provides insights into
                            credit limits, balances, and overall business
                            generated, which is crucial for managing cash flow
                            and financial planning.
                          </li>
                          <li>
                            <b>Relationship Management: </b> By analyzing agent
                            performance, management can foster better
                            relationships with high-performing agents and
                            address concerns with underperformers.
                          </li>
                          <li>
                            <b>Strategic Decision-Making: </b>The report informs
                            decisions on marketing strategies, commission
                            structures, and partnerships to enhance business
                            growth.
                          </li>
                          <li>
                            <b>Operational Efficiency: </b> Understanding
                            booking trends through agents helps optimize
                            inventory management and resource allocation.
                          </li>
                        </ul>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </InformationCustomPopup>
        )}
      </div>
    </>
  );
};
export default ReportAgentBusinessReport;
