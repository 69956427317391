import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "./NoDataFound";
import CustomCssComponentTableText from "./CustomCssComponentTableText";

const ReportByRoomType = ({ regionReportsData }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const rowMarkup =
    regionReportsData &&
    regionReportsData?.map(({ booking_count, meal_plan, state }, index) => {
      return (
        <IndexTable.Row id={index} key={index} position={meal_plan}>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={meal_plan}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={booking_count}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    });

  return (
    <>
      <div className="room-types-full-container">
        <div className="room-types-full-container-div">
          <div className="room-type-heading">Reservation By Room Categories</div>
        </div>

        {regionReportsData && regionReportsData.length > 0 ? (
          <>
            <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={regionReportsData.length}
                selectable={false}
                headings={[{ title: "Plan Name" }, { title: "Booking Count" }]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
};
export default ReportByRoomType;
