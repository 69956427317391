import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import InformationCustomPopup from "../CustomComponents/InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { useState } from "react";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";

const ReportRoomAssignment = ({
  departureReport,
  getSingleBookingDataHandler,
}) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const extractedData = departureReport?.map((item) => ({
    unique_booking_id: item?.unique_booking_id,
    guest_name: item?.guest[0]?.guest_name,
    check_in: item?.check_in,
    check_out: item?.check_out,
    room_type_name: item?.items[0]?.room_type_name,
    assigned_room: item?.assignedrooms.map((room) => room.assigned_room),
    status: item?.status?.replace(/_/g, " "),
  }));
  const [informationPopup, setInformationPopup] = useState(false);

  const names = [
    {
      unique_booking_id: "Booking ID",
      guest_name: "Name",
      check_in: "Arrival Date",
      check_out: "Departure Date",
      room_type_name: "Room category",
      assigned_room: "Assigned Room",
      status: "Status",
    },
  ];

  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>
          <div
            className="pointer"
            style={{
              color: "rgba(57, 104, 237, 1)",
            }}
            onClick={() => getSingleBookingDataHandler(order.unique_booking_id)}
          >
            #{order.unique_booking_id || ""}
          </div>
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.guest[0].guest_name}
        </CustomReportTableCell>
        <CustomReportTableCell>
          <TextDateFormatter
            as={"span"}
            showYear={true}
            date={order.check_in}
          />
        </CustomReportTableCell>

        <CustomReportTableCell>
          <TextDateFormatter
            as={"span"}
            showYear={true}
            date={order.check_out}
          />
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order?.items.map((item, innerIndex) => (
            <span key={innerIndex}>
              {item.room_type_name} {innerIndex < order?.items?.length - 1 && ","}
              &nbsp;
            </span>
          ))}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order?.assignedrooms?.length > 0 ? (
            <div>
              {order.assignedrooms.map((room, innerIndex) => (
                <span key={innerIndex}>
                  {room.assigned_room} {innerIndex < order?.assignedrooms?.length - 1 && ","} &nbsp;
                </span>
              ))}
            </div>
          ) : (
            <div>N/A</div>
          )}
        </CustomReportTableCell>
        <CustomReportTableCell>
          <StatusUpdate status={order.status} /> {}
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));

  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
              <div className="room-type-heading">Room Assignment Report</div>
              <div
                className="hoverCursor"
                onClick={() => setInformationPopup(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                  fill="#3968ED"
                >
                  <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
                </svg>
              </div>
            </div>
            {departureReport?.length > 0 && (
              <div className="flex flex_gap_15">
                <ButtonToDownloadTablePDF
                  itemToDownloadID={"receipt"}
                  longPdf={true}
                  downloadFileName={"assigned_rooms_report"}
                />
                <DownloadCSVButton
                  itemToDownloadID={"receipt"}
                  downloadFileName={"assigned_rooms_report"}
                />
              </div>
            )}
          </div>
        </div>

        {departureReport && departureReport.length > 0 ? (
          <>
            {/* <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={departureReport.length}
                selectable={false}
                headings={[
                  { title: "Booking ID" },
                  { title: "Guest Name" },
                  { title: "Arrival Date" },
                  { title: "Arrival Date" },
                  { title: "Room Type" },
                  { title: "Assigned Rooms" },
                  { title: "Status" },
                ]}
              >
                {rowMarkup}
              </IndexTable>
            </LegacyCard> */}

            <CustomReportTable
              headings={[
                "Booking ID",
                "Guest Name",
                "Arrival Date",
                "Departure Date",
                "Room Type",
                "Assigned Rooms",
                "Status",
              ]}
              // commonHeadings={[
              //   "",
              //   "",
              //   "",
              //   "",
              //   "Quantity",
              //   "Quantity",
              //   "Quantity",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Total Purchase Amount  (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              //   "Average Purchase Cost per unit (₹)",
              // ]}
              headerStyles={{
                backgroundColor: "#dddddd",
                fontSize: "12px",
              }}
              tableStyles={{ textAlign: "left" }}
            >
              {rowMarkup}
              {/* {calculateTotals} */}
            </CustomReportTable>
          </>
        ) : (
          <NoDataFound />
        )}

        {informationPopup && (
          <InformationCustomPopup
            setInformationPopup={setInformationPopup}
            title="Room Assignment Report"
          >
            <div className="mb-20">
              {/* <h2 className="mb-10"> Accordion Component </h2> */}
              <AccordionComponent
                questions={[
                  {
                    question: "What is the Room Assignment Report?",
                    answer: (
                      <p>
                        The <b>Room Assignment</b> Report provides a detailed
                        summary of room assignments for each booking within a
                        selected date range (current and past). This report
                        includes crucial information such as booking IDs, guest
                        names, room categories, number of rooms booked, assigned
                        room numbers, and the status of the stay. It is
                        essential for tracking room allocation and ensuring that
                        the hotel’s resources are effectively managed.
                      </p>
                    ),
                  },
                  {
                    question: "Why is the Room Assignment Report Important?",
                    answer: (
                      <div>
                        <p>
                          The Room Assignment Report is vital for several
                          reasons:
                        </p>

                        <ul>
                          <li>
                            <b>Optimized Room Management: </b>Track the
                            allocation of rooms to ensure that guests are
                            assigned rooms according to their booking
                            preferences, and that no rooms are double-booked.
                          </li>
                          <li>
                            <b>Operational Efficiency: </b>The report helps
                            front desk and housekeeping staff manage check-ins,
                            check-outs, and in-house guest needs efficiently.
                          </li>
                          <li>
                            <b>Guest Satisfaction: </b>By ensuring accurate room
                            assignments and timely check-in, the report supports
                            a smoother guest experience.
                          </li>
                          <li>
                            <b>Revenue Tracking: </b>Understand how room
                            categories are being utilized to optimize pricing
                            strategies and revenue generation.
                          </li>
                        </ul>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </InformationCustomPopup>
        )}
      </div>
    </>
  );
};
export default ReportRoomAssignment;
