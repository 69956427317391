import { Icon, IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "../CustomComponents/NoDataFound";
import CustomCssComponentTableText from "../CustomComponents/CustomCssComponentTableText";
import TextDateFormatter from "../Communication/TextDateFormatter/TextDateFormatter";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import UnderscoreRemoveCapitalize from "../CustomComponents/UnderscoreRemoveCapitalize/UnderscoreRemoveCapitalize";
import Loader from "../UI/Loader/Loader";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import DownloadPdfTables from "../CustomComponents/DownloadPdfTables/DownloadPdfTables";
import DownloadCsvTables from "../CustomComponents/DownloadCsvTables/DownloadCsvTables";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/SvgIcons";
import { showErrorToast } from "../../assets/toastUtils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { CancelMajor } from "@shopify/polaris-icons";
import BookingDetailSliderNew from "../CustomComponents/BookingDetails/BookingDetailsSliderNew";
import { useNavigate } from "react-router-dom";
import InformationCustomPopup from "../CustomComponents/InformationCustomPopup/InformationCustomPopup";

const ReportCityLedgerReport = ({
  departureReport,
  loader,
  fullData,
  donShowTitle,
  property_id,
  userToken,
}) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const navigate = useNavigate();
  const [informationPopup, setInformationPopup] = useState(false);
  const rowMarkup =
    departureReport &&
    departureReport.length > 0 &&
    departureReport?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>
          {" "}
          <TextDateFormatter date={order.date} showYear={true} as={"span"} />
        </CustomReportTableCell>

        <CustomReportTableCell>
          {" "}
          {order.invoice_no ? (
            <span
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => handleSubmitPreview(order?.folio_id)}
            >
              {" "}
              #{order.invoice_no}
            </span>
          ) : (
            "-"
          )}
          {/* {order.invoice_no ? <div>#{order.invoice_no}</div> : "-"} */}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {" "}
          {order.booking_id ? (
            <span
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() =>
                getSingleBookingDataHandler(order.unique_booking_id)
              }
            >
              #{order.unique_booking_id}
            </span>
          ) : (
            "-"
          )}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {" "}
          {order.folio_no ? (
            <span
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() =>
                navigate(
                  `/bookings/folio/${order?.booking_id}/${order?.folio_id}`
                )
              }
            >
              #{order.folio_no}
            </span>
          ) : (
            "-"
          )}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {" "}
          {order.kot_no ? <div>#{order.kot_no}</div> : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(order.opening_balance)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(order.total_credits)}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {formatIndianCurrency(order.transaction_amount)}
        </CustomReportTableCell>
        <CustomReportTableCell>{order.transaction_type}</CustomReportTableCell>
        <CustomReportTableCell>
          {order.used_credit_limit
            ? formatIndianCurrency(order.used_credit_limit)
            : "0"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.available_credit_limit
            ? formatIndianCurrency(order.available_credit_limit)
            : "0"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order.available_credit_limit
            ? formatIndianCurrency(order.closing_balance)
            : "0"}
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));

  // const downloadData = departureReport.map((report) => {
  //   return {
  //     date: report.date,
  //     guest_name: report.guest_name ? report.guest_name : "N/A",
  //     reference: report.guest_name
  //       ? `#${report.unique_booking_id} Folio-${report.folio_no} (${report.room_no})`
  //       : "Paid Credit",
  //     credits: report.credit_amount,
  //     debits: report.debit_amount,
  //     available_credit_limit: report.available_credit_limit,
  //   };
  // });

  const names = [
    {
      date: "Date",
      guest_name: "Guest Name",
      reference: "Ref",
      credits: "Credits",
      debits: "Debits",
      available_credit_limit: "Balance",
    },
  ];

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell colSpan={4}><b>{"Total"}</b></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell>
       <b>
       {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "opening_balance")
        )}
       </b>
      </CustomReportTableCell>
      <CustomReportTableCell>
        <b>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "total_credits")
        )}
        </b>
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>

      <CustomReportTableCell>
        <b>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "used_credit_limit")
        )}
        </b>
      </CustomReportTableCell>
      <CustomReportTableCell>
        <b>
        {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "available_credit_limit")
        )}
        </b>
      </CustomReportTableCell>
      <CustomReportTableCell>
       <b>
       {formatIndianCurrency(
          calculateTotalWithReduce(departureReport, "closing_balance")
        )}
       </b>
      </CustomReportTableCell>
    </CustomReportTableRow>
  );

  const [openSingleBookingDetails, setOpenSingleBookingDetails] =
    useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [folios, setFolios] = useState([]);
  const [chargesDetails, setChargesDetails] = useState(null);
  const [masterFolioDetails, setMasterFolioDetails] = useState(null);

  const getSingleBookingDataHandler = (bookingId) => {
    // setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOpenSingleBookingDetails(true);
          // setLoader(false);
          setBookingDetails(data?.booking);
          setChargesDetails(data?.charges);
          setMasterFolioDetails(data?.masterFolio);
          setFolios(data?.allFolios);
        } else {
          // setLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        // setLoader(false);
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const [iframeSrc, setIframeSrc] = useState("");
  const [showIframePopup, setShowIframePopup] = useState(false);
  const [showBillPreview, setShowBillPreview] = useState(false);
  const [iframeContent, setIframeContent] = useState("");
  const [downloadUrl, setDownloadUrl] = useState("");

  const handleSubmitPreview = async (booking_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomBillPreview&previewId=${booking_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const iframeContent = await response.text(); // API response as text (iframe content)

      // Save iframe content and control states
      setIframeSrc(iframeContent);
      setIframeContent(iframeContent);
      setShowIframePopup(true);
      setShowBillPreview(true);
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      // setLoader(false);
    }
  };

  const printIframe = (id) => {
    // setIsLoading(false);
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  const handleDownload = () => {
    const iframe = document.getElementById("receipt");
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;

    // Get the dimensions of the iframe
    const iframeWidth = iframe.offsetWidth;
    const iframeHeight = iframe.offsetHeight;

    // Use html2canvas to render the iframe content as an image
    html2canvas(iframeDocument.body, {
      scale: 0.5, // Increase scale for better image quality
      width: iframeWidth,
      height: iframeHeight,
      logging: false,
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Create a new jsPDF instance
      const pdf = new jsPDF("p", "in", "letter");

      // Get the page size (letter size is 8.5x11 inches)
      const pageWidth = 8.5; // Width in inches
      const pageHeight = 11; // Height in inches

      // Scale the image to fit within the page dimensions
      const imgWidth = pageWidth * 72; // Convert inches to points (1 inch = 72 points)
      const imgHeight = (iframeHeight * imgWidth) / iframeWidth; // Scale proportionally

      // If the image height is too large, scale it down to fit within the page height
      if (imgHeight > pageHeight * 72) {
        const scaleFactor = (pageHeight * 72) / imgHeight;
        imgWidth *= scaleFactor;
        imgHeight *= scaleFactor;
      }

      // Add the image to the PDF (adjust coordinates to center it on the page)
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth / 72, imgHeight / 72); // Convert from points to inches

      // Save the PDF with a name
      pdf.save("receipt.pdf");
    });
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container" id="cityLedgerReport">
          <div className="room-types-full-container-div">
            <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
              <div className="room-type-heading">City Ledger Report</div>
              <div
                className="hoverCursor"
                onClick={() => setInformationPopup(true)}
                id="ignoreFromPrint"
              >
                <SvgGetInfo />
              </div>
            </div>
            {departureReport?.length > 0 && (
              <div className="flex flex_gap_15" id="ignoreFromPrint">
                <ButtonToDownloadTablePDF
                  itemToDownloadID={"cityLedgerReport"}
                  downloadFileName={"cityLedgerReport"}
                  ignoreFromPrint={"ignoreFromPrint"}
                />
                <DownloadCSVButton
                  itemToDownloadID={"cityLedgerReport"}
                  downloadFileName={"cityLedgerReport"}
                />
              </div>
            )}
          </div>

          <div style={{ display: "flex", gap: "30px", marginBottom: "20px" }}>
            <div>
              <div>
                {" "}
                <strong>Total Credit Limit</strong> :{" "}
                {formatIndianCurrency(fullData?.companyCredit?.credit_amount)}
              </div>
            </div>
            <div>
              <div>
                {" "}
                <strong>Available Credit Limit</strong> :{" "}
                {formatIndianCurrency(fullData?.companyCredit?.balance_amount)}
              </div>
            </div>
            <div>
              <div>
                {" "}
                <strong>Used Credit Limit</strong> :{" "}
                {formatIndianCurrency(fullData?.companyCredit?.used_amount)}
              </div>
            </div>
          </div>
          {departureReport && departureReport.length > 0 ? (
            <>
              {/* <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={departureReport.length}
                  selectable={false}
                  headings={[
                    { title: "Date" },
                    { title: "Guest Name" },
                    { title: "Reference" },
                    { title: "KOT No" },
                    { title: "Total Credits" },
                    { title: "Credits " },
                    { title: "Debits" },
                    { title: "Balance" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>
              </LegacyCard> */}

              <CustomReportTable
                allItems={departureReport} // table data
                // selectedItems={selectedArray} //selected items from table
                // setSelectedItems={setSelectedArray} // state from parent component to update table
                showCheckbox={false}
                headings={[
                  "Invoice Date",
                  "Invoice No",
                  "Booking ID",
                  "Folio No.",
                  "KOT No.",
                  "Opening Balance",
                  "Credit Limit",
                  "Transaction Amount",
                  "Transaction Type",
                  "Used Credits",
                  "Available Credits",
                  "Closing Balance",
                ]}
                headerStyles={{
                  backgroundColor: "#dddddd",
                  fontSize: "12px",
                  // textAlign: "center",
                }}
                tableStyles={{ textAlign: "left" }}
              >
                {rowMarkup}
                {calculateTotals}
              </CustomReportTable>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of City Ledger Report"
        >
          <div className="mb-20">
            {/* <h2 className="mb-10"> Accordion Component </h2> */}
            <AccordionComponent
              questions={[
                {
                  question: "What is the City Ledger Report?",
                  answer: (
                    <p>
                      The <b>City Ledger Report</b>, also known as the Agents
                      Credit Report, provides a detailed summary of credit
                      transactions related to bookings managed through various
                      agents. It includes essential data such as invoice dates,
                      invoice numbers, opening and closing balances, booking
                      IDs, folio numbers, KOT (Kitchen Order Ticket) numbers,
                      and credit or debit amounts. This report is vital for
                      tracking financial transactions, managing agent credits,
                      and ensuring accurate billing.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the City Ledger Report/Agents Credit Report?",
                  answer: (
                    <div>
                      <p>
                        The City Ledger Report is important for several reasons:
                      </p>

                      <ul>
                        <li>
                          <b>Financial Oversight: </b>It allows management to
                          monitor outstanding balances and transactions
                          associated with agents, ensuring financial accuracy.
                        </li>
                        <li>
                          <b>Transaction Tracking: </b>By detailing credit
                          amounts, debits, and available credits, the report
                          helps in managing credit limits effectively, reducing
                          the risk of overdue payments.
                        </li>
                        <li>
                          <b>Credit Management: </b>It provides a comprehensive
                          view of all transactions, aiding in audits and
                          ensuring compliance with financial regulations.
                        </li>
                        <li>
                          <b>Operational Efficiency: </b>Understanding credit
                          usage and transaction types enables better resource
                          allocation and enhances operational processes.
                        </li>
                        <li>
                          <b>Improved Cash Flow Management: </b>The report helps
                          in forecasting cash flow needs by analyzing credit and
                          debit activities, allowing for more effective
                          financial planning.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}

      {showIframePopup && (
        <>
          <div className="bg-backdrop">
            <section className="communication_iframe_container">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                  padding: "10px",
                }}
              >
                <div
                  className="add-ota-model-container-heading"
                  style={{ display: "flex", gap: "10px" }}
                >
                  Tax Invoice
                  <div
                    onClick={() => printIframe("receipt")}
                    style={{ cursor: "pointer" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                    >
                      <path
                        d="M11.0007 2.66667H3.00065V0H11.0007V2.66667ZM11.0007 6.33333C11.1895 6.33333 11.348 6.26933 11.476 6.14133C11.604 6.01333 11.6678 5.85511 11.6673 5.66667C11.6673 5.47778 11.6033 5.31933 11.4753 5.19133C11.3473 5.06333 11.1891 4.99956 11.0007 5C10.8118 5 10.6533 5.064 10.5253 5.192C10.3973 5.32 10.3335 5.47822 10.334 5.66667C10.334 5.85556 10.398 6.014 10.526 6.142C10.654 6.27 10.8122 6.33378 11.0007 6.33333ZM9.66732 10.6667V8H4.33398V10.6667H9.66732ZM11.0007 12H3.00065V9.33333H0.333984V5.33333C0.333984 4.76667 0.528429 4.29156 0.917318 3.908C1.30621 3.52444 1.77843 3.33289 2.33398 3.33333H11.6673C12.234 3.33333 12.7091 3.52511 13.0927 3.90867C13.4762 4.29222 13.6678 4.76711 13.6673 5.33333V9.33333H11.0007V12Z"
                        fill="#3968ED"
                      />
                    </svg>
                  </div>
                  <div onClick={handleDownload} style={{ cursor: "pointer" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M6.99957 10.1107L3.11068 6.22179L4.19957 5.09401L6.22179 7.11623V0.777344H7.77734V7.11623L9.79957 5.09401L10.8885 6.22179L6.99957 10.1107ZM2.3329 13.2218C1.90512 13.2218 1.53879 13.0693 1.2339 12.7645C0.929012 12.4596 0.776827 12.0935 0.777345 11.6662V9.3329H2.3329V11.6662H11.6662V9.3329H13.2218V11.6662C13.2218 12.094 13.0693 12.4603 12.7645 12.7652C12.4596 13.0701 12.0935 13.2223 11.6662 13.2218H2.3329Z"
                        fill="#3968ED"
                      />
                    </svg>
                  </div>
                  {downloadUrl && (
                    <a href={downloadUrl} download="receipt.html">
                      Download
                    </a>
                  )}
                </div>
                <div
                  onClick={() => setShowIframePopup(false)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={CancelMajor} color="base" />
                </div>
              </div>
              <div style={{ background: "#ddddd", height: "100%" }}>
                <iframe srcDoc={iframeContent} title="Receipt" id="receipt" />
              </div>
            </section>
          </div>
        </>
      )}

      {openSingleBookingDetails && (
        <BookingDetailSliderNew
          folios={folios}
          data={bookingDetails}
          chargesDetails={chargesDetails}
          masterFolioDetails={masterFolioDetails}
          setShowBookingDetails={setOpenSingleBookingDetails}
          getSingleBookingDataHandler={getSingleBookingDataHandler}
        />
      )}
    </>
  );
};
export default ReportCityLedgerReport;
