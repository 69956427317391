import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider, Page } from "@shopify/polaris";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import { store } from "./reduxToolkit/Store/store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <AppProvider i18n={enTranslations}>
        <PolarisVizProvider>
          <App />
        </PolarisVizProvider>
      </AppProvider>
    </Provider>
  // </React.StrictMode>
);

reportWebVitals();
