import React, { useEffect, useState } from "react";
 
const StatusUpdate = ({ status }) => {
  const booked = (
    <span
      style={{
        color: "#E03838",
        padding: "6px",
        backgroundColor: "#FFEAEA",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
      }}
    >
      Booked
    </span>
  );
 
  const occupied = (
    <span
      style={{
        color: "#E0AE18",
        padding: "6px",
        backgroundColor: "#FFF4D2",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Occupied
    </span>
  );
 
  const available = (
    <span
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
      }}
    >
      Clean
    </span>
  );
 
  const In_progress = (
    <span
      style={{
        color: "#E0AE18",
        padding: "3px 6px",
        backgroundColor: "#FFF4D2",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      In progress
    </span>
  );
  const partially_issued = (
    <span
      style={{
        color: "#FFF",
        padding: "3px 6px",
        backgroundColor: "rgba(189, 148, 27, 1)",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Partially Issued
    </span>
  );
  const active = (
    <span
      style={{
        color: "#fff",
        padding: "6px",
        backgroundColor: "#15AA12",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Active
    </span>
  );
  const in_active = (
    <span
      style={{
        color: "#fff",
        padding: "6px",
        backgroundColor: "rgba(136, 136, 136, 1)",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Inactive
    </span>
  );
 
  const clean = (
    <span
      style={{
        color: "#3968ED",
        padding: "6px",
        backgroundColor: "#ECF1FD",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Clean
    </span>
  );
 
  const newRequest = (
    <span
      style={{
        color: "#fff",
        padding: "6px",
        backgroundColor: "rgba(57, 104, 237, 1)",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "10px",
      }}
    >
      New Request
    </span>
  );
 
  const issued = (
    <span
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Issued
    </span>
  );
 
  const confirmed = (
    <span
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Confirmed
    </span>
  );
  const low_stock = (
    <span
      style={{
        color: "#fff",
        padding: "6px",
        backgroundColor: "rgba(189, 148, 27, 1)",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "10px",
        width: "100px",
        justifyContent: "center",
      }}
    >
      Low Stock
    </span>
  );
  const in_stock = (
    <span
      style={{
        color: "#fff",
        padding: "6px",
        backgroundColor: "rgba(21, 170, 18, 1)",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "10px",
        width: "100px",
        justifyContent: "center",
      }}
    >
      In Stock
    </span>
  );
  const out_of_stock = (
    <span
      style={{
        color: "#fff",
        padding: "6px",
        backgroundColor: "rgba(224, 56, 56, 1)",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "10px",
        width: "100px",
        justifyContent: "center",
      }}
    >
      Out Of Stock
    </span>
  );
 
  const send = (
    <span
      style={{
        color: "#FFFFFF",
        padding: "5px 12px",
        backgroundColor: "#15AA12",
        display: "inline-flex",
        borderRadius: "15px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Sent
    </span>
  );
  const sent = (
    <span
      style={{
        color: "#FFFFFF",
        padding: "5px 12px",
        backgroundColor: "#15AA12",
        display: "inline-flex",
        borderRadius: "15px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Sent
    </span>
  );
  const paid = (
    <span
      style={{
        color: "#FFFFFF",
        padding: "5px 12px",
        backgroundColor: "#15AA12",
        display: "inline-flex",
        borderRadius: "15px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Paid
    </span>
  );
  const unpaid = (
    <span
      style={{
        color: "#FFFFFF",
        padding: "5px 12px",
        backgroundColor: "rgba(136, 136, 136, 1)",
        display: "inline-flex",
        borderRadius: "15px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Un-Paid
    </span>
  );
  const noShow = (
    <span
      style={{
        color: "#FFFFFF",
        padding: "5px 12px",
        backgroundColor: "rgba(136, 136, 136, 1)",
        display: "inline-flex",
        borderRadius: "15px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      No-Show
    </span>
  );
 
  const inspected = (
    <span
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
      }}
    >
      Inspected
    </span>
  );
 
  const upcoming = (
    <span
      style={{
        color: "#3968ED",
        padding: "6px",
        backgroundColor: "#ECF1FD",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
      }}
    >
      Upcoming
    </span>
  );
 
  const completed = (
    <span
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
      }}
    >
      Completed
    </span>
  );
 
  const checked_out = (
    <span
      style={{
        color: "#FFF",
        padding: "6px",
        backgroundColor: "#FF8040",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Checked Out
    </span>
  );
 
  const checked_in = (
    <span
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Checked In
    </span>
  );
 
  const dirty = (
    <span
      style={{
        color: "#E03838",
        padding: "6px",
        backgroundColor: "#FFEAEA",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Dirty
    </span>
  );
 
  const availableNo = (
    <span
      style={{
        color: "#E03838",
        padding: "6px",
        backgroundColor: "#FFEAEA",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Not Available
    </span>
  );
 
  const cancelled = (
    <div
      style={{
        color: "#E03838",
        padding: "6px",
        backgroundColor: "#FFEAEA",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Cancelled
    </div>
  );
 
  const out_of_order = (
    <div
      style={{
        color: "#888",
        padding: "6px",
        backgroundColor: "#EEE",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Out Of Order
    </div>
  );
 
  const pending = (
    <div
      style={{
        color: "#888",
        padding: "6px",
        backgroundColor: "#EEE",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Pending
    </div>
  );
 
  const draft = (
    <div
      style={{
        color: "#888",
        padding: "6px",
        backgroundColor: "#EEE",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Draft
    </div>
  );
 
  const vacant = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "600",
        fontSize: "12px",
      }}
    >
      Vacant
    </div>
  );
  const availableYes = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontSize: "12px",
        fontWeight: "600",
      }}
    >
      Available
    </div>
  );
 
  useEffect(() => {
    if (status === "clean") {
      setButtonStatus(clean);
    } else if (status === "dirty") {
      setButtonStatus(dirty);
    } else if (status === "inspected") {
      setButtonStatus(inspected);
    } else if (status === "occupied") {
      setButtonStatus(occupied);
    } else if (status === "pending") {
      setButtonStatus(pending);
    } else if (status === "confirmed") {
      setButtonStatus(confirmed);
    } else if (status === "upcoming") {
      setButtonStatus(upcoming);
    } else if (status === "completed") {
      setButtonStatus(completed);
    } else if (status === "checked_out") {
      setButtonStatus(checked_out);
    } else if (status === "cancelled") {
      setButtonStatus(cancelled);
    } else if (status === "checked_in") {
      setButtonStatus(checked_in);
    } else if (status === "out_of_order") {
      setButtonStatus(out_of_order);
    } else if (status === "send") {
      setButtonStatus(send);
    } else if (status === "sent") {
      setButtonStatus(sent);
    } else if (status === "draft") {
      setButtonStatus(draft);
    } else if (status === "vacant") {
      setButtonStatus(vacant);
    } else if (status === "availableYes") {
      setButtonStatus(availableYes);
    } else if (status === "availableNo") {
      setButtonStatus(availableNo);
    } else if (status === "partially_issued") {
      setButtonStatus(partially_issued);
    } else if (status === "out-of-stock") {
      setButtonStatus(out_of_stock);
    } else if (status === "in-stock") {
      setButtonStatus(in_stock);
    } else if (status === "low-stock") {
      setButtonStatus(low_stock);
    } else if (status === "In progress") {
      setButtonStatus(In_progress);
    } else if (status === "1") {
      setButtonStatus(active);
    } else if (status === "issued") {
      setButtonStatus(issued);
    } else if (status === "new-request") {
      setButtonStatus(newRequest);
    } else if (status === "paid") {
      setButtonStatus(paid);
    } else if (status === "unpaid") {
      setButtonStatus(unpaid);
    } else if (status === "no_show") {
      setButtonStatus(noShow);
    } else if (status === "0") {
      setButtonStatus(in_active);
    } else if (status === 1) {
      setButtonStatus(active);
    } else if (status === 0) {
      setButtonStatus(in_active);
    } else {
      // setButtonStatus(out_of_order);
    }
  }, [status]);
 
  const [buttonStatus, setButtonStatus] = useState(status);
 
  return <div>{buttonStatus}</div>;
};
 
export default StatusUpdate;
 
 