import { Route, Routes, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar/Navbar";
import Sidebar from "../Components/Sidebar";
import { useEffect, useState } from "react";
import ProtectedRoutes from "../Components/Auth/ProtectedRoutes";
import { useSelector } from "react-redux";
import NoInternetConnection from "../Components/CustomComponents/NoInternetConnection";
import DashboardPage from "./DashboardPage";
import BookingPage from "./BookingPage";
import HomePage from "./HomePage";

import ThankYouPage from "../Pages/ThankYouPage";
import CancelPage from "../Pages/CancelPage";
import HoldRoomPlansComp from "../Components/AgentHoldRooms/HoldRoomPlansComp";
import AgentContractPage from "./AgentContractPage";
import HoldRoomCreateAgent from "../Components/AgentHoldRooms/HoldRoomCreateAgent";
import CreateContract from "../Components/AgentHoldRooms/CreateContract";
import AgentHoldRoomsCreateBooking from "../Components/AgentHoldRooms/agentCreateBooking/AgentHoldRoomsCreateBooking";
import AgentContractsCreateBooking from "../Components/AgentCreateBooking/AgentContractsCreateBooking";

import ReportsStorePurchasesPages from "./ReportsPages/ReportsStorePurchasesPages";
import FAndBReportsPage from "./ReportsPages/FAndBReportsPage";
import AgentInventory from "../Components/Dashboard/AgentInventory";
import ReportParentPage from "../Components/Reports/ReportParentPage";
import FrontOfficeReports from "../Components/Reports/FrontOfficeReports";
import FinanceReports from "../Components/Reports/FinanceReports";
import ProductionReports from "../Components/Reports/ProductionReports";

const Layout = () => {
  const authenticated = useSelector(
    (state) => state.isAuthenticated.authenticated
  );

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const checkOnlineStatus = () => {
      setIsOnline(window.navigator.onLine);
    };
    const intervalId = setInterval(checkOnlineStatus, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const navigate = useNavigate();

  // const fetchInfo = async () => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/api/v1/user-profile`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     );

  //     if (response.status === 401) {
  //       navigate("/login");
  //       return;
  //     }

  //     const data = await response.json();
  //     localStorage.setItem(
  //       "permissions",
  //       JSON.stringify(data.data.permissions)
  //     );
  //     // if (data?.data?.properties?.length > 0) {
  //     //   if (selectedProperty) {
  //     //     setSelectedHotelOption(selectedProperty);
  //     //     dispatch(saveActiveProperty(selectedHotelOption));
  //     //     setIsFetching(false);
  //     //   } else {
  //     //     setSelectedHotelOption(data?.data?.properties[0]);
  //     //     setIsFetching(false);
  //     //   }
  //     // } else if (data?.data?.properties.length === 0) {
  //     //   navigate("/nopropertypage");
  //     // }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchInfo();
  // }, []);

  const property_details = localStorage.getItem("properties");

  useEffect(() => {
    if (!property_details) {
      localStorage.clear();
      window.location.reload();
    }
  }, [property_details]);

  return (
    <>
      <Navbar />
      <div>
        {isOnline ? (
          <Routes>
            <Route element={<ProtectedRoutes />}>
              {/* <Route path={"/"} element={<DashboardPage />} /> */}
              <Route path={"/"} exact element={<DashboardPage />} />
              <Route path={"/dashboard"} exact element={<DashboardPage />} />
              <Route path={"/dashboard/:ID"} element={<DashboardPage />} />
              <Route path={"/bookings"} element={<BookingPage />} />
              <Route path={"/inventory"} element={<AgentInventory />} />
              <Route path="/reports" element={<ReportParentPage />}>
                <Route path={"daily-activity"} index element={<FrontOfficeReports />} />
                <Route
                path={"finance"}
                element={<FinanceReports />}
              />
               <Route
                path={"production"}
                element={<ProductionReports />}
              />
              </Route>
              {/* <Route
                path={"/reports/store-and-purchases"}
                element={<ReportsStorePurchasesPages />}
              />
              <Route
                path={"/reports/production"}
                element={<ReportsProductionPage />}
              />
              <Route
                path={"/reports/finance"}
                element={<FinanceReportsPage />}
              />{" "}
              <Route path={"/reports/f&b"} element={<FAndBReportsPage />} /> */}
              {/* Agent Hold/Contract */}
              <Route path={"/agent"} element={<AgentContractPage />}>
                <Route
                  path={"agent-hold-rooms"}
                  element={<HoldRoomPlansComp />}
                />
                <Route
                  path={"agent-contracts"}
                  element={<HoldRoomCreateAgent />}
                />
              </Route>
              <Route
                path={"/agent-hold-rooms/create-booking/:ID"}
                element={<AgentHoldRoomsCreateBooking />}
              />
              <Route
                path={"/agent-contracts/create-booking/:ID"}
                element={<AgentContractsCreateBooking />}
              />
              <Route
                path="agent-contracts/create"
                element={<CreateContract />}
              />
              {/* Agent Hold/Contract End */}
              <Route path="/payment" element={<ThankYouPage />} />
              <Route path="/cancel-payment" element={<CancelPage />} />
            </Route>
          </Routes>
        ) : (
          <NoInternetConnection />
        )}
      </div>
      {/* <Sidebar /> */}
    </>
  );
};
export default Layout;
