import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import autoTable from "jspdf-autotable";
import { useSelector } from "react-redux";
import { useCurrentTime, currentDateSingle, capitalizeFirstLetter } from "../../../utils/utils";

const ButtonToDownloadTablePDF = ({
  itemToDownloadID,
  downloadFileName,
  pdfStyles,
  ignoreFromPrint,
  pdfOrientation,
  pdfMargins,
  pageFormat,
  longPdf,
}) => {
  const propertyName =  JSON.parse(localStorage.getItem("selectedProperty"))?.property_name
  const dateTime = `${currentDateSingle}_${useCurrentTime()}`;
  const reportPropertyDateTime = `${propertyName}_${dateTime}`;

  // console.log("reportPropertyDateTime", reportPropertyDateTime)

  // const handleDownload = () => {
  //   const originalTable = document.getElementById(itemToDownloadID);
  //   if (!originalTable) {
  //     console.error("Table element not found");
  //     return;
  //   }
  //   const clonedTable = originalTable.cloneNode(true);

  //   // Apply styles from props or fallback to default styles
  //   clonedTable.style.fontSize = pdfStyles?.fontSize || "12px";
  //   clonedTable.style.fontFamily = pdfStyles?.fontFamily || "Arial, sans-serif";
  //   clonedTable.style.lineHeight = pdfStyles?.lineHeight || "1.5";
  //   clonedTable.style.margin = pdfStyles?.margin || "auto";
  //   clonedTable.style.borderRadius = "0";
  //   clonedTable.style.padding = pdfStyles?.padding || "0";
  //   // pdf.style.display = "none";

  //   // Remove elements if ignoreFromPrint is provided
  //   if (ignoreFromPrint) {
  //     // Check if ignoreFromPrint is an array, if not, make it an array for consistency
  //     const elementsToIgnore = Array.isArray(ignoreFromPrint)
  //       ? ignoreFromPrint
  //       : [ignoreFromPrint];

  //     elementsToIgnore.forEach((selector) => {
  //       // Select elements by ID or class and remove them
  //       const itemsToRemove = clonedTable.querySelectorAll(
  //         `#${selector}, .${selector}`
  //       );
  //       itemsToRemove.forEach((item) => item.remove());
  //     });
  //   }

  //   const tempContainer = document.createElement("div");
  //   tempContainer.appendChild(clonedTable);

  //   // Configure and generate PDF
  //   const opt = {
  //     margin: pdfMargins || [0.1, 0.1, 0.2, 0.1],
  //     filename: `${
  //       downloadFileName ? downloadFileName : "report"
  //     }_${reportPropertyDateTime}.pdf`,
  //     image: { type: "jpeg", quality: 1 },
  //     html2canvas: { scale: 1.5, letterRendering: true },
  //     jsPDF: {
  //       unit: "in",
  //       format: pageFormat || "a4",
  //       orientation: pdfOrientation || "portrait",
  //     },
  //     pagebreak: { mode: ["avoid-all", "css", "legacy"] },
  //   };

  //   html2pdf()
  //     .from(tempContainer)
  //     .set(opt)
  //     .toPdf()
  //     .get("pdf")
  //     .then(function (pdf) {
  //       const totalPages = pdf.internal.getNumberOfPages();

  //       for (let i = 1; i <= totalPages; i++) {
  //         pdf.setPage(i);
  //         pdf.setFontSize(8);
  //         pdf.text(
  //           `Page ${i} of ${totalPages}`,
  //           pdf.internal.pageSize.getWidth() / 2,
  //           pdf.internal.pageSize.getHeight() - 0.1,
  //           { align: "center" }
  //         );
  //       }
  //     })
  //     .save();
  // };

  // const handleDownload = async () => {
  //   const originalTable = document.getElementById(itemToDownloadID);
  //   if (!originalTable) {
  //     console.error("Table element not found");
  //     return;
  //   }
  
  //   // Clone the table and apply styles
  //   const clonedTable = originalTable.cloneNode(true);
  //   clonedTable.style.fontSize = pdfStyles?.fontSize || "12px";
  //   clonedTable.style.fontFamily = pdfStyles?.fontFamily || "Arial, sans-serif";
  //   clonedTable.style.lineHeight = pdfStyles?.lineHeight || "1.5";
  //   clonedTable.style.margin = pdfStyles?.margin || "auto";
  //   clonedTable.style.borderRadius = "0";
  //   clonedTable.style.padding = pdfStyles?.padding || "0";
  
  //   // Remove elements if ignoreFromPrint is provided
  //   if (ignoreFromPrint) {
  //     const elementsToIgnore = Array.isArray(ignoreFromPrint)
  //       ? ignoreFromPrint
  //       : [ignoreFromPrint];
  
  //     elementsToIgnore.forEach((selector) => {
  //       const itemsToRemove = clonedTable.querySelectorAll(`#${selector}, .${selector}`);
  //       itemsToRemove.forEach((item) => item.remove());
  //     });
  //   }
  
  //   // Create a container for the cloned content
  //   const tempContainer = document.createElement("div");
  //   tempContainer.style.position = "absolute";
  //   tempContainer.style.left = "-9999px";
  //   tempContainer.appendChild(clonedTable);
  //   document.body.appendChild(tempContainer);
  
  //   // Capture the content using html2canvas
  //   const pdf = new jsPDF({
  //     orientation: pdfOrientation || "portrait",
  //     unit: "px",
  //     format: pageFormat || "a4",
  //   });
  
  //   const pageWidth = pdf.internal.pageSize.getWidth();
  //   const pageHeight = pdf.internal.pageSize.getHeight();
  
  //   let yOffset = 0; // Tracks vertical position on the current page
  
  //   while (yOffset < clonedTable.scrollHeight) {
  //     const canvas = await html2canvas(clonedTable, {
  //       scale: 2,
  //       y: yOffset,
  //       height: pageHeight * 2, // Capture enough content for one page
  //     });
  
  //     const imgData = canvas.toDataURL("image/jpeg", 1.0);
  //     const imgWidth = pageWidth;
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
  //     pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight);
  
  //     yOffset += pageHeight * 2; // Move down by one page height
  
  //     if (yOffset < clonedTable.scrollHeight) {
  //       pdf.addPage(); // Add a new page if more content remains
  //     }
  //   }
  
  //   // Add page numbers
  //   const totalPages = pdf.getNumberOfPages();
  //   for (let i = 1; i <= totalPages; i++) {
  //     pdf.setPage(i);
  //     pdf.setFontSize(8);
  //     pdf.text(
  //       `Page ${i} of ${totalPages}`,
  //       pdf.internal.pageSize.getWidth() / 2,
  //       pdf.internal.pageSize.getHeight() - 10,
  //       { align: "center" }
  //     );
  //   }
  
  //   pdf.save(`${downloadFileName || "report"}_${reportPropertyDateTime}.pdf`);
  
  //   // Clean up the temporary container
  //   document.body.removeChild(tempContainer);
  // };
  const handleDownload = async () => {
    const originalTable = document.getElementById(itemToDownloadID);
    if (!originalTable) {
        console.error("Table element not found");
        return;
    }

    // Extract headers, removing rupee signs from each header
    const headers = Array.from(originalTable.querySelectorAll("thead tr th")).map(
        (th) => th.innerText.replace(/₹/g, "") // Remove rupee symbols from headers
    );

    // Extract rows, removing rupee signs from each cell
    const rows = Array.from(originalTable.querySelectorAll("tbody tr")).map((tr) =>
        Array.from(tr.querySelectorAll("td")).map((td) =>
            td.innerText.replace(/₹/g, "") // Remove rupee symbols from each cell
        )
    );

    // Create jsPDF instance
    const pdf = new jsPDF({
        orientation: pdfOrientation || "portrait",
        unit: "px",
        format: pageFormat || "a4",
    });

    // Add a heading at the top of the PDF
    const reportHeading = capitalizeFirstLetter(
        downloadFileName.replaceAll("_", " ") || "Report"
    );

    // Set font size for the heading and center it
    pdf.setFontSize(16);
    pdf.text(reportHeading, pdf.internal.pageSize.getWidth() / 2, 15, { align: "center" });

    // Add table to PDF using autoTable
    pdf.autoTable({
        head: [headers],
        body: rows,
        startY: 40, // Adjust startY to leave space for the heading
        margin: { top: 20 },
        theme: "striped",
        headStyles: {
            fontSize: pdfStyles?.fontSize || 12,
            font: pdfStyles?.fontFamily || "Arial",
        },
        bodyStyles: {
            lineColor: pdfStyles?.lineColor || [0, 0, 0],
            lineWidth: 0.1,
        },
        alternateRowStyles: { fillColor: [240, 240, 240] },
        styles: {
            fontSize: pdfStyles?.fontSize || 12,
            lineHeight: pdfStyles?.lineHeight || 1.5,
            cellPadding: pdfStyles?.padding || 5,
        },
        columnStyles: {
            0: { cellWidth: 'auto' },
            1: { cellWidth: 'auto' },
            // Add additional columns as necessary
        },
    });

    // Add page numbers
    const totalPages = pdf.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.setFontSize(8);
        pdf.text(
            `Page ${i} of ${totalPages}`,
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.getHeight() - 10,
            { align: "center" }
        );
    }

    // Save the PDF with the specified file name and date
    pdf.save(`${downloadFileName || "report"}_${reportPropertyDateTime}.pdf`);
};

  const handleDownloadWithJspdf = () => {
    const originalContainer = document.getElementById(itemToDownloadID);
    if (!originalContainer) {
      console.error("Container element not found");
      return;
    }
  
    // Clone the original container to preserve styles
    const clonedContainer = originalContainer.cloneNode(true);
  
    // Apply styles from props or fallback to default styles
    clonedContainer.style.fontSize = pdfStyles?.fontSize || "12px";
    clonedContainer.style.fontFamily = pdfStyles?.fontFamily || "Arial, sans-serif";
    clonedContainer.style.lineHeight = pdfStyles?.lineHeight || "1.5";
    clonedContainer.style.margin = pdfStyles?.margin || "auto";
    clonedContainer.style.borderRadius = "0";
    clonedContainer.style.padding = pdfStyles?.padding || "0";
  
    // Remove rupee signs from all th and td elements
    const cells = clonedContainer.querySelectorAll('th, td');
    cells.forEach(cell => {
      cell.textContent = cell.textContent.replace(/₹/g, ''); // Replace rupee sign with an empty string
    });
  
    // Remove elements if ignoreFromPrint is provided
    if (ignoreFromPrint) {
      const elementsToIgnore = Array.isArray(ignoreFromPrint) ? ignoreFromPrint : [ignoreFromPrint];
      elementsToIgnore.forEach((selector) => {
        const itemsToRemove = clonedContainer.querySelectorAll(`#${selector}, .${selector}`);
        itemsToRemove.forEach((item) => item.remove());
      });
    }
  
    // Configure and generate PDF with jsPDF and autoTable
    const doc = new jsPDF({
      orientation: pdfOrientation || "portrait",
      unit: "px",
      format: pageFormat || "a4",
    });
  
    const reportHeading = capitalizeFirstLetter(
      downloadFileName.replaceAll("_", " ") || "Report"
    );
  
    // Set the font size and add the heading
    doc.setFontSize(16); // Set font size for the heading
    const headingWidth = doc.getTextWidth(reportHeading);
    const pageWidth = doc.internal.pageSize.getWidth();
    doc.text(reportHeading, (pageWidth - headingWidth) / 2, 20); // Centered heading
  
    const tables = clonedContainer.querySelectorAll("table");
    let startY = 30; // Start the first table after the heading
  
    tables.forEach((table, index) => {
      // Add a heading for each table
      doc.setFontSize(14);
      // doc.text(`Table ${index + 1}`, 20, startY);
      startY += 10; // Space between heading and table
  
      autoTable(doc, {
        html: table,
        startY: startY,
        margin: {
          top: pdfMargins ? pdfMargins[0] : 10,
          right: pdfMargins ? pdfMargins[1] : 10,
          bottom: pdfMargins ? pdfMargins[2] : 10,
          left: pdfMargins ? pdfMargins[3] : 10,
        },
        headStyles: {
          fillColor: [60, 141, 188], // Header background color
          textColor: [255, 255, 255], // Header text color
          lineWidth: 0.5, // Header border width
          lineColor: [0, 0, 0], // Header border color
        },
        bodyStyles: {
          fillColor: [255, 255, 255], // Cell background color
          textColor: [0, 0, 0], // Cell text color
          lineWidth: 0.5, // Cell border width
          lineColor: [0, 0, 0], // Cell border color
        },
        columnStyles: {
          0: { cellWidth: 'auto' }, // Automatically adjust the width of the first column
          1: { cellWidth: 'auto' }, // Automatically adjust the width of the second column
          // Repeat for other columns as necessary
        },
      });
  
      // Update startY for the next table
      startY = doc.autoTable.previous.finalY + 10; // Add space after the table
    });
  
    // Add page numbers
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(8);
      doc.text(`Page ${i} of ${pageCount}`, doc.internal.pageSize.getWidth() / 2, doc.internal.pageSize.getHeight() - 5, { align: "center" });
    }
  
    // Save the PDF
    doc.save(`${downloadFileName ? downloadFileName : "report"}_${reportPropertyDateTime}.pdf`);
  };
  
  
 

  return (
    <div
      onClick={longPdf ? handleDownloadWithJspdf : handleDownload}
      // onClick={longPdf ?  handleDownload   :  handleDownloadWithJspdf}
      className="flex  flex_gap_5 justify_content_center align_items_center pointer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M6.99957 10.1107L3.11068 6.22179L4.19957 5.09401L6.22179 7.11623V0.777344H7.77734V7.11623L9.79957 5.09401L10.8885 6.22179L6.99957 10.1107ZM2.3329 13.2218C1.90512 13.2218 1.53879 13.0693 1.2339 12.7645C0.929012 12.4596 0.776827 12.0935 0.777345 11.6662V9.3329H2.3329V11.6662H11.6662V9.3329H13.2218V11.6662C13.2218 12.094 13.0693 12.4603 12.7645 12.7652C12.4596 13.0701 12.0935 13.2223 11.6662 13.2218H2.3329Z"
          fill="#3968ED"
        />
      </svg>
      <span style={{ color: "#3968ED", fontSize: "14px", fontStyle: "normal" }}>
        PDF
      </span>
    </div>
  );
};

export default ButtonToDownloadTablePDF;

// const handleDownloadCSV = () => {
//     const originalTable = document.getElementById(itemToDownloadID);
//     const rows = originalTable.querySelectorAll('tr');

//     // Initialize CSV content
//     let csvContent = '';

//     // Iterate over each row
//     rows.forEach(row => {
//         const categoryDiv = row.previousElementSibling;
//         if (categoryDiv && categoryDiv.classList.contains('category_name')) {
//             const categoryName = categoryDiv.textContent.trim();
//             // Append category name to CSV content
//             csvContent += `"${categoryName}"\n`;
//         }

//         const cells = row.querySelectorAll('td, th');
//         // Iterate over each cell in the row
//         cells.forEach((cell, index) => {
//             let cellContent = cell.textContent.trim().replace('₹', 'Rs'); // Replace ₹ symbol with "Rs"
//             // Enclose cell content within double quotes if it contains special characters or leading/trailing spaces
//             if (/[,"]/.test(cellContent) || /^\s|\s$/.test(cellContent)) {
//                 cellContent = `"${cellContent}"`;
//             }
//             // Append cell value to CSV content
//             csvContent += `${cellContent}`;
//             // Add comma except for the last cell in the row
//             if (index < cells.length - 1) {
//                 csvContent += ',';
//             }
//         });
//         // Add new line after each row
//         csvContent += '\n';
//     });

//     // Create a Blob with the CSV content
//     const blob = new Blob([csvContent], { type: 'text/csv' });

//     // Create a temporary link element
//     const link = document.createElement('a');
//     link.href = window.URL.createObjectURL(blob);
//     link.download = `${downloadFileName}.csv`;

//     // Append the link to the document body and trigger the download
//     document.body.appendChild(link);
//     link.click();

//     // Remove the link from the document body
//     document.body.removeChild(link);
// };
