import NoDataFound from "../CustomComponents/NoDataFound";
import Loader from "../UI/Loader/Loader";
import {
  calculateNestedTotal,
  calculateTotalArrayObjectKey,
  formatIndianCurrency,
  getBaseUrl,
} from "../../utils/utils";
import ButtonToDownloadTablePDF from "../CustomComponents/DownloadPdfTables/ButtonToDownloadTablePDF";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "../CustomComponents/CustomReportComponent/CustomReportComponent";
import DownloadCSVButton from "../CustomComponents/DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import InformationCustomPopup from "../CustomComponents/InformationCustomPopup/InformationCustomPopup";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import { SvgGetInfo } from "../../assets/SvgIcons";
import BookingDetailSliderNew from "../CustomComponents/BookingDetails/BookingDetailsSliderNew";
import { showErrorToast } from "../../assets/toastUtils";
import InvoiceIframePopup from "./InvoiceIframePopup/InvoiceIframePopup";

const ReportRoomNightRevenueInsightsReport = ({
  departureReport,
  // loader,
  fullData,
  donShowTitle,
  userToken,
  property_id,
}) => {
  console.log(departureReport, "departureReport");

  const styles = {
    tableData: {
      verticalAlign: "top",
      textAlign: "left",
    },
  };
  const [informationPopup, setInformationPopup] = useState(false);

  const tableHeadings = [
    "Booking Date",
    "Booking Month",
    "Total Commission",
    "Revenue",
    "Rooms",
  ];

  const rowMarkup =
    departureReport &&
    departureReport?.length > 0 &&
    departureReport?.map((order) => (
      <CustomReportTableRow id={order.id} key={order.id} position={order.index}>
        <CustomReportTableCell>
          {order?.booking_date || "-"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order?.booking_month_name || "-"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order?.total_commission
            ? formatIndianCurrency(order?.total_commission)
            : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order?.total_given_revenue
            ? formatIndianCurrency(order?.total_given_revenue)
            : "-"}
        </CustomReportTableCell>
        <CustomReportTableCell>
          {order?.total_no_of_rooms || "-"}
        </CustomReportTableCell>
      </CustomReportTableRow>
    ));

  const calculateTotals = () => {
    return (
      <CustomReportTableRow bodyStyles={{ backgroundColor: "#dddddd" }}>
      <CustomReportTableCell>
        <b>Totals</b>
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
      
      <CustomReportTableCell><b>
        
      {formatIndianCurrency(calculateTotalArrayObjectKey(departureReport, "total_commission"))}
        
        </b></CustomReportTableCell>
      <CustomReportTableCell><b>
      {formatIndianCurrency(calculateTotalArrayObjectKey(departureReport, "total_given_revenue"))}</b></CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
    </CustomReportTableRow>
    )
  };

  const [openSingleBookingDetails, setOpenSingleBookingDetails] =
    useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [folios, setFolios] = useState([]);
  const [chargesDetails, setChargesDetails] = useState(null);
  const [masterFolioDetails, setMasterFolioDetails] = useState(null);
  const [loader, setLoader] = useState(false);

  const getSingleBookingDataHandler = (bookingId) => {
    // setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/get/booking/${bookingId}/${property_id?.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setOpenSingleBookingDetails(true);
          setLoader(false);
          setBookingDetails(data?.booking);
          setChargesDetails(data?.charges);
          setMasterFolioDetails(data?.masterFolio);
          setFolios(data?.allFolios);
        } else {
          setLoader(false);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error:", error);
        // showErrorToast("An error occurred while processing your request.");
      });
  };

  const [iframeSrc, setIframeSrc] = useState("");
  const [showIframePopup, setShowIframePopup] = useState(false);
  const [showBillPreview, setShowBillPreview] = useState(false);
  const [iframeContent, setIframeContent] = useState("");

  const handleSubmitPreview = async (booking_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/layout/preview/${property_id?.id}/bill?previewType=roomBillPreview&previewId=${booking_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const iframeContent = await response.text(); // API response as text (iframe content)
      setIframeContent(iframeContent);
      setShowIframePopup(true);
    } catch (error) {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      setLoader(false);
    }
  };

  // Jab maine shuru kiya,
  // I was working on small components,
  // trying to understand the codebase.
  // Aaj, I'm proud to contribute to RevCatalyst’s front-end!
  // Yeh journey easy nahi thi, but my amazing team ne har step pe support kiya.
  // Aap sabse bohot kuch seekhne ko मिला, aur aap log sirf colleagues
  // nahi, family jaisa feel hota hai.
  // I'm truly grateful for this opportunity.

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="room-types-full-container" id="sales_table">
          <div className="room-types-full-container-div">
            <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
              <div className="room-type-heading">
                Report Room-Night & Revenue Insights Report
              </div>
              <div
                className="hoverCursor"
                onClick={() => setInformationPopup(true)}
                id="ignoreFromPrint"
              >
                <SvgGetInfo />
              </div>
            </div>
            {departureReport?.length > 0 && (
              <div className="flex flex_gap_15" id="ignoreFromPrint">
                <ButtonToDownloadTablePDF
                  itemToDownloadID={"sales_table"}
                  downloadFileName={"ReportRoomNightRevenueInsightsReport"}
                  ignoreFromPrint={"ignoreFromPrint"}
                  pdfOrientation={"landscape"}
                  pageFormat={"A1"}
                  longPdf={true}
                />
                <DownloadCSVButton
                  itemToDownloadID={"sales_table"}
                  downloadFileName={
                    "Report-Room-Night-&-Revenue-Insights-Report"
                  }
                />
              </div>
            )}
          </div>
          {departureReport && departureReport?.length > 0 ? (
            <>
              <CustomReportTable
                headings={tableHeadings}
                headerStyles={{
                  backgroundColor: "#dddddd",
                  fontSize: "12px",
                  textAlign: "left",
                }}
              >
                {rowMarkup}
                {calculateTotals()}
              </CustomReportTable>
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      )}

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Detailed Business Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is the Detailed Business Report?",
                  answer: (
                    <p>
                      The <b>Detailed Business Report</b> is a detailed
                      financial document that tracks all payments received over
                      a specific date range, categorized by payment methods such
                      as cash, bank transfers, UPI, credit card, debit card, and
                      city ledger. This report provides a consolidated view of
                      all payment transactions, making it easier to reconcile
                      finances.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the Detailed Business Report?",
                  answer: (
                    <div>
                      <p>The Detailed Business Report is essential for:</p>

                      <ul>
                        <li>
                          <b>Financial Accuracy: </b>Ensures all payments are
                          accurately recorded and matched with corresponding
                          transactions, preventing discrepancies.
                        </li>
                        <li>
                          <b>Cash Flow Management: </b>Helps in tracking cash
                          inflows from different payment channels, providing a
                          clear picture of daily revenue.
                        </li>
                        <li>
                          <b>Audit Preparedness: </b> Provides a transparent
                          record of payment methods used, simplifying audits and
                          financial reporting.
                        </li>
                        <li>
                          <b>Operational Efficiency: </b>Helps in identifying
                          any pending or unmatched payments, ensuring smoother
                          financial operations.
                        </li>
                        <li>
                          <b>Fraud Prevention: </b>By reconciling payments
                          against bookings and invoices, this report helps
                          identify potential fraud or errors in financial
                          transactions.
                        </li>
                      </ul>
                      <p>
                        This report is a key tool for maintaining accurate and
                        organized financial records.
                      </p>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
      {openSingleBookingDetails && (
        <BookingDetailSliderNew
          folios={folios}
          data={bookingDetails}
          chargesDetails={chargesDetails}
          masterFolioDetails={masterFolioDetails}
          setShowBookingDetails={setOpenSingleBookingDetails}
          getSingleBookingDataHandler={getSingleBookingDataHandler}
        />
      )}

      {showIframePopup && (
        <>
          <InvoiceIframePopup
            setShowIframePopup={setShowIframePopup}
            iframeContent={iframeContent}
          />
        </>
      )}
    </>
  );
};
export default ReportRoomNightRevenueInsightsReport;
