import React, { useEffect, useState } from "react";
import styles from "./reservationPopup.module.scss";
import {
  calculateNumberOfNights,
  calculateTotalArrayObjectKey,
  formatDateMonthName,
} from "../../../../utils/utils";
import { BgThemeButton } from "../../../UI/Buttons";
import HandleNaN from "../../../CustomComponents/HandleNaN/HandleNaN";
import { useNavigate } from "react-router-dom";
import { showSuccessToast } from "../../../../assets/toastUtils";
import GoBackButtonCustom from "../../../GoBackButtonCustom/GoBackButtonCustom";


const ReservationPopups = ({
  hotel,
  selectedRoom,
  selectedRatePlan,
  agentSearchData,
  setShowReservationsDetails,
  agent,
  creditLimit,
  advanceLimit,
  ID,
  fetchAgentInfo,
  agentId,
}) => {
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const agent_details = JSON.parse(localStorage.getItem("agentDetails"));

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;

    const handleLoad = () => console.log("Razorpay script loaded!");
    const handleError = (e) => {
      console.error("Failed to load Razorpay script", e);
      // setError(e);
    };

    script.addEventListener("load", handleLoad);
    script.addEventListener("error", handleError);

    document.body.appendChild(script);

    return () => {
      script.removeEventListener("load", handleLoad);
      script.removeEventListener("error", handleError);
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (userToken && agent_details) {
      // fetchAgent();
    }
  }, [userToken, agent_details.id]);

  const roomRate = +selectedRatePlan.rate_per_night;
  const propertyDiscount = +hotel.automatic_discounts.discount_amount;
  const propertyDiscountType = hotel.automatic_discounts.discount_type;
  const agentDiscount = +hotel.agent_discount;
  const agentDiscountType = "percent";
  const childPricesArray = selectedRatePlan.child_price;
  const extraAdultCount = selectedRatePlan.extra_adult_count;
  const extraAdultPrice = selectedRatePlan.extra_adult;

  const noOfNights = +calculateNumberOfNights(
    agentSearchData.start_date,
    agentSearchData.end_date
  );
  const noOfRooms = +agentSearchData.rooms;

  const [priceBreakup, setPriceBreakup] = useState({
    roomRate: "",
    bookingDiscount: "",
    bookingDiscountType: "",
    agentDiscount: "",
    agentDiscountType: "",
    childPricesArray: "",
    noOfNights: "",
    noOfRooms: "",
  });

  const calculateBookingValues = (
    roomRate,
    bookingDiscount,
    bookingDiscountType,
    agentDiscount,
    agentDiscountType,
    childPricesArray,
    noOfNights,
    noOfRooms,
    extraAdult,
    extraAdultPrice
  ) => {
    let roomRateParsed = parseFloat(roomRate) * +noOfNights * +noOfRooms;
    if (isNaN(roomRateParsed)) {
      roomRateParsed = 0;
    }

    let taxSlab = +roomRate > 7500 ? 18 : 12;

    let bookingDiscountAmount = 0;
    if (bookingDiscountType === "percent") {
      bookingDiscountAmount = (roomRateParsed * bookingDiscount) / 100;
    } else {
      bookingDiscountAmount = parseFloat(bookingDiscount);
      if (isNaN(bookingDiscountAmount)) {
        bookingDiscountAmount = 0;
      }
    }

    let agentDiscountAmount = 0;
    if (agentDiscountType === "percent") {
      agentDiscountAmount =
        ((roomRateParsed - bookingDiscountAmount) * agentDiscount) / 100;
    } else {
      agentDiscountAmount = parseFloat(agentDiscount);
      if (isNaN(agentDiscountAmount)) {
        agentDiscountAmount = 0;
      }
    }

    let totalDiscountAmount = bookingDiscountAmount + agentDiscountAmount;

    let childPrices = childPricesArray.reduce((accumulator, currentPrice) => {
      const parsedPrice = parseFloat(currentPrice);
      return accumulator + (isNaN(parsedPrice) ? 0 : parsedPrice);
    }, 0);

    let extraAdultPrices = +extraAdult * +extraAdultPrice * +noOfNights;
    if (isNaN(extraAdultPrice)) {
      extraAdultPrices = 0;
    }
    const subtotal =
      roomRateParsed -
      totalDiscountAmount +
      +childPrices * +noOfNights +
      extraAdultPrices;
    const taxAmount = (subtotal * taxSlab) / 100;
    const grandTotal = subtotal + taxAmount;

    return {
      taxSlab,
      subtotal,
      taxAmount,
      grandTotal,
      discountAmt: totalDiscountAmount,
      totalChildAdultCost: childPrices * +noOfNights,
      childPrices,
      bookingDiscountAmount,
      agentDiscountAmount,
      roomRate,
    };
  };

  useEffect(() => {
    setPriceBreakup(
      calculateBookingValues(
        roomRate,
        propertyDiscount,
        propertyDiscountType,
        agentDiscount,
        agentDiscountType,
        childPricesArray,
        noOfNights,
        noOfRooms,
        extraAdultCount,
        extraAdultPrice
      )
    );
  }, [
    roomRate,
    propertyDiscount,
    propertyDiscountType,
    agentDiscount,
    agentDiscountType,
    childPricesArray,
    noOfNights,
    noOfRooms,
    setPriceBreakup,
  ]);
  const selectedProperty = JSON.parse(localStorage.getItem("selectedProperty"));

  const handleClose = () => {
    setShowReservationsDetails(false);
  };

  const [creditChecked, setCreditChecked] = useState(false);
  const [advanceChecked, setAdvanceChecked] = useState(false);
  const calculateCreditAndBalance = (
    name,
    hotelId,
    creditChecked,
    creditLimit,
    advanceChecked,
    advanceLimit,
    priceBreakup,
    checkIn,
    checkOut
  ) => {
    let responseData = {
      pay_with_company_credit: creditChecked,
      pay_with_advance: advanceChecked,
      remaining_credit_balance: creditLimit,
      remaining_advance_balance: advanceLimit,
      checkIn: checkIn,
      checkOut: checkOut,
      taxAmount: priceBreakup.taxAmount,
      subTotal: priceBreakup.subtotal,
      discountAmount: priceBreakup.bookingDiscountAmount,
      grandTotal: priceBreakup.grandTotal,
      paidAmount: 0,
      balanceAmount: priceBreakup.grandTotal,
      hotelId: hotelId,
      customerName: name,
      tacAmount: priceBreakup.agentDiscountAmount,
      roomDetails: [],
    };

    if (creditChecked && !advanceChecked) {
      if (creditLimit > priceBreakup.grandTotal) {
        responseData.balanceAmount = 0;
        responseData.remaining_credit_balance =
          creditLimit - priceBreakup.grandTotal;
        responseData.paidAmount = priceBreakup.grandTotal;
      } else {
        responseData.balanceAmount = priceBreakup.grandTotal - creditLimit;
        responseData.remaining_credit_balance = 0;
        responseData.paidAmount = creditLimit;
      }
      return responseData;
    }

    if (!creditChecked && advanceChecked) {
      if (advanceLimit > priceBreakup.grandTotal) {
        responseData.balanceAmount = 0;
        responseData.remaining_advance_balance =
          advanceLimit - priceBreakup.grandTotal;
        responseData.paidAmount = priceBreakup.grandTotal;
      } else {
        responseData.balanceAmount = priceBreakup.grandTotal - advanceLimit;
        responseData.remaining_advance_balance = 0;
        responseData.paidAmount = advanceLimit;
      }
      return responseData;
    }

    if (creditChecked && advanceChecked) {
      let remainingAmount = priceBreakup.grandTotal;

      if (creditLimit >= remainingAmount) {
        responseData.paidAmount = remainingAmount;
        responseData.remaining_credit_balance = creditLimit - remainingAmount;
        responseData.remaining_advance_balance = advanceLimit;
        responseData.balanceAmount = 0;
      } else {
        remainingAmount -= creditLimit;
        responseData.remaining_credit_balance = 0;
        responseData.paidAmount = creditLimit;

        if (advanceLimit >= remainingAmount) {
          responseData.paidAmount += remainingAmount;
          responseData.remaining_advance_balance =
            advanceLimit - remainingAmount;
          responseData.balanceAmount = 0;
        } else {
          responseData.paidAmount += advanceLimit;
          responseData.remaining_advance_balance = 0;
          responseData.balanceAmount = remainingAmount - advanceLimit;
        }
      }
      return responseData;
    }
    if (!creditChecked && !advanceChecked) {
      return responseData;
    }
  };
  const handleConfirmAndPayClick = async () => {
    const responseData = calculateCreditAndBalance(
      agent.agent_name,
      ID,
      creditChecked,
      creditLimit,
      advanceChecked,
      advanceLimit,
      priceBreakup,
      agentSearchData.start_date,
      agentSearchData.end_date
    );

    const roomDetails = {
      room_id: selectedRoom.room_id,
      roomTypeName: selectedRoom.room_name,
      ratePlanId: selectedRatePlan.rate_plan_id,
      adult: agentSearchData.adults,
      taxType: "exclusive",
      roomPlan: selectedRatePlan.meal_plan,
      rooms: noOfRooms,
      nights: noOfNights,
      roomRate: roomRate,
      children: agentSearchData.number_of_children,
      checkIn: agentSearchData.start_date,
      checkOut: agentSearchData.end_date,
      taxRate: priceBreakup.taxSlab,
      taxAmount: priceBreakup.taxAmount,
      disAmount: priceBreakup.bookingDiscountAmount || 0,
      disRate: propertyDiscount || 0,
      totalAmount: priceBreakup.subtotal,
      netTotal: priceBreakup.grandTotal,
      tacRate: agentDiscount || 0,
      tacAmount: priceBreakup.agentDiscountAmount || 0,
      extraAdultPrice: selectedRatePlan.extra_adult || 0,
      extraAdult: extraAdultCount || 0,
    };
    responseData.roomDetails = [roomDetails];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agent/booking-engine/push-booking`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(responseData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error(`HTTP error! status: ${response.status}`, errorData);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.data.balance_amount === 0) {
        showSuccessToast("Payment Successful");
        navigate("/bookings");
        fetchAgentInfo(agentId);
      } else {
        redirectToPaymentPage(data.data);
        setShowReservationsDetails(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [isDisabled, setDisabled] = useState({
    credit: false,
    advance: false,
  });

  const handleCreditCheckboxChange = (e) => {
    const data = calculateCreditAndBalance(
      agent.agent_name,
      ID,
      e.target.checked, ///creditChecked
      creditLimit,
      false, /// advanceChecked
      advanceLimit,
      priceBreakup,
      agentSearchData.start_date,
      agentSearchData.end_date
    );

    if (data.balanceAmount === 0) {
      setDisabled({
        ...isDisabled,
        advance: true,
      });
    } else {
      setDisabled({
        ...isDisabled,
        advance: false,
      });
    }

    setCreditChecked(!creditChecked);
  };
  const handleAdvanceCheckboxChange = (e) => {
    setAdvanceChecked(!advanceChecked);
    const data = calculateCreditAndBalance(
      agent.agent_name,
      ID,
      false, /// creditChecked
      creditLimit,
      e.target.checked, ///advanceChecked
      advanceLimit,
      priceBreakup,
      agentSearchData.start_date,
      agentSearchData.end_date
    );
    if (data.balanceAmount === 0) {
      setDisabled({
        ...isDisabled,
        credit: true,
      });
    } else {
      setDisabled({
        ...isDisabled,
        credit: false,
      });
    }
  };

  // Razorpay Start

  const navigate = useNavigate();

  // Safely parse the JSON if the item exists
  const property_name = selectedProperty
    ? JSON.parse(localStorage.getItem("selectedProperty")).property_name
    : null;

  const agentDetails = JSON.parse(localStorage.getItem("agentDetails"));

  const redirectToPaymentPage = (bookingData) => {
    if (!window.Razorpay) {
      console.error("Razorpay script is not loaded");
      return;
    }
    // localStorage.
    const options = {
      key: process.env.REACT_APP_RAZOR_KEY,
      amount: bookingData.balance_amount * 100,
      currency: "INR",
      order_id: bookingData.razorpayOrderId,
      name: property_name,
      description: `Payment against Booking - #${bookingData.bookingId}`,
      image: "path/to/image/ecologo.svg",
      prefill: {
        name: agentDetails.agent_name,
        contact: agentDetails.agent_phone,
        email: agentDetails.agent_email,
      },
      handler: function (response) {
        const data = {
          reservationId: bookingData.bookingId,
          status: "confirmed",
          paidAmount: bookingData.balance_amount,
          gatewayOrderId: response.razorpay_order_id,
          gatewayPaymentId: response.razorpay_payment_id,
          gatewayPaymentSignature: response.razorpay_signature,
          gatewayMethod: "Razorpay",
          gatewayCurrency: "INR",
        };

        const queryString = new URLSearchParams(data).toString();
        navigate(`/payment?${queryString}`);
      },
      modal: {
        ondismiss: function () {
          const data = {
            reservationId: bookingData.bookingId,
            status: "cancelled",
            paidAmount: 0,
            gatewayOrderId: "NULL",
            gatewayPaymentId: "NULL",
            gatewayPaymentSignature: "NULL",
            gatewayMethod: "NULL",
            gatewayCurrency: "NULL",
          };
          const queryString = new URLSearchParams(data).toString();
          navigate(`/cancel-payment?${queryString}`);
        },
      },
      theme: {
        color: "#00aa49",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  // Razorpay End

  return (
    <>
      <div className={styles.container}>
        <div className={styles.popupContainer}>
          <GoBackButtonCustom
            buttonNextText={"Review Reservation Details"}
            onClick={handleClose}
          />

          <div className={styles.child}>
            <div className={styles.generalInfo}>
              <h3>General Information</h3>
              <div className={styles.details}>
                <p>Agent Name </p> <p>{agent.agent_name}</p>
              </div>
              <div className={styles.details}>
                <p> Agent Phone</p>
                <p>{agent.agent_phone || "NA"}</p>
              </div>

              <div className={styles.details}>
                <p> Agent Email</p>
                <p>{agent.agent_email || "NA"}</p>
              </div>
              <div className={styles.details}>
                <p> Check-In</p>
                <p>{formatDateMonthName(agentSearchData.start_date) || "NA"}</p>
              </div>
              <div className={styles.details}>
                <p> Check-Out</p>
                <p>{formatDateMonthName(agentSearchData.end_date)}</p>
              </div>

              <div className={styles.details}>
                <p> Property Discount</p>
                <p>
                  {hotel.automatic_discounts.discount_amount
                    ? hotel.automatic_discounts.discount_amount.toFixed(2)
                    : 0}
                  {hotel.automatic_discounts.discount_type === "percent"
                    ? "%"
                    : ""}
                </p>
              </div>
              <div className={styles.details}>
                <p> Agent Discount</p>
                <p>{hotel.agent_discount ? hotel.agent_discount : 0} %</p>
              </div>
            </div>

            <div className={styles.generalInfo}>
              <h3>Room Details</h3>
              <div className={styles.details}>
                <p>Room Category </p> <p>{selectedRoom.room_name}</p>
              </div>
              <div className={styles.details}>
                <p>Meal Plan </p> <p>{selectedRatePlan.meal_plan}</p>
              </div>
              <div className={styles.details}>
                <p>No of Rooms </p> <p>{agentSearchData.rooms}</p>
              </div>
            </div>

            {/* price breakup */}

            <div className={styles.generalInfo}>
              <h3>Price Break Up</h3>

              <div className={styles.details}>
                <p>Rooms Rate </p>{" "}
                <p>
                  <HandleNaN
                    currency={"₹"}
                    value={selectedRatePlan.rate_per_night}
                    decimal={true}
                  />
                </p>
              </div>

              <div className={styles.details}>
                <p> Subtotal </p>{" "}
                <p>
                  <HandleNaN
                    currency={"₹"}
                    value={priceBreakup.subtotal}
                    decimal={true}
                  />
                </p>
              </div>

              <div className={styles.details}>
                <p> Tax ({priceBreakup.taxSlab} %) </p>{" "}
                <p>
                  <HandleNaN
                    currency={"₹"}
                    value={priceBreakup.taxAmount}
                    decimal={true}
                  />
                </p>
              </div>

              <div className={styles.details}>
                <p>
                  <b>Total Amount</b>
                </p>
                <p>
                  <HandleNaN
                    currency={"₹"}
                    value={priceBreakup.grandTotal}
                    decimal={true}
                  />
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className="flex flex_direction_col">
              <label>
                <input
                  type="checkbox"
                  value={creditLimit}
                  disabled={+creditLimit === 0 || isDisabled.credit}
                  checked={creditChecked}
                  // onChange={() => setCreditChecked(!creditChecked)}
                  onChange={handleCreditCheckboxChange}
                />
                <i>
                  Pay with Credit Payment &nbsp;
                  <HandleNaN
                    currency={"₹"}
                    value={creditLimit}
                    decimal={false}
                  />
                </i>
              </label>

              {/* advance payment checkbox */}
              <label>
                <input
                  type="checkbox"
                  value={advanceLimit}
                  disabled={+advanceLimit === 0 || isDisabled.advance}
                  checked={advanceChecked}
                  onChange={handleAdvanceCheckboxChange}
                  // onChange={() => setAdvanceChecked(!advanceChecked)}
                />
                <i>
                  Pay with Advance Payment &nbsp;
                  <HandleNaN
                    currency={"₹"}
                    value={advanceLimit}
                    decimal={false}
                  />
                </i>
              </label>
            </div>
            <BgThemeButton onClick={handleConfirmAndPayClick}>
              Confirm & Pay
            </BgThemeButton>
          </div>
        </div>
      </div>


      
    </>
  );
};

export default ReservationPopups;
