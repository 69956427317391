import React, { useEffect, useState } from "react";
import {
  SvgBaseAdult,
  SvgCancel,
  SvgChild,
  SvgChildWithBed,
  SvgExtraAdult,
  SvgLock,
  SvgMinus,
  SvgPlus,
  SvgUnLock,
} from "../../assets/SvgIcons";
import styles from "./agentInventory.module.scss";
import { showErrorToast } from "../../assets/toastUtils";
import NoDataFound from "../CustomComponents/NoDataFound";
import Loader from "../UI/Loader/Loader";

const AgentInventory = () => {
  const ID = JSON.parse(localStorage.getItem("selectedProperty"))?.id;
  const agentInfo = JSON.parse(localStorage.getItem("agentDetails"));
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  const fetchInfo = async (propertyId, id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${
          process.env.REACT_APP_BASE_URL
        }/api/v1/get/agent-inventory/${7}/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setData(data);
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };
  function getMonthDay(dateString) {
    const date = new Date(dateString);
    const day = date.getDate(); // Get the day (e.g., 23)
    const month = date
      .toLocaleString("default", { month: "short" })
      .toUpperCase(); // Get the month (e.g., AUG)

    return `${day}<br/> ${month}`;
  }

  useEffect(() => {
    if (ID && agentInfo?.details?.agent_information) {
      fetchInfo(ID, agentInfo.id);
    }
  }, [ID, agentInfo?.details?.agent_information]);

  const [bulkInventoryData, setBulkInventoryData] = useState({
    property_id: ID,
    from_date: "",
    to_date: "",
  });

  const fetchInfoWithDate = async (propertyId, date, agentId) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/agent-inventory/${agentId}/${propertyId}?date=${date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setData(data);
      setDoNotShowCategories(data.data.rooms.map((room) => room.id));
      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const [doNotShowCategories, setDoNotShowCategories] = useState([]);

  const dateClickHandler = (date) => {
    setBulkInventoryData({
      ...bulkInventoryData,
      from_date: date,
    });
    fetchInfoWithDate(ID, date, agentInfo?.details?.agent_information);
  };
  const [hoveredId, setHoveredId] = useState(null);
  const [expandedState, setExpandedState] = useState({});

  // Toggle expanded state for a specific room + rateType
  const toggleExpanded = (roomIndex, rateType) => {
    const key = `${roomIndex}-${rateType}`; // Unique key for room + rateType

    setExpandedState((prevState) => ({
      ...prevState,
      [key]: !prevState[key], // Toggle the expanded state for the specific rateType
    }));
  };

  const handleShowHideRoomClick = (id) => {
    if (doNotShowCategories.includes(id)) {
      setDoNotShowCategories(doNotShowCategories.filter((item) => item !== id));
    } else {
      setDoNotShowCategories([...doNotShowCategories, id]);
    }
  };

  return (
    <div className="main_container">
      {data && data.date_range && data.date_range.length > 0 ? (
        <div className="room-types-full-container">
          {/* <div className="room-types-full-container-div">
            <div className="room-type-heading">Inventory</div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <div></div>
            </div>
          </div> */}

          <div className="inventory-full-container">
            
              
            

            {loader ? (
              <Loader />
            ) : (
              <table
                style={{ width: "100%" }}
                className={styles.tableInventory}
              >
                <thead>
                  <tr>
                    <th className={styles.longHeader}>Inventory & Rates</th>
                    <th
                      style={{ border: "none" }}
                      className={styles.dateHeaders}
                    ></th>
                    <th
                      style={{ border: "none" }}
                      className={styles.dateHeaders}
                    ></th>

                    {data.date_range?.map((date, index) => (
                      <th key={index} className={styles.dateHeaders}>
                        <div className="flex justify_content_center align_items_center">
                          <span
                            style={
                              index === 0
                                ? {
                                    backgroundColor: "#3968ed",
                                    color: "white",
                                  }
                                : {}
                            }
                            dangerouslySetInnerHTML={{
                              __html: getMonthDay(date),
                            }}
                            onClick={() => dateClickHandler(date)}
                          />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data?.rooms?.map((room, roomIndex) => (
                    <tr key={roomIndex}>
                      <td colSpan={data.date_range.length + 3}>
                        <table
                          style={{
                            width: "100%",
                            border: "1px solid #ccc",
                          }}
                          className={`${styles.innerTable} ${styles.InventoryRatesInnerTable}`}
                        >
                          <tbody>
                            <tr>
                              <td className={styles.longHeader}>
                                <div className="flex flex_gap_10 align_items_center ">
                                  <span
                                    onClick={() =>
                                      handleShowHideRoomClick(room.id)
                                    }
                                    className="pointer"
                                  >
                                    {" "}
                                    {doNotShowCategories.includes(room.id) ? (
                                      <SvgMinus />
                                    ) : (
                                      <SvgPlus />
                                    )}
                                  </span>
                                  <p className={styles.rateType}>
                                    {room.room_name}
                                  </p>
                                </div>
                              </td>

                              <td
                                style={{ border: "none" }}
                                className={styles.innerTableData}
                              />
                              <td
                                style={{ border: "none" }}
                                className={styles.innerTableData}
                              />

                              {room.inventory
                                ?.slice(0, 15)
                                .map((childData, index) => (
                                  <td
                                    key={index}
                                    className={styles.innerTableData}
                                  >
                                    <div className="flex justify_content_center align_items_center">
                                      {childData?.blocked ? (
                                        <span
                                          className={`flex flex_direction_col align_items_center  ${styles["flex-container"]}`}
                                        >
                                          <input
                                            disabled={true}
                                            defaultValue={"00"}
                                            className="inventory-1st-column-inner-input"
                                            style={{
                                              background: "#fb04044d",
                                            }}
                                            type="number"
                                          />
                                          {childData?.sold_rooms} Sold /{" "}
                                          {childData?.hold_rooms} Hold
                                          <span
                                            className={
                                              styles["hover-icon-visible"]
                                            }
                                            onMouseOver={() =>
                                              setHoveredId(index)
                                            }
                                            onMouseOut={() =>
                                              setHoveredId(null)
                                            }
                                          >
                                            {hoveredId === index ? (
                                              <span
                                              // onClick={() => {
                                              //   if (
                                              //     permissions &&
                                              //     permissions.includes(
                                              //       "full_access_channel_management"
                                              //     )
                                              //   ) {
                                              //     setShowConfirmPopupUnblock(
                                              //       true
                                              //     );
                                              //     setDeleteWithReasonData({
                                              //       childData: childData,
                                              //       roomData: room,
                                              //     });
                                              //   } else {
                                              //     showErrorToast(
                                              //       "Permission denied: Please contact your administrator for access"
                                              //     );
                                              //   }
                                              // }}
                                              >
                                                <SvgUnLock />
                                              </span>
                                            ) : (
                                              <SvgLock />
                                            )}
                                            <span className={styles["tooltip"]}>
                                              <b>Blocked-By</b>{" "}
                                              {childData.blocked_by}
                                            </span>
                                          </span>
                                        </span>
                                      ) : (
                                        <span
                                          className={`flex flex_direction_col align_items_center  ${styles["flex-container"]}`}
                                        >
                                          <input
                                            disabled={true}
                                            defaultValue={
                                              childData.available_rooms
                                            }
                                            className="inventory-1st-column-inner-input"
                                            type="number"
                                          />
                                          {childData?.sold_rooms} Sold /{" "}
                                          {childData?.hold_rooms} Hold
                                          <span
                                            className={styles["hover-icon"]}
                                            // onClick={() => {
                                            //   if (
                                            //     permissions &&
                                            //     permissions.includes(
                                            //       "full_access_channel_management"
                                            //     )
                                            //   ) {
                                            //     setShowConfirmPopupBlock(true);
                                            //     setDeleteWithReasonData({
                                            //       childData: childData,
                                            //       roomData: room,
                                            //     });
                                            //   } else {
                                            //     showErrorToast(
                                            //       "Permission denied: Please contact your administrator for access"
                                            //     );
                                            //   }
                                            // }}
                                          >
                                            <SvgLock />
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </td>
                                ))}
                            </tr>

                            {/*===== expand collapse room category conditionally =======*/}
                            {doNotShowCategories.includes(room.id) ? (
                              <>
                                {Object.keys(room.rates).map(
                                  (rateType, index) => {
                                    const key = `${roomIndex}-${rateType}`;
                                    return (
                                      <React.Fragment key={index}>
                                        <tr>
                                          <td
                                            className={`${styles.longHeader} ${styles.addBorderTop} `}
                                          >
                                            <div className="flex flex_gap_10 align_items_center p_10">
                                              <button
                                                onClick={() =>
                                                  toggleExpanded(
                                                    roomIndex,
                                                    rateType
                                                  )
                                                }
                                              >
                                                {expandedState[key] ? (
                                                  <SvgMinus />
                                                ) : (
                                                  <SvgPlus />
                                                )}
                                              </button>
                                              <p className={styles.rateType}>
                                                {rateType}
                                              </p>
                                            </div>
                                          </td>
                                          <td
                                            className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                          >
                                            <SvgBaseAdult />
                                          </td>
                                          <td
                                            className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                          >
                                            <div
                                              className={`flex flex_direction_col flex_gap_10 text_center ${styles.multipleRatesContainer}`}
                                            >
                                              <span> PMS Rates</span>
                                              <hr />
                                              <span>AgentRates</span>
                                            </div>
                                          </td>
                                          {data.date_range.map((date) => (
                                            <td
                                              key={date}
                                              className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                            >
                                              <div
                                                className={`flex flex_direction_col flex_gap_10 ${styles.multipleRatesContainer}`}
                                              >
                                                <span
                                                  className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                                >
                                                  <input
                                                    disabled={true}
                                                    type="number"
                                                    value={
                                                      room.rates[rateType][
                                                        date
                                                      ]?.[0]?.rate_bar_price ||
                                                      "0"
                                                    }
                                                    className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                  />
                                                </span>
                                                <hr />
                                                <span
                                                  className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                                >
                                                  <input
                                                    disabled={true}
                                                    type="number"
                                                    value={
                                                      room.rates[rateType][
                                                        date
                                                      ]?.[0]?.agent_rate || "0"
                                                    }
                                                    className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                  />
                                                </span>
                                              </div>
                                            </td>
                                          ))}
                                        </tr>

                                        {/* Only show when expanded */}
                                        {expandedState[key] && (
                                          <>
                                            <tr>
                                              <td />
                                              <td
                                                className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                              >
                                                <SvgExtraAdult />
                                              </td>
                                              <td
                                                className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                              >
                                                <div
                                                  className={`flex flex_direction_col flex_gap_10 text_center ${styles.multipleRatesContainer}`}
                                                >
                                                  <span> PMS Rates</span>
                                                  <hr />
                                                  <span>AgentRates</span>
                                                </div>
                                              </td>
                                              {data.date_range.map((date) => (
                                                <td
                                                  key={date}
                                                  className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                                >
                                                  <div
                                                    className={`flex flex_direction_col flex_gap_10 ${styles.multipleRatesContainer}`}
                                                  >
                                                    <span
                                                      className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                                    >
                                                      <input
                                                        disabled={true}
                                                        type="number"
                                                        value={
                                                          room.rates[rateType][
                                                            date
                                                          ]?.[0]?.extra_adult ||
                                                          "0"
                                                        }
                                                        className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                      />
                                                    </span>
                                                    <hr />
                                                    <span
                                                      className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                                    >
                                                      <input
                                                        disabled={true}
                                                        type="number"
                                                        value={
                                                          room.rates[rateType][
                                                            date
                                                          ]?.[0]
                                                            ?.agent_extra_adult ||
                                                          "0"
                                                        }
                                                        className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                      />
                                                    </span>
                                                  </div>
                                                </td>
                                              ))}
                                            </tr>

                                            <tr>
                                              <td />
                                              <td
                                                className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                              >
                                                <SvgChild />
                                              </td>
                                              <td
                                                className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                              >
                                                <div
                                                  className={`flex flex_direction_col flex_gap_10 text_center ${styles.multipleRatesContainer}`}
                                                >
                                                  <span> PMS Rates</span>
                                                  <hr />
                                                  <span>AgentRates</span>
                                                </div>
                                              </td>
                                              {data.date_range.map((date) => (
                                                <td
                                                  key={date}
                                                  className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                                >
                                                  <div
                                                    className={`flex flex_direction_col flex_gap_10 ${styles.multipleRatesContainer}`}
                                                  >
                                                    <span
                                                      className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                                    >
                                                      <input
                                                        disabled={true}
                                                        type="number"
                                                        value={
                                                          room.rates[rateType][
                                                            date
                                                          ]?.[0]
                                                            ?.child_with_Bed ||
                                                          "0"
                                                        }
                                                        className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                      />
                                                    </span>
                                                    <hr />
                                                    <span
                                                      className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                                    >
                                                      <input
                                                        disabled={true}
                                                        type="number"
                                                        value={
                                                          room.rates[rateType][
                                                            date
                                                          ]?.[0]
                                                            ?.agent_child_with_Bed ||
                                                          "0"
                                                        }
                                                        className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                      />
                                                    </span>
                                                  </div>
                                                </td>
                                              ))}
                                            </tr>

                                            <tr>
                                              <td />
                                              <td
                                                className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                              >
                                                <SvgChildWithBed />
                                              </td>
                                              <td
                                                className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                              >
                                                <div
                                                  className={`flex flex_direction_col flex_gap_10 text_center ${styles.multipleRatesContainer}`}
                                                >
                                                  <span> PMS Rates</span>
                                                  <hr />
                                                  <span>AgentRates</span>
                                                </div>
                                              </td>
                                              {data.date_range.map((date) => (
                                                <td
                                                  key={date}
                                                  className={`${styles.innerTableData} ${styles.addBorderTop}`}
                                                >
                                                  <div
                                                    className={`flex flex_direction_col flex_gap_10 ${styles.multipleRatesContainer}`}
                                                  >
                                                    <span
                                                      className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                                    >
                                                      <input
                                                        disabled={true}
                                                        type="number"
                                                        value={
                                                          room.rates[rateType][
                                                            date
                                                          ]?.[0]
                                                            ?.child_without_Bed ||
                                                          "0"
                                                        }
                                                        className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                      />
                                                    </span>
                                                    <hr />
                                                    <span
                                                      className={`flex flex_direction_col align_items_center ${styles.inputContainer}`}
                                                    >
                                                      <input
                                                        disabled={true}
                                                        type="number"
                                                        value={
                                                          room.rates[rateType][
                                                            date
                                                          ]?.[0]
                                                            ?.child_without_Bed ||
                                                          "0"
                                                        }
                                                        className={`inventory-1st-column-inner-input ${styles.inputRateChange}`}
                                                      />
                                                    </span>
                                                  </div>
                                                </td>
                                              ))}
                                            </tr>
                                          </>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </>
                            ) : null}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      ) : (
        <NoDataFound />
      )}
    </div>
  );
};

export default AgentInventory;
