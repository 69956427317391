import React, { useEffect, useState } from "react";
import VerticalInputFieldCustom from "../../CustomComponents/VerticalInputFieldCustom";
import { BgOutlineThemeButton, BgThemeButton } from "../../UI/Buttons";
import "./AgentDashboard.css";
import HandleNaN from "../../CustomComponents/HandleNaN/HandleNaN";
import { showErrorToast } from "../../../assets/toastUtils";
import NoDataFound from "../../CustomComponents/NoDataFound";
import TextDateFormatter from "../../Communication/TextDateFormatter/TextDateFormatter";
import BookingStatusUpdate from "../../CustomComponents/BookingStatusUpdate";
import ReviewReservationsPopup from "./ReviewReservationPopup";
import Loader from "../../UI/Loader/Loader";
import { useLocation, useParams } from "react-router-dom";
import styles from "./AgentDashboard.module.scss";
import ReservationPopups from "./popups/ReservationPopups";
import {
  calculateDiscount,
  calculateTotalArrayObjectKey,
} from "../../../utils/utils";
import CarouselComponent from "../../../Components/carouselComponent/CarouselComponent";
import BalanceSummaryCard from "./BalanceSummaryCard/BalanceSummaryCard";
import AgentInventory from "../AgentInventory";

const AgentDashboard = ({ fetchAgentInfo, agentId }) => {
  const ID = JSON.parse(localStorage.getItem("selectedProperty"))?.id;
  const location = useLocation();
  const agentInfo = JSON.parse(localStorage.getItem("agentDetails"));
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [loader, setLoader] = useState(false);
  const currentDate = new Date().toISOString().split("T")[0];
  const [hotel, setHotel] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedRatePlan, setSelectedRatePlan] = useState(null);
  const [roomDetailsPopupState, setRoomDetailsPopupState] = useState([]);
  const [dashboardInfo, setDashboardInfo] = useState([]);
  const [agentDiscount, setAgentDiscount] = useState(null);
  const [showReservationDetails, setShowReservationsDetails] = useState(false);
  const [agentSearchData, setAgentSearchData] = useState({
    start_date: currentDate,
    end_date: getNextDay(currentDate),
    rooms: 1,
    adults: 2,
    number_of_children: 0,
    childAge: [],
    property_id: "",
  });

  function getNextDay(inputDate) {
    const currentDate = new Date(inputDate);
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    return nextDate.toISOString().split("T")[0];
  }

  const setAgentDataHandleChange = (event) => {
    setAgentSearchData({
      ...agentSearchData,
      [event.target.name]: event.target.value,
    });
  };

  const toggleRoomDetailsPopup = (roomIndex) => {
    const updatedRoomDetailsPopupState = [...roomDetailsPopupState];
    updatedRoomDetailsPopupState[roomIndex] =
      !updatedRoomDetailsPopupState[roomIndex];
    setRoomDetailsPopupState(updatedRoomDetailsPopupState);
  };

  // new code  search from
  const checkAvailability = async (event) => {
    event.preventDefault();
    setLoader(true);
    const formData = new FormData();
    formData.append("hotelId", ID);
    formData.append("checkIn", agentSearchData?.start_date);
    formData.append("checkOut", agentSearchData?.end_date);
    formData.append("child", agentSearchData?.number_of_children?.toString());
    formData.append("agent_info_id", agentInfo?.id);
    formData.append("adults", agentSearchData?.adults?.toString());
    formData.append("rooms", agentSearchData?.rooms?.toString());

    agentSearchData.childAge.forEach((age, index) => {
      formData.append(`childAge[${index}]`, age?.toString());
    });

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/agent/booking-engine/check-availability`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        body: formData,
      }
    );

    const data = await response.json();
    if (data) {
      setHotel(data?.data);
      setAgentDiscount(data?.data?.agent_discount);
      setLoader(false);
    }
    setLoader(false);
  };

  const handleSelectRoom = (room, ratePlan) => {
    setSelectedRoom(room);
    setSelectedRatePlan(ratePlan);
    setShowReservationsDetails(true);
  };

  const [showPaxDropDown, setShowPaxDropDown] = useState(false);

  const updateValue = (field, action) => {
    setAgentSearchData((prev) => {
      const newValue =
        action === "increment" ? prev[field] + 1 : prev[field] - 1;
      return {
        ...prev,
        [field]: newValue < 0 ? 0 : newValue, // Prevent negative values
      };
    });
  };

  const handleChildAgeChange = (index, age) => {
    const updatedChildAge = [...agentSearchData.childAge];
    updatedChildAge[index] = age;
    setAgentSearchData((prev) => ({
      ...prev,
      childAge: updatedChildAge,
    }));
  };

  const addOrRemoveChild = (action) => {
    setAgentSearchData((prev) => {
      const updatedChildAge = [...prev.childAge];
      if (action === "increment") {
        updatedChildAge.push(""); // Add a new child age input
      } else {
        updatedChildAge.pop(); // Remove the last child age input
      }
      return {
        ...prev,
        number_of_children: updatedChildAge.length,
        childAge: updatedChildAge,
      };
    });
  };

  const [agent, setAgent] = useState(
    JSON.parse(localStorage.getItem("agentInfo"))
  );

  return (
    <>
      <section className="dashboard_page">
        <div className="dashboard_page_left">
          <div className="checkin_rooms_status">
            <form
              onSubmit={checkAvailability}
              className="flex flex_gap_10"
              style={{ alignItems: "flex-end" }}
            >
              <div style={{ width: "20%" }}>
                <VerticalInputFieldCustom
                  required={true}
                  type="date"
                  name="start_date"
                  className="textfield"
                  format={"dd-mm-yyyy"}
                  titleName="Check-In"
                  min={currentDate}
                  // defaultValue={currentDate}
                  onChange={setAgentDataHandleChange}
                  value={agentSearchData.start_date}
                />
              </div>
              <div style={{ width: "20%" }}>
                <VerticalInputFieldCustom
                  required={true}
                  type="date"
                  name="end_date"
                  className="textfield"
                  titleName="Check-Out"
                  focused={false}
                  onChange={setAgentDataHandleChange}
                  autoComplete="off"
                  min={getNextDay(agentSearchData.start_date || currentDate)}
                  value={agentSearchData.end_date}
                />
              </div>
              <div style={{ width: "40%" }}>
                <div className={styles.room_adults_child_input_drop_down}>
                  <div className="form-container-div">
                    <div style={{ marginBottom: "4px" }}>
                      Rooms & Adults
                      <span style={{ color: "red" }}>*</span>
                    </div>
                    <div
                      className={styles.room_adults_child_input_box}
                      onClick={() => setShowPaxDropDown(!showPaxDropDown)}
                    >
                      <div className="flex justify-content-between ">
                        <span>{agentSearchData.adults} adult</span>{" "}
                        <span className={styles.divider}>·</span>
                        <span>{agentSearchData.rooms} rooms </span>{" "}
                        <span className={styles.divider}>·</span>
                        <span>
                          {agentSearchData.number_of_children} children
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* ////////////////////////////////////////////// */}

                  {showPaxDropDown && (
                    <div className="room_adults_child_input_options">
                      {[
                        { label: "Adults", field: "adults" },
                        { label: "Rooms", field: "rooms" },
                      ].map(({ label, field }) => (
                        <div className="optionItem" key={field}>
                          <div>
                            <p className="optionText">{label}</p>
                          </div>
                          <div className="optionCounter">
                            <button
                              type="button"
                              className="optionCounterButton"
                              onClick={() => updateValue(field, "decrement")}
                            >
                              -
                            </button>
                            <div>
                              <input
                                className="adult_room_input"
                                name={field}
                                value={agentSearchData[field]}
                                onChange={(e) =>
                                  setAgentSearchData((prev) => ({
                                    ...prev,
                                    [field]: Number(e.target.value),
                                  }))
                                }
                              />
                            </div>
                            <button
                              type="button"
                              className="optionCounterButton"
                              onClick={() => updateValue(field, "increment")}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      ))}

                      <div className="optionItem">
                        <div>
                          <p className="optionText">Children</p>
                        </div>
                        <div className="optionCounter">
                          <button
                            type="button"
                            className="optionCounterButton"
                            onClick={() => addOrRemoveChild("decrement")}
                          >
                            -
                          </button>
                          <div>
                            <input
                              className="adult_room_input"
                              name="number_of_children"
                              value={agentSearchData.number_of_children}
                              readOnly
                            />
                          </div>
                          <button
                            type="button"
                            className="optionCounterButton"
                            onClick={() => addOrRemoveChild("increment")}
                          >
                            +
                          </button>
                        </div>
                      </div>

                      {agentSearchData?.childAge?.map((age, index) => (
                        <div className="optionItem" key={index}>
                          <div>
                            <p className="optionText">Child {index + 1} Age</p>
                          </div>
                          <div>
                            <input
                              type="number"
                              className={styles.child_age_input}
                              value={age}
                              onChange={(e) =>
                                handleChildAgeChange(index, e.target.value)
                              }
                            />
                          </div>
                        </div>
                      ))}

                      <BgThemeButton
                        onClick={() => setShowPaxDropDown(!showPaxDropDown)}
                        style={{ width: "100%" }}
                      >
                        Apply
                      </BgThemeButton>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ width: "20%" }}>
                <BgThemeButton
                  // onClick={fetchHotels}
                  type={"submit"}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M12.0203 11.578L14.8757 14.4327L13.9323 15.376L11.0777 12.5207C10.0155 13.3722 8.69434 13.8353 7.33301 13.8333C4.02101 13.8333 1.33301 11.1453 1.33301 7.83334C1.33301 4.52134 4.02101 1.83334 7.33301 1.83334C10.645 1.83334 13.333 4.52134 13.333 7.83334C13.3349 9.19468 12.8718 10.5158 12.0203 11.578ZM10.683 11.0833C11.5291 10.2133 12.0016 9.04696 11.9997 7.83334C11.9997 5.25534 9.91101 3.16668 7.33301 3.16668C4.75501 3.16668 2.66634 5.25534 2.66634 7.83334C2.66634 10.4113 4.75501 12.5 7.33301 12.5C8.54663 12.5019 9.71293 12.0294 10.583 11.1833L10.683 11.0833Z"
                        fill="white"
                      />
                    </svg>
                  }
                  children={"Search"}
                />
              </div>
            </form>
            <div>
              {loader ? (
                <Loader />
              ) : (
                <>
                  {hotel?.rooms &&
                    hotel?.rooms.map((room, roomIndex) => {
                      return (
                        <div
                          className="show_hotel_rooms"
                          key={room.id}
                          style={{ borderRadius: "4px" }}
                        >
                          <div className="show_hotel_rooms_top_bar flex align_items_center justify_content_between">
                            <h2 className="agent_dashboard_common_title">
                              {room.room_name}{" "}
                              <span className="agent_dashboard_common_subtitle">
                                {room.room_view && room.room_view}
                              </span>
                            </h2>
                            <button
                              className="flex align_items_center pointer"
                              onClick={() => toggleRoomDetailsPopup(roomIndex)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                              >
                                <path
                                  d="M7.33301 11.8334H8.66634V7.83337H7.33301V11.8334ZM7.99967 6.50004C8.18856 6.50004 8.34701 6.43604 8.47501 6.30804C8.60301 6.18004 8.66679 6.02182 8.66634 5.83337C8.66634 5.64448 8.60234 5.48604 8.47434 5.35804C8.34634 5.23004 8.18812 5.16626 7.99967 5.16671C7.81079 5.16671 7.65234 5.23071 7.52434 5.35871C7.39634 5.48671 7.33256 5.64493 7.33301 5.83337C7.33301 6.02226 7.39701 6.18071 7.52501 6.30871C7.65301 6.43671 7.81123 6.50048 7.99967 6.50004ZM7.99967 15.1667C7.07745 15.1667 6.21079 14.9916 5.39967 14.6414C4.58856 14.2912 3.88301 13.8163 3.28301 13.2167C2.68301 12.6167 2.20812 11.9112 1.85834 11.1C1.50856 10.2889 1.33345 9.42226 1.33301 8.50004C1.33301 7.57782 1.50812 6.71115 1.85834 5.90004C2.20856 5.08893 2.68345 4.38337 3.28301 3.78337C3.88301 3.18337 4.58856 2.70849 5.39967 2.35871C6.21079 2.00893 7.07745 1.83382 7.99967 1.83337C8.9219 1.83337 9.78856 2.00849 10.5997 2.35871C11.4108 2.70893 12.1163 3.18382 12.7163 3.78337C13.3163 4.38337 13.7915 5.08893 14.1417 5.90004C14.4919 6.71115 14.6668 7.57782 14.6663 8.50004C14.6663 9.42226 14.4912 10.2889 14.141 11.1C13.7908 11.9112 13.3159 12.6167 12.7163 13.2167C12.1163 13.8167 11.4108 14.2918 10.5997 14.642C9.78856 14.9923 8.9219 15.1672 7.99967 15.1667ZM7.99967 13.8334C9.48856 13.8334 10.7497 13.3167 11.783 12.2834C12.8163 11.25 13.333 9.98893 13.333 8.50004C13.333 7.01115 12.8163 5.75004 11.783 4.71671C10.7497 3.68337 9.48856 3.16671 7.99967 3.16671C6.51079 3.16671 5.24967 3.68337 4.21634 4.71671C3.18301 5.75004 2.66634 7.01115 2.66634 8.50004C2.66634 9.98893 3.18301 11.25 4.21634 12.2834C5.24967 13.3167 6.51079 13.8334 7.99967 13.8334Z"
                                  fill="#333333"
                                />
                              </svg>
                              &nbsp; Room Details
                            </button>
                          </div>

                          {roomDetailsPopupState[roomIndex] && (
                            <div className="room_details_popup">
                              <div className="room_details_popup_main">
                                <div className="flex justify_content_between common_title mb-20">
                                  <h3 className="">{room.room_name}</h3>
                                  <button
                                    onClick={() =>
                                      toggleRoomDetailsPopup(roomIndex)
                                    }
                                    style={{
                                      border: "none",
                                      background: "transparent",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                    >
                                      <path
                                        d="M1 12.895L13 1"
                                        stroke="#AAAAAA"
                                        strokeWidth="1.2"
                                      />
                                      <path
                                        d="M13 12.895L0.999999 0.999977"
                                        stroke="#AAAAAA"
                                        strokeWidth="1.2"
                                      />
                                    </svg>
                                  </button>
                                </div>
                                <div>
                                  <h2 className="agent_dashboard_common_title">
                                    Highlights
                                  </h2>
                                  <ul
                                    className="flex p-0 flex_wrap m-10"
                                    style={{ paddingLeft: "1.2em" }}
                                  >
                                    <li className="w-half mb-10">
                                      <strong>Rooms Size:</strong>{" "}
                                      {room.room_size} {room.room_size_unit}
                                    </li>
                                    <li className="w-half mb-10">
                                      <strong>Bed type:</strong> {room.room_bed}
                                    </li>
                                    <li className="w-half">
                                      <strong>View Type:</strong>{" "}
                                      {room.room_view}
                                    </li>
                                  </ul>
                                </div>
                                {/* {Array.isArray(amenitiesArray) &&
                              <div>
                              <h2 className='agent_dashboard_common_title'>Room Amenities</h2>
                              <ul className='flex p-0 flex_wrap m-10' style={{ paddingLeft: '1.2em' }}>
                              {Array.isArray(amenitiesArray) && amenitiesArray.map((amenity, index) => (
                              <li className='w-half mb-10' key={index}>
                              {amenity}
                              </li>
                              ))}
                              </ul>
                              </div>
                            } */}
                              </div>
                            </div>
                          )}

                          <div className="hotel_rooms flex justify_content_between align_items_center">
                            <div className="w-30 hotel_rooms_image_container">
                              <CarouselComponent
                                images={room.photos}
                                width="200px"
                                height="200px"
                              />
                            </div>

                            <div className="w-70 hotel_rooms_plans_container">
                              {room.rates.map((ratePlan, roomRateIndex) => {
                                const websiteDiscountPrice = calculateDiscount(
                                  +ratePlan.rate_per_night,
                                  +hotel.automatic_discounts.discount_amount,
                                  hotel.automatic_discounts.discount_type
                                )?.discountedPrice;
                                const agentDiscountPrice = calculateDiscount(
                                  websiteDiscountPrice,
                                  agentDiscount,
                                  "percent"
                                )?.discountedPrice;

                                return (
                                  <div
                                    key={ratePlan.id}
                                    className="show_hotel_rooms_plans flex justify_content_between align_items_center"
                                    style={{
                                      borderBottom:
                                        roomRateIndex === room.rates.length - 1
                                          ? "none"
                                          : "",
                                    }}
                                  >
                                    <div className="show_hotel_rooms_plans_left w-half">
                                      <h2 className="agent_dashboard_common_sub_title ">
                                        {ratePlan.meal_plan_name}
                                      </h2>
                                      {/* <CheckWithoutBgUnderlineBtn
                                    children={'View Price Details'}
                                    style={{ fontSize: '12px', fontWeight: '400'   }}
                                    // onClick={() =>
                                    //   toggleRateDetailsPopup(roomIndex)  
                                    // }
                                  /> */}
                                    </div>
                                    <div className="show_hotel_rooms_plans_right flex align_items_center flex_gap_10 w-half justify_content_end">
                                      <div className="flex flex_direction_col">
                                        <p
                                          className="plan_price flex align_items_center"
                                          style={{ gap: "5px" }}
                                        >
                                          Room Rate:
                                          <span
                                            style={{
                                              textDecorationLine:
                                                "line-through",
                                            }}
                                          >
                                            <HandleNaN
                                              currency={"₹"}
                                              value={ratePlan.rate_per_night}
                                              decimal={false}
                                            />
                                          </span>
                                        </p>
                                        <p
                                          className="plan_price flex align_items_center"
                                          style={{ gap: "5px" }}
                                        >
                                          Website Price:
                                          <span
                                            style={{
                                              textDecorationLine:
                                                "line-through",
                                            }}
                                          >
                                            <HandleNaN
                                              currency={"₹"}
                                              value={websiteDiscountPrice}
                                              decimal={false}
                                            />
                                          </span>
                                        </p>
                                        <p
                                          className="plan_price flex align_items_center"
                                          style={{ gap: "5px" }}
                                        >
                                          Your Price:
                                          <strong>
                                            <HandleNaN
                                              currency={"₹"}
                                              value={agentDiscountPrice}
                                              decimal={false}
                                            />
                                          </strong>
                                        </p>
                                      </div>

                                      <div>
                                        {ratePlan.status === "UNAVAILABLE" ? (
                                          <button
                                            style={{
                                              background: "dimgray",
                                              color: "white",
                                              opacity: "0.5",
                                              cursor: "not-allowed",
                                              border: "none",
                                              fontSize: "12px",
                                              height: "25px",
                                              width: "100px",
                                            }}
                                            disabled
                                          >
                                            Sold Out
                                          </button>
                                        ) : (
                                          <>
                                            {ratePlan.add_rooms &&
                                            ratePlan.required_rooms >
                                              agentSearchData?.rooms ? (
                                              <>
                                                <button
                                                  style={{
                                                    height: "25px",
                                                    fontSize: "12px",
                                                    width: "100px",
                                                  }}
                                                  className="disabled_button_booking_flow"
                                                  disabled
                                                >
                                                  Select Room
                                                  <div className="disabled_btn_tooltip">
                                                    Please add more room
                                                  </div>
                                                </button>
                                              </>
                                            ) : (
                                              <>
                                                <BgOutlineThemeButton
                                                  children={"Select Rooms"}
                                                  style={{
                                                    height: "25px",
                                                    fontSize: "12px",
                                                    width: "100px",
                                                    padding: "0px",
                                                  }}
                                                  onClick={() =>
                                                    handleSelectRoom(
                                                      room,
                                                      ratePlan
                                                    )
                                                  }
                                                />
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            </div>
          </div>
        </div>

        {!loader && (
          <div className="dashboard_page_right">
            <BalanceSummaryCard
              heading={"Your Credit Limit"}
              firstLimitHeading={"Total Credit Limit"}
              secondLimitHeading={"Remaining Limit"}
              firstLimitValue={calculateTotalArrayObjectKey(
                agent?.credit,
                "credit_amount"
              )}
              secondLimitValue={calculateTotalArrayObjectKey(
                agent?.credit,
                "balance_amount"
              )}
            />
            <BalanceSummaryCard
              heading={"Your Advance Limit"}
              firstLimitHeading={"Total Advance Limit"}
              secondLimitHeading={"Remaining Limit"}
              firstLimitValue={calculateTotalArrayObjectKey(
                agent?.advance,
                "advance_amount"
              )}
              secondLimitValue={calculateTotalArrayObjectKey(
                agent?.advance,
                "balance_amount"
              )}
            />
          </div>
        )}

        {showReservationDetails && (
          <ReservationPopups
            hotel={hotel}
            agentSearchData={agentSearchData}
            selectedRoom={selectedRoom}
            selectedRatePlan={selectedRatePlan}
            setShowReservationsDetails={setShowReservationsDetails}
            agent={agent}
            creditLimit={agent?.remainingCredits}
            advanceLimit={calculateTotalArrayObjectKey(
              agent?.advance,
              "balance_amount"
            )}
            ID={ID}
            fetchAgentInfo={fetchAgentInfo}
            agentId={agentId}
          />
        )}
      </section>
      {/* <AgentInventory /> */}
    </>
  );
};

export default AgentDashboard;
