import { IndexTable, LegacyCard } from "@shopify/polaris";
import NoDataFound from "./NoDataFound";
import CustomCssComponentTableText from "./CustomCssComponentTableText";
import DownloadCsvTables from "./DownloadCsvTables/DownloadCsvTables";
import DownloadPdfTables from "./DownloadPdfTables/DownloadPdfTables";
import {
  calculateTotalWithReduce,
  formatIndianCurrency,
} from "../../utils/utils";
import {
  CustomReportTable,
  CustomReportTableCell,
  CustomReportTableRow,
} from "./CustomReportComponent/CustomReportComponent";

import ButtonToDownloadTablePDF from "./DownloadPdfTables/ButtonToDownloadTablePDF";
import DownloadCSVButton from "./DownloadCsvTables/DownloadCSVButton";
import { useState } from "react";
import AccordionComponent from "../NewCustomComp/accordionComponent/AccordionComponent";
import InformationCustomPopup from "./InformationCustomPopup/InformationCustomPopup";
const ReportComplimentaryKot = ({ reportData }) => {
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [informationPopup, setInformationPopup] = useState(false);

  const names = [
    {
      kot_id: "KOT ID",
      kot_date: "Date",
      kot_grand_total: "SubTotal",
      kot_tax_amount: "Tax",
      kot_total_amount: "Grand Total",
    },
  ];
  const rowMarkup =
    reportData &&
    reportData?.map(
      (
        {
          kot_id,
          kot_date,
          kot_grand_total,
          kot_reason,
          kot_tax_amount,
          kot_total_amount,
          rooms_occupancy,
          room_name,
          total_rooms,
          sold_rooms,
        },
        index
      ) => {
        return (
          <IndexTable.Row id={index} key={index}>
            <IndexTable.Cell>{kot_id}</IndexTable.Cell>
            <IndexTable.Cell>{kot_date}</IndexTable.Cell>

            <IndexTable.Cell>
              {formatIndianCurrency(kot_total_amount)}
            </IndexTable.Cell>
            <IndexTable.Cell>
              {formatIndianCurrency(kot_tax_amount)}
            </IndexTable.Cell>
            <IndexTable.Cell>
              {formatIndianCurrency(kot_grand_total)}
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      }
    );

  const calculateTotal = (items, key) => {
    return items.reduce((total, item) => +total + +item[key], 0);
  };

  const calculateTotals = (
    <CustomReportTableRow
      CustomReportTableRow
      id={"id"}
      key={"id"}
      bodyStyles={{
        borderTop: "1px solid #CCC",
        backgroundColor: "lightgray",
      }}
    >
      <CustomReportTableCell>{"Total"}</CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>

      <CustomReportTableCell>
        {" "}
        {formatIndianCurrency(
          calculateTotalWithReduce(reportData, "kot_total_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(reportData, "kot_tax_amount")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell>
        {formatIndianCurrency(
          calculateTotalWithReduce(reportData, "kot_grand_total")
        )}
      </CustomReportTableCell>
      <CustomReportTableCell></CustomReportTableCell>
    </CustomReportTableRow>
  );

  return (
    <>
      <div
        id="receipt"
        className="room-types-full-container reports-new-background"
      >
        <div className="room-types-full-container-div">
          <div className="flex flex_gap_5 " style={{ alignItems: "unset" }}>
            <div className="room-type-heading">Complimentary KOT's</div>

            <div
              className="hoverCursor"
              onClick={() => setInformationPopup(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#3968ED"
              >
                <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
              </svg>
            </div>
          </div>

          {reportData?.length > 0 && (
            <div className="flex flex_gap_15 align_items_center" id="pdf">
              <ButtonToDownloadTablePDF
                itemToDownloadID={"receipt"}
                downloadFileName={"complimentary_kot"}
                ignoreFromPrint={"pdf"}
                // pdfStyles={

                // }
              />
              <DownloadCSVButton
                itemToDownloadID={"receipt"}
                downloadFileName={"complimentary_kot"}
              />
            </div>
          )}
        </div>

        {reportData && reportData.length > 0 ? (
          // <>
          //   <LegacyCard>
          //     <IndexTable
          //       resourceName={resourceName}
          //       itemCount={reportData.length}
          //       selectable={false}
          //       headings={[
          //         { title: "KOT ID" },
          //         { title: "Date" },
          //         { title: "SubTotal" },
          //         { title: "Tax Amount" },
          //         { title: "Grand Total" },
          //       ]}
          //     >
          //       {rowMarkup}
          //     </IndexTable>
          //   </LegacyCard>
          // </>

          <CustomReportTable
            allItems={reportData} // table data
            // selectedItems={selectedArray} //selected items from table
            // setSelectedItems={setSelectedArray} // state from parent component to update table
            showCheckbox={false}
            headings={[
              "KOT ID",
              "Date",
              "SubTotal",
              "Tax Amount",
              "Grand Total",
              "Reason",
            ]}
            headerStyles={{
              backgroundColor: "#dddddd",
              fontSize: "12px",
              textAlign: "center",
            }}
            tableStyles={{ textAlign: "left" }}
          >
            {reportData?.map(
              ({
                id,
                count,
                item,
                sale,
                kot_id,
                kot_date,
                kot_grand_total,
                kot_reason,
                kot_tax_amount,
                kot_total_amount,
                rooms_occupancy,
                room_name,
                total_rooms,
                sold_rooms,
              }) => {
                return (
                  <CustomReportTableRow
                    id={id}
                    key={id}
                    // selectedItems={selectedArray} // send for checking the check box is checked
                    // setSelectedItems={setSelectedArray}
                    showCheckbox={false} // show the check box
                  >
                    <CustomReportTableCell>{kot_id}</CustomReportTableCell>

                    <CustomReportTableCell>{kot_date}</CustomReportTableCell>
                    <CustomReportTableCell>
                      {formatIndianCurrency(kot_total_amount)}
                    </CustomReportTableCell>
                    <CustomReportTableCell>
                      {formatIndianCurrency(kot_tax_amount)}
                    </CustomReportTableCell>
                    <CustomReportTableCell>
                      {formatIndianCurrency(kot_grand_total)}
                    </CustomReportTableCell>
                    <CustomReportTableCell>
                      {kot_reason ? kot_reason : "N/A"}
                    </CustomReportTableCell>
                  </CustomReportTableRow>
                );
              }
            )}

            {calculateTotals}
          </CustomReportTable>
        ) : (
          <NoDataFound />
        )}
      </div>

      {informationPopup && (
        <InformationCustomPopup
          setInformationPopup={setInformationPopup}
          title="Information of Complimentary KOT Report"
        >
          <div className="mb-20">
            <AccordionComponent
              questions={[
                {
                  question: "What is the Complimentary KOT Report?",
                  answer: (
                    <p>
                      A <b>Complimentary KOT (Kitchen Order Ticket)</b> Report
                      details the items that have been served complimentary in a
                      restaurant or hospitality setting. It includes essential
                      information such as the KOT ID, date of service, subtotal,
                      tax amount, and grand total for each complimentary order.
                      The report also specifies the reason for providing the
                      complimentary service when applicable.
                    </p>
                  ),
                },
                {
                  question:
                    "What is the Importance of the Complimentary KOT Report?",
                  answer: (
                    <div>
                      <ul>
                        <li>
                          <b>Expense Tracking:</b> It allows businesses to
                          monitor the cost of complimentary services, ensuring
                          that the financial impact is well understood and
                          managed.
                        </li>
                        <li>
                          <b>Operational Efficiency:</b> Understanding the
                          frequency and reasons for cancellations can help
                          streamline operations, minimizing waste and optimizing
                          staff performance.
                        </li>
                        <li>
                          <b>Customer Relationship Management:</b> By analyzing
                          complimentary services, businesses can identify trends
                          in customer satisfaction and loyalty, tailoring
                          services to enhance guest experiences.
                        </li>
                        <li>
                          <b>Operational Insights:</b> This report helps
                          management evaluate when and why complimentary
                          services are provided, aiding in decision-making for
                          promotions and special occasions.
                        </li>
                        <li>
                          <b>Budgeting and Financial Planning:</b> Understanding
                          the total value of complimentary services can assist
                          in budgeting and forecasting future revenue and
                          expenses.
                        </li>
                      </ul>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </InformationCustomPopup>
      )}
    </>
  );
};
export default ReportComplimentaryKot;
