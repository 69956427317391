// import React from 'react'

// const FAndBReports = () => {
//   return (
//     <div>A</div>
//   )
// }

// export default FAndBReports
// Polaris Imports
import {
  Button,
  Card,
  ChoiceList,
  HorizontalStack,
  Icon,
  IndexTable,
  Pagination,
  Tooltip,
  useIndexResourceState,
  Text,
} from "@shopify/polaris";
import BarCharts from "../BarCharts";
import { PolarisVizProvider } from "@shopify/polaris-viz";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  CheckWithoutBgUnderlineBtn,
  WithoutBgButtonBlue,
} from "../UI/Buttons";

import DatePicker from "react-datepicker";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import CustomProgressBar from "../CustomComponents/CustomProgressBar";
import CustomToggleButton from "../UI/CustomToggleButton";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { Page, LegacyCard, DataTable } from "@shopify/polaris";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import VerticalInputFieldCustom from "../CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "../CustomComponents/VerticalInputSelectCustomCopy";
import { useSelector } from "react-redux";
import Loader from "../UI/Loader/Loader";
import { useNavigate } from "react-router-dom";
import CompetitorsAfter from "../CompetitorsAfter";
import SelectDateRangeWithoutInput from "../CustomComponents/SelectDateRangeWithoutInput/SelectDateRangeWithoutInput";
import ShowConfirmedArrivals from "../UI/ShowConfirmedArrivals";
import ShowHouseCheckIn from "../UI/ShowHouseCheckIn";
import ShowDepartureCheckOut from "../UI/ShowDepartureCheckOut";
import NoDataFound from "../CustomComponents/NoDataFound";
import NoDataFoundSmall from "../CustomComponents/NoDataFoundSmall";
import { current } from "@reduxjs/toolkit";
import LineChartDashboard from "../LineChartsDashboard";
import SelectDateRange from "../CustomComponents/SelectDateRange/SelectDateRange";
import StatusUpdate from "../CustomComponents/StatusUpdate";
import ReactDatePicker from "react-datepicker";
import SelectDatePickerCustom from "../CustomComponents/SelectDateRange/SelectDatePickerCustom";
import SidebarInnerLists from "../CustomComponents/SidebarInnerLists";
import {
  permission_inner_lists,
  rooms_inner_lists,
} from "../../assets/constant";
import DonutCustomChart from "../CustomComponents/Charts/DonutCustomChart";
import ReportPaymentReconciliation from "./ReportPaymentReconciliation";
import CustomRoomChoiceSelect from "../CustomComponents/CustomRoomChoiceSelect/CustomRoomChoiceSelect";
import ReportPaymentTaxReport from "./ReportPaymentTaxReport";
import ReportPaymentInvoice from "./ReportPaymentInvoice";
import ReportPaymentCommission from "./ReportPaymentCommission";
import ReportPaymentFoodSaleReport from "./ReportPaymentFoodSaleReport";
import ReportAgentSaleReport from "./ReportAgentSaleReport";
import ReportItemizedFoodSaleReport from "./ReportItemizedFoodSaleReport";
import VerticalInputSelectCustomPermissions from "../CustomComponents/VerticalInputSelectCustomPermissions";
import ReportCityLedgerReport from "./ReportCityLedgerReport";
import ReportCashierFinance from "./ReportCashierFinance";
import ReportMonthlySalesReport from "./ReportMonthlySalesReport";
import ReportSaleSummeryReport from "./ReportSaleSummeryReport";
import ReportOutlet from "./ReportOutlet";
import { SelectBasic } from "../NewCustomComp/SelectMultiSelect/SelectMultiSelect";
import ReportAccountBalanceTable from "./ReportAccountBalanceTable";
import ReportRoomAssignment from "./ReportRoomAssignment";
import ReportPaymentDailyRevenue from "./ReportPaymentDailyRevenue";
import ReportAgentBusinessReport from "./ReportAgentBusinessReport";
import ReportGstComplianceReport from "./ReportGstComplianceReport";
import ReportComplimentaryKot from "../CustomComponents/ReportComplimentaryKot";
import LoaderSmall from "../UI/Loader/LoaderSmall";
import ReportCancelledKot from "../CustomComponents/ReportCancelledKot";
import { getDecryptedData } from "../../utils/encryptStorage";
import DateRangePicker from "../NewCustomComp/DateRangePicker/DateRangePicker";
import SidebarInnerListWithMultiplePermissions from "../CustomComponents/SidebarInnerListWithMultiplePermissions";

Chart.register(ArcElement);

const FAndBReports = () => {
  const currentDate = new Date().toISOString().split("T")[0];
  const [fetchedData, setFetchedData] = useState(null);
  const [openDayStatus, setOpenDayStatus] = useState();
  const [checkInDate, setCheckInDate] = useState();
  const [checkOutDate, setCheckOutDate] = useState();
  const [showDashBoardCheckIn, setShowDashBoardCheckIn] = useState(false);
  const [showDashBoardCheckOut, setShowDashBoardCheckOut] = useState(false);
  const [showDashboardAllCheckIn, setShowDashboardAllCheckIn] = useState(false);
  const [showManualSearchInput, setShowManualSearchInput] = useState(false);
  const [showWebInput, setShowWebInput] = useState(false);
  const [showBookingDetails, setShowBookingDetails] = useState(false);

  const [showCheckOutBookingDetails, setShowCheckOutBookingDetails] =
    useState(false);
  const [
    showCheckOutIdClickBookingDetails,
    setShowCheckOutIdClickBookingDetails,
  ] = useState(false);

  const [settlementDoneCheckOut, setSettlementDoneCheckOut] = useState(false);

  const [showCheckOutSettlementDetails, setShowCheckOutSettlementDetails] =
    useState(false);
  const [openBookingDetails, setOpenBookingDetails] = useState(true);
  const userToken = getDecryptedData('encryptToken');

  const [bookingDetails, setBookingDetails] = useState([]);
  const [allSettlementsData, setAllSettlementsData] = useState([]);

  // show click arrow buttons
  const [showArrivalConfirmed, setShowArrivalConfirmed] = useState(false);
  const [showAllSettlement, setShowAllSettlement] = useState(false);
  const [showInHouseCheckIn, setShowInHouseCheckIn] = useState(false);
  const [showDepartureCheckOut, setShowDepartureCheckOut] = useState(false);

  const [checkOutBookingDetail, setCheckOutBookingDetail] = useState([]);

  const property_id = useSelector((state) => state.property.value);

  const [dayTypeFilter, setDayTypeFilter] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [valueArray, setValueArray] = useState([]);
  const accordRef = useRef(null);

  const toggleAccord = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false);
    setIsOpen2(false);
  };
  const toggleAccord1 = () => {
    setIsOpen(false);
    setIsOpen1(!isOpen1);
    setIsOpen2(false);
  };
  const toggleAccord2 = () => {
    setIsOpen2(!isOpen2);
    // setIsOpen1(false);
    // setIsOpen(false);
  };
  const toggleAccord3 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen1(false);
    setIsOpen(false);
  };

  const permissions = JSON.parse(localStorage.getItem("permissions"));

  // Reservations
  const dateOptions = [
    { id: 0, value: "-1", label: "Today" },
    { id: 0, value: "-2", label: "Yesterday" },
    { id: 1, value: "-7", label: "Past 7 Days" },
    { id: 2, value: "-30", label: "Past 30 Days" },
    { id: 3, value: "-90", label: "Past 90 Days" },
    { id: 4, value: "-1", label: "Last Year" },
    { id: 5, value: "-3", label: "Last 3 year" },
  ];
  // Reservations
  const dateOptions1 = [
    { id: 0, value: "revenue", label: "Revenue" },
    { id: 1, value: "profit", label: "Profit" },
    { id: 2, value: "reservation", label: "Reservation" },
    { id: 3, value: "expense", label: "Expense" },
    { id: 4, value: "event", label: "Event" },
    { id: 5, value: "f&b", label: "F&B" },
  ];

  const [allRoomTypes, setAllRoomTypes] = useState([]);
  const fetchAllRoomTypes = async (propertyId) => {
    try {
      setLoader(true);

      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAllRoomTypes(data.rooms);
      setSelectedRoomType([data.rooms[0].id]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const choiceListRoomsArray = allRoomTypes.map((room) => {
    return { label: room.room_name, value: room.id };
  });

  const handleAccordClick = (id, label, value) => {
    setValueArray([{ id: id, label: label, value: value }]);
    setDayTypeFilter(value);
  };

  const handleShowDashBoardCheckIn = (e) => {
    e.stopPropagation();
    setShowDashBoardCheckIn(!showDashBoardCheckIn);
  };

  const handleShowDashBoardCheckOut = (e) => {
    e.stopPropagation();
    setBookingId("");
    setShowDashBoardCheckOut(!showDashBoardCheckOut);
  };

  const handleShowManualSearchInput = () => {
    {
      showWebInput === true && setShowWebInput(false);
    }
    setShowManualSearchInput(!showManualSearchInput);
  };

  const handleShowWebInput = () => {
    {
      showManualSearchInput === true && setShowManualSearchInput(false);
    }
    setShowWebInput(!showWebInput);
  };

  const [bookingOpenIndex, setOpenBookingIndex] = useState(1);
  const [bookingAccordianActive, setBookingAccordianActive] = useState("");

  const handleOpenBookigDetails = (idx) => {
    setOpenBookingIndex(idx);
    if (bookingOpenIndex === idx) {
      setBookingAccordianActive("active");
    } else {
      setBookingAccordianActive("");
    }
  };

  const handleShowDashBoardAllCheckIn = () => {
    setShowDashboardAllCheckIn(!showDashboardAllCheckIn);
  };

  const [loader, setLoader] = useState(false);

  const data = [
    {
      name: "Values in Thousand",
      color: "#7C9CF3",
      barMargin: "Large",
      data: [
        { value: 1000, key: "Jan" },
        { value: 7000, key: "Feb" },
        { value: 10000, key: "Mar" },
        { value: 2000, key: "Apr" },
        { value: 2000, key: "May" },
        { value: 2000, key: "Jun" },
        { value: 2000, key: "Jul" },
        { value: 2000, key: "Aug" },
        { value: 2000, key: "Sep" },
        { value: 2000, key: "Oct" },
        { value: 2000, key: "Nov" },
        { value: 2000, key: "Dec" },
      ],
    },
  ];

  const navigate = useNavigate();

  const [selectedDiscountMethod, setSelectedDiscountMethod] =
    useState("automatic");

  const handleDiscountMethodChange = (value) => {
    setSelectedDiscountMethod(value);
  };

  const [selectedRoomType, setSelectedRoomType] = useState([1]);
  const [selectedBalanceType, setSelectedBalanceType] = useState([]);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState([]);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([]);

  const handleRoomTypeStatus = useCallback(
    (value) => setSelectedRoomType(value),
    []
  );
  const handleBalanceTypeStatus = useCallback(
    (value) => setSelectedBalanceType(value),
    []
  );
  const handleBookingStatus = useCallback(
    (value) => setSelectedBookingStatus(value),
    []
  );
  const handlePaymentStatus = useCallback(
    (value) => setSelectedPaymentStatus(value),
    []
  );

  const fetchDashboardInfo = async (propertyId, dayTypeFilter) => {
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/dashboard/${property_id?.id}?dayTypeFilter=${dayTypeFilter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (!response.ok) {
        setLoader(false);
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (data.message === "Unauthenticated.") {
        navigate("/login");
      }
      setLoader(false);
      setFetchedData(data);
    } catch (error) {
      if (error === "Unauthenticated.") {
        navigate("/login");
      }
      setLoader(false);
      console.error(error);
    }
  };

  // const currentDate = new Date().toISOString().split("T")[0];

  const calculateToDate = (fromDate) => {
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 5);
    return toDate.toISOString().split("T")[0];
  };

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: currentDate,
    to_date: calculateToDate(currentDate),
  });
  const [comparisonStartDateRange, setComparisonStartDateRange] = useState({
    from_date: currentDate,
    to_date: calculateToDate(currentDate),
  });
  const [comparisonEndDateRange, setComparisonEndDateRange] = useState({
    from_date: currentDate,
    to_date: calculateToDate(currentDate),
  });

  const sendingData = {
    comparisonFrom: comparisonStartDateRange,
    comparisonTo: comparisonEndDateRange,
    roomType: selectedRoomType,
    source: selectedBookingStatus,
  };

  const dataFromChild = (from_date, to_date) => {
    setSelectedDatesRange({
      from_date: from_date,
      to_date: to_date,
    });
  };
  const dataFromChild1 = (from_date, to_date) => {
    setComparisonStartDateRange({
      from_date: from_date,
      to_date: to_date,
    });
  };
  const dataFromChild2 = (from_date, to_date) => {
    setComparisonEndDateRange({
      from_date: from_date,
      to_date: to_date,
    });
  };

  useEffect(() => {
    if (property_id) {
      // fetchDashboardInfo(property_id.id, dayTypeFilter);
      fetchAllRoomTypes(property_id.id);
    }
  }, [property_id]);

  const openDayStatusHandler = useCallback((data) => {
    setOpenDayStatus(data);
  }, []);

  const chart_data = {
    datasets: [
      {
        data: [fetchedData?.total_revenue, fetchedData?.foodSale, 0],
        backgroundColor: ["#3968ED", "#15AA12", "#CCCCCC"],
        display: true,
      },
    ],
  };

  const [bookingId, setBookingId] = useState("");

  const [openAccordion, setOpenAccordion] = useState("first");

  // UseState for forms
  const [settlementAddData, setSettlementAddData] = useState({
    settlement_name: "",
    payment_mode: "",
    notes: "",
    date: "",
    amount: "",
    property_id: property_id?.id,
    other_payment_mode: "",
  });

  const paymentModeOptions = [
    { label: "Cash", value: "cash" },
    { label: "Bank Payment", value: "bank_payment" },
    { label: "UPI", value: "upi" },
    { label: "Credit Card", value: "credit_card" },
    { label: "Debit Card", value: "debit_card" },
    { label: "Other", value: "other" },
  ];

  const reportFilterOptions = [
    { label: "Select Option" },
    {
      label: "Cashier Report",
      value: "cashier_report",
      permission: ["full_access_analytics", "read_f_and_b_report"],
    },
    // {
    //   label: "Property Occupancy Report",
    //   value: "daily_finance_report",
    //   permission: "daily_finance_report",
    // },
    // {
    //   label: "Agent Business Report",
    //   value: "agent_business_report",
    //   permission: "daily_activity_account_balance",
    // },
    // {
    //   label: "GST Compliance Report",
    //   value: "gst_compliance_report",
    //   permission: "daily_activity_account_balance",
    // },
    // {
    //   label: "Account Balance",
    //   value: "account_balance",
    //   permission: "daily_activity_account_balance",
    // },
    // {
    //   label: "Room Assignment",
    //   value: "room_assignment",
    //   permission: "daily_activity_room_assignment",
    // },
    // {
    //   label: "Payment Reconciliation",
    //   value: "payment_reconciliation",
    //   permission: "daily_activity_payment_reconciliation",
    // },
    // {
    //   label: "Daily Revenue Report",
    //   value: "revenue_report",
    //   permission: "daily_activity_revenue_report",
    // },
    // {
    //   label: "Payment Ledger Report",
    //   value: "payment_ledger_report",
    //   permission: "finance_payment_ledger_report",
    // },
    // {
    //   label: "Invoice Report",
    //   value: "invoice_report",
    //   permission: "finance_invoice_report",
    // },
    // {
    //   label: "Tax Report",
    //   value: "tax_report",
    //   permission: "finance_tax_report",
    // },
    // {
    //   label: "Payout / Commission Report",
    //   value: "commission_report",
    //   permission: "finance_commission_report",
    // },
    {
      label: "Food Sale Report",
      value: "food_sale_report",
      permission: ["full_access_analytics", "read_f_and_b_report"],
    },
    // {
    //   label: "Agent Sale Report",
    //   value: "agent_sale_report",
    //   permission: "finance_agent_sale_report",
    // },
    {
      label: "Itemized Sale Report",
      value: "itemized_sale_report",
      permission: ["full_access_analytics", "read_f_and_b_report"],
    },
    {
      label: "Complimentary KOT's",
      value: "complimentary_kot",
      permission: ["full_access_analytics", "read_f_and_b_report"],
    },
    {
      label: "Cancelled KOT's",
      value: "cancelled_kot",
      permission: ["full_access_analytics", "read_f_and_b_report"],
    },
    // {
    //   label: "City Ledger Report",
    //   value: "city_ledger_report",
    //   permission: "finance_city_ledger_report",
    // },
   
    // {
    //   label: "Monthly Sales Report",
    //   value: "month_wise_sale_report",
    //   permission: "finance_wise_sale_report",
    // },
    // {
    //   label: "Sale Summery Report",
    //   value: "sale_summery_report",
    //   permission: "finance_wise_sale_report",
    // },
    {
      label: "Revenue Contribution Report",
      value: "outlet_report",
      permission: ["full_access_analytics", "read_f_and_b_report"],
    },  
  ];

  const invoiceBookingFilters = [
    { label: "Select Option", value: "" },
    { label: "Booking Date", value: "booking" },
    { label: "Check In Date", value: "check_in_out" },
  ];
  const invoiceSettlementFilters = [
    { label: "Select Option", value: "" },
    { label: "Settlement Date", value: "booking" },
    { label: "Check In Date", value: "check_in_out" },
  ];

  const renderYearContent = (year) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  useEffect(() => {
    document.title = "RevCatalyst - Boosting Your Hotel Performance";

    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);

  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  const [fullData, setFullData] = useState([]);
  const [orders, setOrders] = useState([]);

  const [settlementOrders, setSettlementOrders] = useState([]);

  const [filterName, setFilterName] = useState("");
  const [bookingNewFilter, setBookingNewFilter] = useState("");
  const [dailyFinanceDate, setDailyFinanceDate] = useState(currentDate);

  const polarisData = [
    {
      data: fullData.comparison_from || [],
      name: "2022",
      color: "lightseagreen",
    },
    {
      data: fullData.comparison_to || [],
      name: "2023",
      color: "lightgreen",
    },
  ];

  const [donutChart, setDonutChart] = useState([]);

  useEffect(() => {
    if (fullData?.data && fullData?.data.length > 0) {
      setDonutChart(fullData?.data);
    } else {
    }
  }, [fullData]);

  const donutChartData = [
    {
      name: "Shopify Payments",
      data: [
        {
          key: "april - march",
          value: 50000,
        },
      ],
    },
    {
      name: "Paypal",
      data: [
        {
          key: "april - march",
          value: 25000,
        },
      ],
    },
  ];

  const generateYearOptions = (currentYear) => {
    const numYears = 3;
    const startYear = currentYear - numYears;
    const endYear = currentYear;

    const years = [];
    years.push({ label: "Select a year", value: "" });
    for (let year = startYear; year <= endYear; year++) {
      years.push({ label: year.toString(), value: year });
    }

    return years;
  };

  // Example usage:
  const currentYear = new Date().getFullYear();
  const yearOptions = generateYearOptions(currentYear);

  const [urlFilter, setUrlFilter] = useState("occupancy");

  const handleUrlInnerFilter = useCallback(
    (e) => {
      if (e === "payment_ledger_report") {
        setUrlFilter("payment");
      } else {
        setUrlFilter("occupancy");
      }
    },
    [urlFilter]
  );

  const fetchInfo = async (propertyId) => {
    if (!selectedDatesRange.from_date || !selectedDatesRange.to_date) {
      showErrorToast("Please select all the filters");
      return;
    }
    try {
      const response = await fetch(
        // https://dev.revcatalyst.in/api/v1/get/food-sale-overview/4?from_date=2024-02-01&to_date=2024-04-30
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/food-sale-overview/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&viewType=List`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const [paymentReconciliationError, setPaymentReconciliationError] = useState({
    paymentOption: false,
  });

  const fetchPaymentReconciliation = async (propertyId, roomIds) => {
    try {
      if (paymentOptions.length <= 0) {
        showErrorToast("Please select payment options.");
        setErrorWithTimeout(
          setPaymentReconciliationError,
          "paymentOption",
          3000
        );
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/payment-reconciliation/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&payment_options=${paymentOptions}&booking_filter=${bookingNewFilter}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   // payment_options: paymentOptions,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setSettlementOrders(data.foodData);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const [paymentError, setPaymentError] = useState({ paymentMode: false });
  const fetchPaymentReconciliationNew = async (propertyId) => {
    try {
      // setChartData([]);
      setLoader(true);
      if (paymentOptions.length <= 0) {
        showErrorToast("Please select payment options.");
        setErrorWithTimeout(setPaymentError, "paymentMode", 3000);
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/payment-reconciliation-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            payment_type: paymentOptions,
          }),
        }
      );

      const data = await response.json();

      if (data.success) {
        const filterData = data.data.filter(
          (item) => item.payment_mode === "Null"
        );
        
        setOrders(data.data);
        setSettlementOrders(data.foodData);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchPaymentRevenueFetch = async (propertyId, roomIds) => {
    try {
      // setChartData([]);
      setLoader(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/revenue-report/${propertyId}?from_date=${selectedDatesRange.from_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);

        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchAgentBusinessReport = async (propertyId, roomIds) => {
    try {
      // setChartData([]);
      setLoader(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/agent-bussiness-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);

        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchGstComplianceReport = async (propertyId, roomIds) => {
    try {
      // setChartData([]);
      setLoader(true);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/gst-compliance/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setOrders(data.data.data);
        setFullData(data.data);
        showSuccessToast(data.message);
        setLoader(false);
      } else {
        setLoader(false);

        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchAccountBalanceHandler = async (propertyId, roomIds) => {
    // if (!selectedRoomType || selectedRoomType.length === 0) {
    //   showErrorToast("Please select the Room Type first!");
    //   return;
    // }

    if (
      filterName === "departure" &&
      (!selectedBookingStatus || selectedBookingStatus.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "in_house" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "payment_reconciliation" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Payment Option!");
      return;
    }

    setLoader(true);

    try {
      // setChartData([]);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/account-balance-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}&event_status=${selectedBookingStatus}&balance=${selectedBalanceType}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchRoomAssignmentHandler = async (propertyId, roomIds) => {
    // if (!selectedRoomType || selectedRoomType.length === 0) {
    //   showErrorToast('Please select the Room Type first!');
    //   return;
    // }

    if (
      filterName === "departure" &&
      (!selectedBookingStatus || selectedBookingStatus.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "in_house" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Status!");
      return;
    }

    if (
      filterName === "payment_reconciliation" &&
      (!selectedBalanceType || selectedBalanceType.length === 0)
    ) {
      showErrorToast("Please select the Payment Option!");
      return;
    }

    try {
      // setChartData([]);
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/room-assignment-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&room_types=${selectedRoomType}&event_status=${selectedBookingStatus}&balance=${selectedBalanceType}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   room_types: selectedRoomType,
          //   event_status: selectedBookingStatus,
          //   balance: selectedBalanceType,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchPaymentTaxReport = async (propertyId, roomIds) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/tax-report/${propertyId}?from_date=${selectedDatesRange.from_date}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   // payment_options: paymentOptions,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        // setOrders(data.data);
        setSettlementOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const fetchPaymentInvoiceReport = async (propertyId, roomIds) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/invoice-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&booking_source=${selectedBookingStatus}&booking_filter=${bookingNewFilter}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   // payment_options: paymentOptions,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchPaymentCommissionReport = async (propertyId, roomIds) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/commission-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&booking_filter=${bookingNewFilter}`,
        {
          method: "GET",
          // body: JSON.stringify({
          //   from_date: selectedDatesRange.from_date,
          //   to_date: selectedDatesRange.to_date,
          //   // payment_options: paymentOptions,
          // }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchPaymentFoodSalesReport = async (propertyId, roomIds) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/food-sale-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const [cityLedgerError, setCityLedgerError] = useState({
    agent: false,
  });

  const fetchCityLedgerReport = async (propertyId, roomIds) => {
    if (
      !bookingNewFilter ||
      !selectedDatesRange.from_date ||
      !selectedDatesRange.to_date
    ) {
      showErrorToast("Please select all the filters");
      setErrorWithTimeout(setCityLedgerError, "agent", 3000);
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/city/ledger/report/${bookingNewFilter}/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&viewType=List`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setOrders(data.creditTransaction);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchSummarySaleReport = async (propertyId, roomIds) => {
    if (!selectedDatesRange.from_date || !selectedDatesRange.to_date) {
      showErrorToast("Please select all the filters");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/sale-summary-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setFullData(data.data);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchCashierReport = async (propertyId, roomIds) => {
    if (!selectedDatesRange.from_date || !selectedDatesRange.to_date) {
      showErrorToast("Please select all the filters");
      return;
    }
    try {
      const response = await fetch(
        // https://dev.revcatalyst.in/api/v1/get/food-sale-overview/4?from_date=2024-02-01&to_date=2024-04-30
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/food-sale-overview/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&viewType=List`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchMonthlySalesReport = async (propertyId, roomIds) => {
    if (!selectedDatesRange.from_date || !selectedDatesRange.to_date) {
      showErrorToast("Please select all the filters");
      return;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/sale-report/${propertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&viewType=List`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const FetchItemizedSaleReport = async (propertyId, roomIds) => {
    try {
      const response = await fetch(
        // https://dev.revcatalyst.in/api/v1/get/itemised-food-sale/2?from_date=2024-04-01&categories=BEVERAGES,BREADS
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/itemised-food-sale/${propertyId}?from_date=${selectedDatesRange.from_date}&categories=${paymentOptions}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchComplimentaryKot = async (PropertyId, roomIds) => {
    // if (selectedRoomType.length === 0) {
    //   showErrorToast("Please select room type");
    //   return;
    // }
    setLoader(true);

    try {
      const response = await fetch(
        // https://dev.revcatalyst.in/api/v1/get/complimentary-kot-report/4?from_date=2024-02-01&to_date=2024-04-30
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/complimentary-kot-report/${PropertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setFullData([]);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  const fetchCAncelledKot = async (PropertyId, roomIds) => {
    // if (selectedRoomType.length === 0) {
    //   showErrorToast("Please select room type");
    //   return;
    // }
    setLoader(true);

    try {
      const response = await fetch(
        // https://dev.revcatalyst.in/api/v1/get/complimentary-kot-report/4?from_date=2024-02-01&to_date=2024-04-30
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/cancelled-kot-report/${PropertyId}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data.data);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setFullData([]);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };
  // https://dev.revcatalyst.in/api/v1/get/agent-sales/2?from_date=2024-03-15
  const fetchPaymentAgentSalesReport = async (propertyId, roomIds) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/get/agent-sales/${propertyId}?from_date=${selectedDatesRange.from_date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const data = await response.json();

      if (data.success) {
        setOrders(data?.data?.revenue);
        setFullData(data);
        showSuccessToast(data.message);
      } else {
        setOrders(data.data);
        showErrorToast(data.message);
      }

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
    }
  };

  const [agentList, setAgentList] = useState([]);

  const fetchAgentList = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/agents/${property_id?.id}?pagination=false`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      setAgentList(data.data);

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchAgentList();
    }
  }, [property_id]);

  const agentOptions = [
    { label: "Select Option", value: "" },
    ...agentList.map((agent) => ({
      label: agent.agent_name,
      value: agent.agent_information,
    })),
  ];

  const [menuItems, setMenuItems] = useState([]);

  const menuItemArray = menuItems?.map((roomType) => {
    return { label: roomType.item_category, value: roomType.item_category };
  });

  const fetchMenuItems = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/menus/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      if (data.success) {
        setMenuItems(data.menu);
        // if (data?.menu?.length > 0) {
        //   setSelectedMenuItems(data?.menu[0].menu_items);
        //   setSelectedCategory(data?.menu[0]?.item_category);
        // }
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id?.id) {
      fetchInfo(property_id?.id);
      fetchMenuItems(property_id?.id);
    }
  }, [property_id?.id]);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  // const rowMarkup = orders?.map((order) => (
  //   <IndexTable.Row
  //     id={order.id}
  //     key={order.id}
  //     selected={selectedResources.includes(order.id)}
  //     position={order.index}
  //   >
  //     <IndexTable.Cell>{order.booking_id}</IndexTable.Cell>
  //     <IndexTable.Cell>{order.date}</IndexTable.Cell>
  //     <IndexTable.Cell>{order.notes}</IndexTable.Cell>
  //     <IndexTable.Cell>{order.payment_mode}</IndexTable.Cell>
  //     <IndexTable.Cell>{order.amount}</IndexTable.Cell>
  //   </IndexTable.Row>
  // ));

  const otherMenusRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      otherMenusRef.current &&
      !otherMenusRef.current.contains(event.target)
    ) {
      setIsOpen(false);
      setIsOpen1(false);
      setIsOpen2(false);
      setIsOpen3(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [paymentOptions, setPaymentOptions] = useState([]);

  const getDataFromCustomRoomChoiceSelectPaymentOptions = (data) => {
    setPaymentOptions(data);
  };

  const setErrorWithTimeout = (setterFunction, keyName, duration = 3000) => {
    setterFunction((prev) => ({ ...prev, [keyName]: true }));
    setTimeout(() => {
      setterFunction((prev) => ({ ...prev, [keyName]: false }));
    }, duration);
  };

  // Outlet Report
  const getOutletReport = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/xml");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const url = selectedStaff
      ? `https://dev.revcatalyst.in/api/v1/get/food-sale/order-by-report/${property_id?.id}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}&order_by=${selectedStaff}`
      : `https://dev.revcatalyst.in/api/v1/get/food-sale/order-by-report/${property_id?.id}?from_date=${selectedDatesRange.from_date}&to_date=${selectedDatesRange.to_date}`;
    try {
      const response = await fetch(url, requestOptions);
      const result = await response.json();

      if (result.success) {
        setOrders(result.data);
      } else {
        showErrorToast("No Data Found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // staff_list Api
  const [fetchedRoles, setFetchedRoles] = useState([]);
  const fetchRoles = async () => {
    // if (!property_id?.id) {
    //   navigate("/login");
    // };
    setLoader(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/account/get-user/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setFetchedRoles(data.data);
      // F and B filter
      // const fnbStaff = data.data.filter((user) =>
      //   user.roles.some((role) => role.name.includes("f_and_b"))
      // );
      // setFilteredStaff(fnbStaff);

      setLoader(false);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const [selectedStaff, setSelectedStaff] = useState([]);
  const handleStaffSelect = (data) => {
    setSelectedStaff(data);
  };

  useEffect(() => {
    fetchRoles();
  }, [property_id]);
  console.log(fetchedRoles, "roles");

  return (
    <>
      <SidebarInnerListWithMultiplePermissions innerLists={permission_inner_lists} zIndex={20} />
      <section className="dashboard_page">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "15px 0",
            }}
          >
            <div style={{ fontSize: "20px" }} className="report-chart-heading">
              F & B Reports
            </div>
          </div>
          <div>
            <div
              style={{
                background: "rgba(255, 255, 255, 1)",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "10px",
                  }}
                >
                  <div className="report-chart-heading">Filters</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{ width: "95%", flexWrap: "wrap" }}
                    className="flex flex_gap_10"
                  >
                    <div style={{ width: "150px" }}>
                      <VerticalInputSelectCustomPermissions
                        titleName={"Select Report"}
                        value={filterName}
                        defaultValue={"arrival"}
                        onChange={(e) => {
                          setFilterName(e.target.value);
                          handleUrlInnerFilter(e.target.value);
                          setSelectedRoomType([]);
                          setBookingDetails([]);
                          setSelectedBookingStatus([]);
                          setOrders([]);
                        }}
                        options={reportFilterOptions}
                      />
                    </div>
                    {filterName === "arrival" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange dataFromChild={dataFromChild} defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]} />
                        </div>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Room Type</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord2}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedRoomType.length} of{" "}
                                {allRoomTypes.length} SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${isOpen ? "accordImageRotate" : null
                                  } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen2 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={choiceListRoomsArray}
                                  selected={selectedRoomType}
                                  onChange={handleRoomTypeStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 2 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${isOpen ? "accordImageRotate" : null
                                  } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "In House",
                                      value: "in-house",
                                    },
                                    {
                                      label: "Not Checked-in",
                                      value: "not-Checked-in",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {filterName === "departure" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange dataFromChild={dataFromChild} defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]} />
                        </div>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Room Type</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord2}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedRoomType.length} of{" "}
                                {allRoomTypes.length} SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${isOpen ? "accordImageRotate" : null
                                  } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen2 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={choiceListRoomsArray}
                                  selected={selectedRoomType}
                                  onChange={handleRoomTypeStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 2 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${isOpen ? "accordImageRotate" : null
                                  } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "In House",
                                      value: "in-house",
                                    },
                                    {
                                      label: "Checked Out",
                                      value: "checked-out",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {filterName === "in_house" && (
                      <>
                        <div style={{ width: "200px" }}>
                          {/* <div style={{ marginBottom: "5px" }}>
                                Date Range
                              </div> */}
                          <VerticalInputFieldCustom
                            titleName={"Check-In Date"}
                            type={"Date"}
                          />
                          {/* <SelectDateRange dataFromChild={dataFromChild} /> */}
                        </div>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Balance Due</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord2}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBalanceType.length} of {2} SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${isOpen ? "accordImageRotate" : null
                                  } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen2 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "Balance Due",
                                      value: "balance-due",
                                    },
                                    {
                                      label: "No Balance",
                                      value: "no-balance",
                                    },
                                  ]}
                                  selected={selectedBalanceType}
                                  onChange={handleBalanceTypeStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 2 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${isOpen ? "accordImageRotate" : null
                                  } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "In House",
                                      value: "in-house",
                                    },
                                    {
                                      label: "Checked Out",
                                      value: "checked-out",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {filterName === "daily_finance_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            type={"date"}
                            titleName={"Select Date"}
                            onChange={(e) =>
                              setDailyFinanceDate(e.target.value)
                            }
                            value={dailyFinanceDate}
                            name={"dailyFinanceDate"}
                          />
                        </div>

                        {/* <div style={{ width: "200px" }}>
                              <div style={{ marginBottom: "5px" }}>
                                Comparison End Range
                              </div>
                              <SelectDateRange dataFromChild={dataFromChild2} />
                            </div> */}

                        {/* <div style={{ width: "200px" }}>
                              <div style={{ marginBottom: "5px" }}>
                                Room Type
                              </div>
                              <div
                                ref={accordRef}
                                style={{
                                  position: "relative",
                                }}
                              >
                                <div
                                  onClick={toggleAccord2}
                                  style={{
                                    display: "flex",
                                    padding: "10px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    position: "relative",
                                    cursor: "pointer",
                                    border: "0.5px solid #CCC",
                                    gap: "10px",
                                    borderRadius: "5px",
                                    fontSize: "14px",
                                    height: "35px",
                                  }}
                                >
                                  <div
                                    style={{ borderRadius: "5px" }}
                                    className=""
                                  >
                                    {selectedRoomType.length} of{" "}
                                    {allRoomTypes.length} SELECTED
                                  </div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="6"
                                    viewBox="0 0 10 6"
                                    fill="none"
                                    className={`${
                                      isOpen ? "accordImageRotate" : null
                                    } `}
                                  >
                                    <path d="M1 1L5 5L9 1" stroke="#333333" />
                                  </svg>
                                </div>
                                {isOpen2 && (
                                  <div
                                    ref={otherMenusRef}
                                    style={{
                                      position: "absolute",
                                      border: "1px #3333332d solid",
                                      left: "0",
                                      top: "45px",
                                      width: "100%",
                                      height: "fit-content",
                                      zIndex: 999,
                                    }}
                                    className="accord_container"
                                  >
                                    <ChoiceList
                                      choices={choiceListRoomsArray}
                                      selected={selectedRoomType}
                                      onChange={handleRoomTypeStatus}
                                      allowMultiple
                                    />
                                  </div>
                                )}
                              </div>
                            </div> */}

                        {/* <div style={{ width: "200px" }}>
                              <div style={{ marginBottom: "5px" }}>Source</div>
                              <div
                                ref={accordRef}
                                style={{
                                  position: "relative",
                                }}
                              >
                                <div
                                  onClick={toggleAccord3}
                                  style={{
                                    display: "flex",
                                    padding: "10px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    position: "relative",
                                    cursor: "pointer",
                                    border: "0.5px solid #CCC",
                                    gap: "10px",
                                    borderRadius: "5px",
                                    fontSize: "14px",
                                    height: "35px",
                                  }}
                                >
                                  <div
                                    style={{ borderRadius: "5px" }}
                                    className=""
                                  >
                                    {selectedBookingStatus.length} of 4 SELECTED
                                  </div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="6"
                                    viewBox="0 0 10 6"
                                    fill="none"
                                    className={`${
                                      isOpen ? "accordImageRotate" : null
                                    } `}
                                  >
                                    <path d="M1 1L5 5L9 1" stroke="#333333" />
                                  </svg>
                                </div>
                                {isOpen3 && (
                                  <div
                                    ref={otherMenusRef}
                                    style={{
                                      position: "absolute",
                                      border: "1px #3333332d solid",
                                      left: "0",
                                      top: "45px",
                                      width: "100%",
                                      height: "fit-content",
                                      zIndex: 999,
                                    }}
                                    className="accord_container"
                                  >
                                    <ChoiceList
                                      choices={[
                                        {
                                          label: "Walk In Booking",
                                          value: "walk_in_booking",
                                        },
                                        {
                                          label: "Website Booking",
                                          value: "website_booking",
                                        },
                                        {
                                          label: "Channel Booking",
                                          value: "channel_booking",
                                        },
                                        {
                                          label: "Agent Booking",
                                          value: "agent_booking",
                                        },
                                      ]}
                                      selected={selectedBookingStatus}
                                      onChange={handleBookingStatus}
                                      allowMultiple
                                    />
                                  </div>
                                )}
                              </div>
                            </div> */}
                      </>
                    )}
                    {filterName === "account_balance" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                            defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                          />
                        </div>

                        {/* <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 2 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${isOpen ? "accordImageRotate" : null
                                  } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "In House",
                                      value: "in-house",
                                    },
                                    {
                                      label: "Checked Out",
                                      value: "checked-out",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div> */}
                      </>
                    )}
                    {filterName === "agent_business_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                            defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                          />
                        </div>

                        {/* <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 2 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${isOpen ? "accordImageRotate" : null
                                  } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "In House",
                                      value: "in-house",
                                    },
                                    {
                                      label: "Checked Out",
                                      value: "checked-out",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div> */}
                      </>
                    )}
                    {filterName === "gst_compliance_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                            defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                          />
                        </div>

                        {/* <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Status</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 2 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${isOpen ? "accordImageRotate" : null
                                  } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "In House",
                                      value: "in-house",
                                    },
                                    {
                                      label: "Checked Out",
                                      value: "checked-out",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div> */}
                      </>
                    )}
                    {filterName === "room_assignment" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            maxDate={new Date(currentDate)}
                            dataFromChild={dataFromChild}
                            defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "payment_reconciliation" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                          // minDate={new Date(currentDate)}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <CustomRoomChoiceSelect
                            roomChoice={paymentModeOptions}
                            getDataFromCustomRoomChoiceSelect={
                              getDataFromCustomRoomChoiceSelectPaymentOptions
                            }
                            selectedRoomsIds={paymentOptions}
                            heading="Payment Options"
                            showButtons={true}
                            error={paymentError.paymentMode}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "invoice_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                          // minDate={new Date(currentDate)}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <VerticalInputSelectCustomCopy
                            titleName={"Booking Filter"}
                            value={bookingNewFilter}
                            defaultValue={"arrival"}
                            onChange={(e) => {
                              setBookingNewFilter(e.target.value);
                            }}
                            options={invoiceBookingFilters}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Source</div>
                          <div
                            ref={accordRef}
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              onClick={toggleAccord3}
                              style={{
                                display: "flex",
                                padding: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #CCC",
                                gap: "10px",
                                borderRadius: "5px",
                                fontSize: "14px",
                                height: "35px",
                              }}
                            >
                              <div style={{ borderRadius: "5px" }} className="">
                                {selectedBookingStatus.length} of 4 SELECTED
                              </div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                className={`${isOpen ? "accordImageRotate" : null
                                  } `}
                              >
                                <path d="M1 1L5 5L9 1" stroke="#333333" />
                              </svg>
                            </div>
                            {isOpen3 && (
                              <div
                                ref={otherMenusRef}
                                style={{
                                  position: "absolute",
                                  border: "1px #3333332d solid",
                                  left: "0",
                                  top: "45px",
                                  width: "100%",
                                  height: "fit-content",
                                  zIndex: 999,
                                }}
                                className="accord_container"
                              >
                                <ChoiceList
                                  choices={[
                                    {
                                      label: "Agent (B2B Partner)",
                                      value: "agent",
                                    },
                                    {
                                      label: "Walk In Bookings",
                                      value: "walk_in_bookings",
                                    },
                                    {
                                      label: "Front Desk",
                                      value: "front_desk",
                                    },
                                    {
                                      label: "Sales and Marketing",
                                      value: "sales_and_marketing",
                                    },
                                  ]}
                                  selected={selectedBookingStatus}
                                  onChange={handleBookingStatus}
                                  allowMultiple
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {filterName === "payment_ledger_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                          // minDate={new Date(currentDate)}
                          />
                        </div>
                        <div style={{ width: "200px" }}>
                          <VerticalInputSelectCustomCopy
                            titleName={"Settlement Filter"}
                            value={bookingNewFilter}
                            defaultValue={"arrival"}
                            onChange={(e) => {
                              setBookingNewFilter(e.target.value);
                            }}
                            options={invoiceSettlementFilters}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <CustomRoomChoiceSelect
                            roomChoice={paymentModeOptions}
                            getDataFromCustomRoomChoiceSelect={
                              getDataFromCustomRoomChoiceSelectPaymentOptions
                            }
                            selectedRoomsIds={paymentOptions}
                            heading="Payment Options"
                            showButtons={true}
                            error={paymentReconciliationError.paymentOption}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "tax_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            // disabled={true}
                            titleName={"Date"}
                            type={"Date"}
                            value={selectedDatesRange.from_date}
                            onChange={(e) => {
                              setSelectedDatesRange({
                                ...selectedDatesRange,
                                from_date: e.target.value,
                              });
                            }}
                          // max={currentDate}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "commission_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>
                            Date Booked Date Range
                          </div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                            maxDate={new Date(currentDate)}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <VerticalInputSelectCustomCopy
                            titleName={"Booking Filter"}
                            value={bookingNewFilter}
                            defaultValue={"arrival"}
                            onChange={(e) => {
                              setBookingNewFilter(e.target.value);
                            }}
                            options={invoiceBookingFilters}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "food_sale_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <DateRangePicker
                            dataFromChild={dataFromChild}
                            defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                          // minDate={new Date(currentDate)}
                          />
                        </div>

                        {/* <div style={{ width: "200px" }}>
                          <VerticalInputSelectCustomCopy
                            titleName={"Booking Filter"}
                            value={bookingNewFilter}
                            defaultValue={"arrival"}
                            onChange={(e) => {
                              setBookingNewFilter(e.target.value);
                            }}
                            options={invoiceBookingFilters}
                          />
                        </div> */}
                      </>
                    )}
                    {filterName === "city_ledger_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <VerticalInputSelectCustomCopy
                            titleName={"Select Agent"}
                            value={bookingNewFilter}
                            defaultValue={"arrival"}
                            onChange={(e) => {
                              setBookingNewFilter(e.target.value);
                            }}
                            options={agentOptions}
                            error={cityLedgerError.agent}
                          />
                        </div>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                          // minDate={new Date(currentDate)}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "sale_summery_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                          // minDate={new Date(currentDate)}
                          defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "cashier_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                          // minDate={new Date(currentDate)}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "month_wise_sale_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            maxDate={new Date()}
                            defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                          // minDate={new Date(currentDate)}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "agent_sale_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            // disabled={true}
                            titleName={"Date"}
                            type={"Date"}
                            value={selectedDatesRange.from_date}
                            onChange={(e) => {
                              setSelectedDatesRange({
                                ...selectedDatesRange,
                                from_date: e.target.value,
                              });
                            }}
                          // max={currentDate}
                          />
                        </div>

                        {/* <div style={{ width: "200px" }}>
                          <VerticalInputSelectCustomCopy
                            titleName={"Booking Filter"}
                            value={bookingNewFilter}
                            defaultValue={"arrival"}
                            onChange={(e) => {
                              setBookingNewFilter(e.target.value);
                            }}
                            options={invoiceBookingFilters}
                          />
                        </div> */}
                      </>
                    )}
                    {filterName === "itemized_sale_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            // disabled={true}
                            titleName={"Date"}
                            type={"Date"}
                            value={selectedDatesRange.from_date}
                            onChange={(e) => {
                              setSelectedDatesRange({
                                ...selectedDatesRange,
                                from_date: e.target.value,
                              });
                            }}
                            max={currentDate}
                          />
                        </div>

                        <div style={{ width: "200px" }}>
                          <CustomRoomChoiceSelect
                            roomChoice={menuItemArray}
                            getDataFromCustomRoomChoiceSelect={
                              getDataFromCustomRoomChoiceSelectPaymentOptions
                            }
                            selectedRoomsIds={paymentOptions}
                            heading="Categories"
                            showButtons={true}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "complimentary_kot" && (
                      <>
                        {/* closeOnScroll={(e) => e.target === document} */}
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>
                            Comparison Date Range
                          </div>
                          <SelectDateRange
                            minDate={false}
                            dataFromChild={dataFromChild}
                            maxDate={new Date()}
                            defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "cancelled_kot" && (
                      <>
                        {/* closeOnScroll={(e) => e.target === document} */}
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>
                            Comparison Date Range
                          </div>
                          <SelectDateRange
                            minDate={false}
                            dataFromChild={dataFromChild}
                            maxDate={new Date()}
                            defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                          />
                        </div>
                      </>
                    )}
                    {filterName === "outlet_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <div style={{ marginBottom: "5px" }}>Date Range</div>
                          <SelectDateRange
                            dataFromChild={dataFromChild}
                            defaultDate={[selectedDatesRange.from_date, selectedDatesRange.to_date]}
                          // minDate={new Date(currentDate)}
                          />
                        </div>
                        <div style={{ width: "200px" }}>
                          <>
                            <div style={{ marginBottom: "5px" }}>Staff</div>
                            <SelectBasic
                              emptyOption={"none"}
                              options={fetchedRoles}
                              handleSelectTagChange={handleStaffSelect}
                              optionKeyName={"name"}
                              showKeyName={"name"}
                              selectedKeyName={"name"}
                            />
                          </>
                        </div>
                      </>
                    )}
                    {filterName === "revenue_report" && (
                      <>
                        <div style={{ width: "200px" }}>
                          <VerticalInputFieldCustom
                            // disabled={true}
                            titleName={"Date"}
                            type={"Date"}
                            value={selectedDatesRange.from_date}
                            onChange={(e) => {
                              setSelectedDatesRange({
                                ...selectedDatesRange,
                                from_date: e.target.value,
                              });
                            }}
                            max={currentDate}
                          />
                        </div>

                        {/* <div style={{ width: "200px" }}>
                              <CustomRoomChoiceSelect
                                roomChoice={paymentModeOptions}
                                getDataFromCustomRoomChoiceSelect={
                                  getDataFromCustomRoomChoiceSelectPaymentOptions
                                }
                                selectedRoomsIds={paymentOptions}
                                heading="Payment Options"
                                showButtons={true}
                              />
                            </div> */}
                      </>
                    )}
                  </div>
                  {/* <div></div> */}
                  <div className="flex flex_gap_10">
                    {filterName != "payment_ledger_report" &&
                      filterName != "invoice_report" &&
                      filterName != "tax_report" &&
                      filterName != "account_balance" &&
                      filterName != "agent_business_report" &&
                      filterName != "gst_compliance_report" &&
                      filterName != "room_assignment" &&
                      filterName != "payment_reconciliation" &&
                      filterName != "revenue_report" &&
                      filterName != "commission_report" &&
                      filterName != "agent_sale_report" &&
                      filterName != "itemized_sale_report" &&
                      filterName != "complimentary_kot" &&
                      filterName != "cancelled_kot" &&
                      filterName != "sale_summery_report" &&
                      filterName != "city_ledger_report" &&
                      filterName != "cashier_report" &&
                      filterName != "month_wise_sale_report" &&
                      filterName != "food_sale_report" &&
                      filterName !== "outlet_report" && (
                        <BgThemeButton
                          children={"Apply"}
                          loader={loader}
                          onClick={() => fetchInfo(property_id.id)}
                        />
                      )}

                    {filterName === "payment_ledger_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchPaymentReconciliation(property_id.id)
                        }
                      />
                    )}
                    {filterName === "revenue_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchPaymentRevenueFetch(property_id.id)}
                      />
                    )}
                    {filterName === "account_balance" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchAccountBalanceHandler(property_id.id)
                        }
                      />
                    )}
                    {filterName === "agent_business_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchAgentBusinessReport(property_id.id)}
                      />
                    )}
                    {filterName === "gst_compliance_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchGstComplianceReport(property_id.id)}
                      />
                    )}
                    {filterName === "room_assignment" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchRoomAssignmentHandler(property_id.id)
                        }
                      />
                    )}
                    {filterName === "payment_reconciliation" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchPaymentReconciliationNew(property_id.id)
                        }
                      />
                    )}
                    {filterName === "tax_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchPaymentTaxReport(property_id.id)}
                      />
                    )}

                    {filterName === "invoice_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchPaymentInvoiceReport(property_id.id)
                        }
                      />
                    )}
                    {filterName === "commission_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchPaymentCommissionReport(property_id.id)
                        }
                      />
                    )}
                    {filterName === "food_sale_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchPaymentFoodSalesReport(property_id.id)
                        }
                      />
                    )}
                    {filterName === "city_ledger_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchCityLedgerReport(property_id.id)}
                      />
                    )}
                    {filterName === "sale_summery_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchSummarySaleReport(property_id.id)}
                      />
                    )}
                    {filterName === "cashier_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchCashierReport(property_id.id)}
                      />
                    )}
                    {filterName === "month_wise_sale_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchMonthlySalesReport(property_id.id)}
                      />
                    )}
                    {filterName === "itemized_sale_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => FetchItemizedSaleReport(property_id.id)}
                      />
                    )}
                    {filterName === "complimentary_kot" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchComplimentaryKot(property_id.id)
                        }
                      />
                    )}
                    {filterName === "cancelled_kot" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() => fetchCAncelledKot(property_id.id)}
                      />
                    )}
                    {filterName === "agent_sale_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        loader={loader}
                        onClick={() =>
                          fetchPaymentAgentSalesReport(property_id.id)
                        }
                      />
                    )}

                    {filterName === "outlet_report" && (
                      <BgThemeButton
                        children={"Apply"}
                        onClick={getOutletReport}
                      />
                    )}
                    <BgOutlineThemeButton
                      onClick={() => {
                        setFilterName("");
                        setSelectedRoomType([]);
                        setBookingDetails([]);
                        setSelectedBookingStatus([]);
                        setOrders([]);
                      }}
                      type={"button"}
                      children={"Clear"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {filterName === "rev_par" && (
          
          )} */}
          {filterName === "rev_par" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <LineChartDashboard
                  data={polarisData}
                  title={"Rev Par Insight"}
                />
              )}
            </>
          )}
          {filterName === "occupancy" && (
            <LineChartDashboard data={polarisData} title={"Occupancy"} />
          )}

          {filterName === "daily_finance_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <DonutCustomChart
                  data={donutChart || []}
                  title={"Property Occupancy Report"}
                  state={loader}
                />
              )}
            </>
          )}
          {filterName === "account_balance" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportAccountBalanceTable
                  departureReport={orders}
                  fullData={fullData}
                />
              )}
            </>
          )}
          {filterName === "agent_business_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportAgentBusinessReport departureReport={orders} />
              )}
            </>
          )}
          {filterName === "gst_compliance_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportGstComplianceReport
                  departureReport={orders}
                  fullData={fullData}
                />
              )}
            </>
          )}
          {filterName === "room_assignment" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportRoomAssignment departureReport={orders} />
              )}
            </>
          )}
          {filterName === "payment_reconciliation" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportPaymentReconciliation
                  departureReport={orders}
                  settlementOrders={settlementOrders}
                  loader={loader}
                />
              )}
            </>
          )}
          {filterName === "revenue_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportPaymentDailyRevenue
                  departureReport={orders}
                  loader={loader}
                />
              )}
            </>
          )}

          {/* <BarCharts
                loader={loader}
                data={data}
                leftTitle={"Reservation Insights"}
              /> */}

          {filterName === "payment_ledger_report" && (
            <>
              {loader ? (
                <Loader />
              ) : (
                <ReportPaymentReconciliation
                  departureReport={orders}
                  settlementOrders={settlementOrders}
                />
              )}
            </>
          )}
          {filterName === "invoice_report" && (
            <ReportPaymentInvoice
              departureReport={orders}
              settlementOrders={settlementOrders}
            />
          )}
          {filterName === "tax_report" && (
            <ReportPaymentTaxReport
              departureReport={settlementOrders}
              settlementOrders={settlementOrders}
            />
          )}
          {filterName === "commission_report" && (
            <ReportPaymentCommission departureReport={orders} />
          )}
          {filterName === "food_sale_report" && (
            <ReportPaymentFoodSaleReport
              departureReport={orders}
              fullData={fullData}
            />
          )}
          {filterName === "city_ledger_report" && (
            <ReportCityLedgerReport
              departureReport={orders}
              fullData={fullData}
            />
          )}
          {filterName === "sale_summery_report" && (
            <ReportSaleSummeryReport
              departureReport={orders}
              fullData={fullData}
            />
          )}
          {filterName === "cashier_report" && (
            <ReportCashierFinance
              departureReport={orders}
              fullData={fullData?.data}
            />
          )}
          {filterName === "month_wise_sale_report" && (
            <ReportMonthlySalesReport
              departureReport={orders}
              fullData={fullData?.data}
            />
          )}
          {filterName === "agent_sale_report" && (
            <ReportAgentSaleReport departureReport={orders} />
          )}
          {filterName === "itemized_sale_report" && (
            <ReportItemizedFoodSaleReport departureReport={orders} />
          )}
          {filterName === "complimentary_kot" && fullData && (
            <>
              {loader ? (
                <LoaderSmall />
              ) : (
                // <div>ppp</div>
                <ReportComplimentaryKot reportData={orders} />
              )}
            </>
          )}
          {filterName === "cancelled_kot" && fullData && (
            <>
              {loader ? (
                <LoaderSmall />
              ) : (
                // <div>ppp</div>
                <ReportCancelledKot reportData={orders} />
              )}
            </>
          )}
          {filterName === "outlet_report" && <ReportOutlet data={orders} />}
        </div>
      </section>
    </>
  );
};

export default FAndBReports;
