import React, { useEffect } from "react";
import NavTabs from "../NewCustomComp/NavTabs/NavTabs";
import { Outlet } from "react-router-dom";

const ReportParentPage = () => {
  useEffect(() => {
    document.title = "RevCatalyst - Boosting Your Hotel Performance";

    let metaDescription = document.querySelector('meta[name="description"]');
    if (!metaDescription) {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      document.head.appendChild(metaDescription);
    }
    metaDescription.content =
      "Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property.";
  }, []);

  const links = [
    { label: "Daily Activity Reports", to: "/reports/daily-activity" },
    { label: "Finance Report", to: "finance" },
    { label: "Production Report", to: "production" },
    
  ];

  return (
    <div className="main_container">
      <NavTabs mainData={links} />

      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default ReportParentPage;
