import {
    IndexTable,
    LegacyCard,
} from "@shopify/polaris";
import NoDataFound from "./NoDataFound";
import CustomCssComponentTableText from "./CustomCssComponentTableText";

const RegionReportComp = ({ regionReportsData }) => {
    
    const resourceName = {
        singular: "order",
        plural: "orders",
    };

    const rowMarkup = regionReportsData && regionReportsData?.map(
        ({ booking_count, guest_state, state }, index) => {
            return (
                <IndexTable.Row
                    id={index}
                    key={index}
                    position={guest_state}
                >
                     <IndexTable.Cell>
                        <CustomCssComponentTableText
                            innerText={guest_state}
                        ></CustomCssComponentTableText>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                        <CustomCssComponentTableText
                            innerText={booking_count}
                        ></CustomCssComponentTableText>
                    </IndexTable.Cell>
                </IndexTable.Row>
            );
        }
    );

    return (
        <>
            <div className="room-types-full-container">
                <div className="room-types-full-container-div">
                    <div className="room-type-heading">Reservation By Region Report</div>
                </div>

                {regionReportsData && regionReportsData.length > 0 ? (
                    <>
                        <LegacyCard>
                            <IndexTable
                                resourceName={resourceName}
                                itemCount={regionReportsData.length}
                                selectable={false}
                                headings={[
                                    { title: "Guest State" },
                                    { title: "Booking Count" },
                                ]}
                            >
                                {rowMarkup}
                            </IndexTable>
                        </LegacyCard>
                    </>
                ) : (
                    <NoDataFound />
                )}
            </div>
        </>
    );
};
export default RegionReportComp;
