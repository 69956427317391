import React, { useEffect, useState } from "react";
import styles from "./SelectMultiSelect.module.scss";

export const SelectBasic = ({
  label,
  required,
  size,
  options,
  disableOptions,
  disabled,
  error,
  errorText,
  optionKeyName,
  selectedKeyName,
  defaultSelected,
  handleSelectTagChange,
  showKeyName,
  placeholder,
  emptyOption,
  style,
}) => {
  useEffect(() => {
    const getSelectedOption = () => {
      if (defaultSelected && !selectedOption) {
        if (typeof defaultSelected === "object" && optionKeyName) {
          return defaultSelected[optionKeyName];
        } else {
          return defaultSelected;
        }
      } else {
        return "";
      }
    };
    setSelectedOption(getSelectedOption());
  }, [defaultSelected]);

  const getSelectedOption = () => {
    if (defaultSelected) {
      if (typeof defaultSelected === "object" && optionKeyName) {
        return defaultSelected[optionKeyName];
      } else {
        return defaultSelected;
      }
    } else {
      return "";
    }
  };

  const [selectedOption, setSelectedOption] = React.useState(
    getSelectedOption()
  );

  const getSvgSize = () => {
    switch (size) {
      case "large":
        return 16;
      case "medium":
        return 14;
      case "small":
        return 12;
      default:
        return 16;
    }
  };

  const optionContainerReference = React.useRef(null);

  const handleOptionClick = (e, option) => {
    e.stopPropagation(); // Prevent any other onClick events from triggering

    let selectedValue;

    if (selectedKeyName !== undefined && selectedKeyName !== null) {
      selectedValue = option[selectedKeyName];
      handleSelectTagChange(selectedValue);

      setSelectedOption(showKeyName ? option[showKeyName] : selectedValue);
      optionContainerReference.current.style.display = "none";
      svgReference.current.classList.remove(styles.rotated);
    } else {
      selectedValue = option;
      handleSelectTagChange(selectedValue);
      setSelectedOption(selectedValue);
      optionContainerReference.current.style.display = "none";
      svgReference.current.classList.remove(styles.rotated);
    }
  };

  const parentContainerReference = React.useRef(null);
  const svgReference = React.useRef(null);

  const handleToggleButton = () => {
    const container = optionContainerReference.current;
    const svgIcon = svgReference.current;

    container.style.display =
      container.style.display === "none" ? "block" : "none";

    if (container.style.display === "block") {
      svgIcon.classList.add(styles.rotated);
    } else {
      svgIcon.classList.remove(styles.rotated);
    }
  };

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        optionContainerReference.current &&
        !optionContainerReference.current.contains(event.target) &&
        !parentContainerReference.current.contains(event.target)
      ) {
        optionContainerReference.current.style.display = "none";
        svgReference.current.classList.remove(styles.rotated);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.selectTagContainer} style={{ width: style?.width }}>
      {label && (
        <div className={styles.label}>
          <label
            className={
              size === "small"
                ? styles.small
                : size === "medium"
                ? styles.medium
                : styles.large
            }
          >
            {label} {required && <sup style={{ color: "red" }}>*</sup>}
          </label>
        </div>
      )}
      <div
        className={`${styles.optionsParentContainer} ${
          size === "small"
            ? styles.small
            : size === "medium"
            ? styles.medium
            : styles.large
        } 
        
        ${disabled ? styles.disabled : ""}

        
        `}
        tabIndex="0"
        style={error ? { borderColor: "red" } : {}}
        onClick={!disabled ? handleToggleButton : () => {}}
        ref={parentContainerReference}
      >
        {selectedOption ? (
          <span>{selectedOption}</span>
        ) : (
          <span> {placeholder ? placeholder : "Select"}</span>
        )}

        <svg
          width={getSvgSize(size)}
          height={getSvgSize(size)}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`${styles.svgIcon}`}
          ref={svgReference}
        >
          <path
            d="M6.95838 10.5625C6.79232 10.5625 6.62123 10.4971 6.49543 10.3713L2.11753 5.99841C1.86089 5.74177 1.86089 5.32411 2.11753 5.06748C2.37416 4.81084 2.79182 4.81084 3.04846 5.06748L6.95838 8.9774L10.8683 5.06748C11.1249 4.81084 11.5376 4.81084 11.7942 5.06748C12.0508 5.32411 12.0508 5.74177 11.7942 5.99338L7.4163 10.3713C7.2905 10.4971 7.1194 10.5625 6.95335 10.5625H6.95838Z"
            fill="#ADADAD"
          />
        </svg>

        {disabled ? null : (
          <div
            className={styles.optionsContainer}
            ref={optionContainerReference}
          >
            {optionKeyName !== undefined ? (
              <>
                {emptyOption ? (
                  <div className={styles.option}>
                    <button onClick={(e) => handleOptionClick(e, "")}>
                      select
                    </button>
                  </div>
                ) : null}

                {options?.map((option, index) => (
                  <div key={index} className={styles.option}>
                    <button
                      onClick={(e) => handleOptionClick(e, option)}
                      disabled={disableOptions?.includes(option[optionKeyName])}
                      style={
                        selectedOption === option[optionKeyName]
                          ? { color: "#3968ED" }
                          : {}
                      }
                    >
                      {option[optionKeyName]}
                    </button>
                  </div>
                ))}
              </>
            ) : (
              <>
                {emptyOption ? (
                  <div className={styles.option}>
                    <button onClick={(e) => handleOptionClick(e, "")}>
                      select
                    </button>
                  </div>
                ) : null}
                {options?.map((option, index) => (
                  <div
                    key={index}
                    className={styles.option}
                    onClick={(e) => handleOptionClick(e, option)}
                  >
                    <button
                      onClick={(e) => handleOptionClick(e, option)}
                      disabled={disableOptions?.includes(option)}
                      style={
                        selectedOption === option ? { color: "#3968ED" } : {}
                      }
                    >
                      {option}
                    </button>
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>

      {error && errorText && (
        <div className={styles.error_text}>{errorText}</div>
      )}
    </div>
  );
};

export const SelectMultiple = ({
  label,
  required,
  size,
  options,
  disableOptions,
  disabled,
  error,
  errorText,
  optionKeyName,
  selectedKeyName,
  defaultSelected,
  handleSelectTagChange,
  placeholder,
  selectedOptionsFromParent,
  style,
}) => {
  const getSelectedOptions = () => {
    if (defaultSelected && Array.isArray(defaultSelected)) {
      if (optionKeyName) {
        return defaultSelected?.map((opt) => opt[optionKeyName]);
      } else {
        return defaultSelected;
      }
    } else {
      return [];
    }
  };
  React.useEffect(() => {
    // const getSelectedOptions = () => {
    //   if (defaultSelected && Array.isArray(defaultSelected)) {
    //     if (optionKeyName) {
    //       return defaultSelected?.map((opt) => opt[optionKeyName]);
    //     } else {
    //       return defaultSelected;
    //     }
    //   } else {
    //     return [];
    //   }
    // };

    setSelectedOptions(getSelectedOptions);
  }, [defaultSelected]);

  const [selectedOptions, setSelectedOptions] = React.useState("");

  const getSvgSize = () => {
    switch (size) {
      case "large":
        return 16;
      case "medium":
        return 14;
      case "small":
        return 12;
      default:
        return 16;
    }
  };

  const handleOptionClick = (e, option) => {
    e.stopPropagation();
    const optionValue = optionKeyName ? option[optionKeyName] : option;
    let newSelectedOptions;

    if (selectedOptions.includes(optionValue)) {
      newSelectedOptions = selectedOptions.filter(
        (selected) => selected !== optionValue
      );
    } else {
      newSelectedOptions = [...selectedOptions, optionValue];
    }

    setSelectedOptions(newSelectedOptions);
    handleSelectTagChange(newSelectedOptions);
  };

  const optionContainerReference = React.useRef(null);
  const parentContainerReference = React.useRef(null);
  const svgReference = React.useRef(null);

  const handleToggleButton = () => {
    const container = optionContainerReference.current;
    const svgIcon = svgReference.current;

    container.style.display =
      container.style.display === "none" ? "block" : "none";

    if (container.style.display === "block") {
      svgIcon.classList.add(styles.rotated);
    } else {
      svgIcon.classList.remove(styles.rotated);
    }
  };

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        optionContainerReference.current &&
        !optionContainerReference.current.contains(event.target) &&
        !parentContainerReference.current.contains(event.target)
      ) {
        optionContainerReference.current.style.display = "none";
        svgReference.current.classList.remove(styles.rotated);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.selectTagContainer} style={{ width: style?.width }}>
      {label && (
        <div className={styles.label}>
          <label
            className={
              size === "small"
                ? styles.small
                : size === "medium"
                ? styles.medium
                : styles.large
            }
          >
            {label} {required && <sup style={{ color: "red" }}>*</sup>}
          </label>
        </div>
      )}
      <div
        className={`${styles.optionsParentContainer} ${
          size === "small"
            ? styles.small
            : size === "medium"
            ? styles.medium
            : styles.large
        } 
        ${disabled ? styles.disabled : ""}
        `}
        tabIndex="0"
        style={error ? { borderColor: "red" } : {}}
        ref={parentContainerReference}
        onClick={disabled ? null : handleToggleButton}
      >
        {selectedOptions.length === 0
          ? placeholder
          : `${selectedOptions.length} of ${options.length} selected`}
        <svg
          width={getSvgSize(size)}
          height={getSvgSize(size)}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.svgIcon}
          ref={svgReference}
        >
          <path
            d="M6.95838 10.5625C6.79232 10.5625 6.62123 10.4971 6.49543 10.3713L2.11753 5.99841C1.86089 5.74177 1.86089 5.32411 2.11753 5.06748C2.37416 4.81084 2.79182 4.81084 3.04846 5.06748L6.95838 8.9774L10.8683 5.06748C11.1249 4.81084 11.5376 4.81084 11.7942 5.06748C12.0508 5.32411 12.0508 5.74177 11.7942 5.99338L7.4163 10.3713C7.2905 10.4971 7.1194 10.5625 6.95335 10.5625H6.95838Z"
            fill="#ADADAD"
          />
        </svg>
        {disabled ? null : (
          <div
            className={styles.optionsContainer}
            ref={optionContainerReference}
          >
            {optionKeyName !== undefined ? (
              <>
                {options?.map((option, index) => (
                  <div
                    key={index}
                    className={styles.option}
                    style={
                      selectedOptions?.includes(option[optionKeyName])
                        ? { background: "#F0F4FD" }
                        : {}
                    }
                  >
                    <button
                      className={styles.checkboxContainerButton}
                      onClick={(e) => handleOptionClick(e, option)}
                      disabled={
                        disableOptions &&
                        disableOptions?.includes(option[optionKeyName])
                      }
                      style={
                        selectedOptions &&
                        selectedOptions?.includes(option[optionKeyName])
                          ? { color: "#3968ED" }
                          : {}
                      }
                    >
                      <input
                        type="checkbox"
                        checked={
                          selectedOptions &&
                          selectedOptions?.includes(option[optionKeyName])
                        }
                        disabled={
                          disableOptions &&
                          disableOptions?.includes(option[optionKeyName])
                        }
                        onChange={(e) => handleOptionClick(e, option)}
                      />{" "}
                      {option[optionKeyName]}
                    </button>
                  </div>
                ))}
              </>
            ) : (
              <>
                {options?.map((option, index) => (
                  <div
                    key={index}
                    className={styles.option}
                    onClick={(e) => handleOptionClick(e, option)}
                    style={
                      selectedOptions && selectedOptions?.includes(option)
                        ? { background: "#F0F4FD" }
                        : {}
                    }
                  >
                    <input
                      type="checkbox"
                      checked={
                        selectedOptions && selectedOptions?.includes(option)
                      }
                      disabled={
                        disableOptions && disableOptions?.includes(option)
                      }
                      onChange={(e) => handleOptionClick(e, option)}
                    />
                    <button
                      onClick={(e) => handleOptionClick(e, option)}
                      disabled={
                        disableOptions && disableOptions?.includes(option)
                      }
                      className={styles.checkboxContainerButton}
                      style={
                        selectedOptions?.includes(option)
                          ? { color: "#3968ED" }
                          : {}
                      }
                    >
                      {option}
                    </button>
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>

      {error && errorText && (
        <div className={styles.error_text}>{errorText}</div>
      )}
    </div>
  );
};

export const SelectMultipleWithSearch = ({
  label = "Label",
  required = true,
  size,
  options = [{ name: "option 1" }, { name: "option 2" }, { name: "option 3" }],
  disableOptions = ["option 1"],
  disabled,
  error,
  errorText = "Please select at least one option",
  optionKeyName = "name",
  selectedKeyName,
  defaultSelected = [{ name: "option 1" }],
  handleSelectTagChange,
  style,
}) => {
  const getSelectedOptions = () => {
    if (defaultSelected && Array.isArray(defaultSelected)) {
      if (optionKeyName) {
        return defaultSelected?.map((opt) => opt[optionKeyName]);
      } else {
        return defaultSelected;
      }
    } else {
      return [];
    }
  };

  const [selectedOptions, setSelectedOptions] = React.useState(
    getSelectedOptions()
  );

  const getSvgSize = () => {
    switch (size) {
      case "large":
        return 16;
      case "medium":
        return 14;
      case "small":
        return 12;
      default:
        return 16;
    }
  };
  const getSvgSizeSearch = () => {
    switch (size) {
      case "large":
        return 13;
      case "medium":
        return 12;
      case "small":
        return 11;
      default:
        return 13;
    }
  };

  const handleOptionClick = (e, option) => {
    e.stopPropagation();
    const optionValue = optionKeyName ? option[optionKeyName] : option;
    let newSelectedOptions;
    if (selectedOptions?.includes(optionValue)) {
      newSelectedOptions = selectedOptions.filter(
        (selected) => selected !== optionValue
      );
    } else {
      newSelectedOptions = [...selectedOptions, optionValue];
    }
    setSelectedOptions(newSelectedOptions);
  };

  React.useEffect(() => {
    if (
      selectedKeyName !== undefined ||
      selectedKeyName !== null ||
      selectedKeyName !== ""
    ) {
      handleSelectTagChange(
        options.filter((option) =>
          selectedOptions?.includes(option[selectedKeyName])
        )
      );
    } else {
      handleSelectTagChange(selectedOptions);
    }
  }, [selectedKeyName, selectedOptions]);

  const getHighlightedText = (text, highlight) => {
    const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
    return parts?.map((part, index) =>
      part?.toLowerCase().trim().replaceAll(" ", "") ===
      highlight?.toLowerCase().trim().replaceAll(" ", "") ? (
        <span key={index} style={{ color: "#3968ED" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const [searchTerm, setSearchTerm] = React.useState("");

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleSelectAll = (e) => {
    e.stopPropagation();

    if (optionKeyName) {
      setSelectedOptions(
        options
          .filter((option) => !disableOptions?.includes(option[optionKeyName]))
          ?.map((option) => option[optionKeyName])
      );
    } else {
      setSelectedOptions(
        options.filter((option) => !disableOptions?.includes(option))
      );
    }
  };
  const handleClearAll = (e) => {
    e.stopPropagation();
    setSelectedOptions([]);
    setSearchTerm("");
  };

  const optionContainerReference = React.useRef(null);
  const parentContainerReference = React.useRef(null);
  const svgReference = React.useRef(null);

  const handleToggleButton = () => {
    const container = optionContainerReference.current;
    const svgIcon = svgReference.current;

    container.style.display =
      container.style.display === "none" ? "block" : "none";

    if (container.style.display === "block") {
      svgIcon.classList.add(styles.rotated);
    } else {
      svgIcon.classList.remove(styles.rotated);
    }
  };

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        optionContainerReference.current &&
        !optionContainerReference.current.contains(event.target) &&
        !parentContainerReference.current.contains(event.target)
      ) {
        optionContainerReference.current.style.display = "none";
        svgReference.current.classList.remove(styles.rotated);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.selectTagContainer} style={{ width: style?.width }}>
      {label && (
        <div className={styles.label}>
          <label
            className={
              size === "small"
                ? styles.small
                : size === "medium"
                ? styles.medium
                : styles.large
            }
          >
            {label} {required && <sup style={{ color: "red" }}>*</sup>}
          </label>
        </div>
      )}
      <div
        className={`${styles.optionsParentContainer} ${
          size === "small"
            ? styles.small
            : size === "medium"
            ? styles.medium
            : styles.large
        } 
        ${disabled ? styles.disabled : ""}
        `}
        tabIndex="0"
        style={error ? { borderColor: "red" } : {}}
        ref={parentContainerReference}
        onClick={disabled ? () => {} : handleToggleButton}
      >
        {selectedOptions.length} of {options.length} selected
        <svg
          width={getSvgSize(size)}
          height={getSvgSize(size)}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.svgIcon}
          ref={svgReference}
        >
          <path
            d="M6.95838 10.5625C6.79232 10.5625 6.62123 10.4971 6.49543 10.3713L2.11753 5.99841C1.86089 5.74177 1.86089 5.32411 2.11753 5.06748C2.37416 4.81084 2.79182 4.81084 3.04846 5.06748L6.95838 8.9774L10.8683 5.06748C11.1249 4.81084 11.5376 4.81084 11.7942 5.06748C12.0508 5.32411 12.0508 5.74177 11.7942 5.99338L7.4163 10.3713C7.2905 10.4971 7.1194 10.5625 6.95335 10.5625H6.95838Z"
            fill="#ADADAD"
          />
        </svg>
        {disabled ? null : (
          <div
            className={`${styles.optionsContainer} ${styles.optionsContainerWithSearch}`}
            ref={optionContainerReference}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.multiselectSearchInputButtonContainer}>
              <div className={styles.multiselectSearchContainer}>
                <svg
                  width={getSvgSizeSearch(size)}
                  height={getSvgSizeSearch(size)}
                  viewBox="0 0 12 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.0667 12.5L6.86667 8.3C6.53333 8.56667 6.15 8.77778 5.71667 8.93333C5.28333 9.08889 4.82222 9.16667 4.33333 9.16667C3.12222 9.16667 2.09722 8.74722 1.25833 7.90833C0.419445 7.06944 0 6.04444 0 4.83333C0 3.62222 0.419445 2.59722 1.25833 1.75833C2.09722 0.919445 3.12222 0.5 4.33333 0.5C5.54444 0.5 6.56944 0.919445 7.40833 1.75833C8.24722 2.59722 8.66667 3.62222 8.66667 4.83333C8.66667 5.32222 8.58889 5.78333 8.43333 6.21667C8.27778 6.65 8.06667 7.03333 7.8 7.36667L12 11.5667L11.0667 12.5ZM4.33333 7.83333C5.16667 7.83333 5.875 7.54167 6.45833 6.95833C7.04167 6.375 7.33333 5.66667 7.33333 4.83333C7.33333 4 7.04167 3.29167 6.45833 2.70833C5.875 2.125 5.16667 1.83333 4.33333 1.83333C3.5 1.83333 2.79167 2.125 2.20833 2.70833C1.625 3.29167 1.33333 4 1.33333 4.83333C1.33333 5.66667 1.625 6.375 2.20833 6.95833C2.79167 7.54167 3.5 7.83333 4.33333 7.83333Z"
                    fill="#858585"
                  />
                </svg>

                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  placeholder="Search..."
                />
              </div>
              <div className={styles.multiselectClearAllSelectAllContainer}>
                <button onClick={handleClearAll}>Clear All </button>
                <button onClick={handleSelectAll}>Select All </button>
              </div>
            </div>

            <div className={styles.multiselectSearchFilterOptionContainer}>
              {optionKeyName !== undefined ? (
                <>
                  {options
                    ?.filter((option) =>
                      option[optionKeyName]
                        .trim()
                        .replace(/\s+/g, " ")
                        .toLowerCase()

                        ?.includes(
                          searchTerm.trim().replace(/\s+/g, " ").toLowerCase()
                        )
                    )
                    ?.map((option, index) => (
                      <div
                        key={index}
                        className={styles.option}
                        onClick={(e) => handleOptionClick(e, option)}
                        style={
                          selectedOptions?.includes(option[optionKeyName])
                            ? { background: "#F0F4FD" }
                            : {}
                        }
                      >
                        <input
                          type="checkbox"
                          checked={selectedOptions?.includes(
                            option[optionKeyName]
                          )}
                          disabled={disableOptions?.includes(
                            option[optionKeyName]
                          )}
                          value={""}
                          onChange={() => {}}
                        />
                        <button
                          disabled={disableOptions?.includes(
                            option[optionKeyName]
                          )}
                          style={
                            selectedOptions?.includes(option)
                              ? { color: "#3968ED" }
                              : {}
                          }
                        >
                          {getHighlightedText(
                            option[optionKeyName],
                            searchTerm
                          )}
                        </button>
                      </div>
                    ))}
                </>
              ) : (
                <>
                  {options
                    ?.filter((option) =>
                      option
                        ?.trim()
                        ?.replace(/\s+/g, " ")
                        ?.toLowerCase()

                        ?.includes(
                          searchTerm
                            ?.trim()
                            ?.replace(/\s+/g, " ")
                            ?.toLowerCase()
                        )
                    )

                    ?.map((option, index) => (
                      <div
                        key={index}
                        className={styles.option}
                        onClick={(e) => handleOptionClick(e, option)}
                        style={
                          selectedOptions?.includes(option)
                            ? { background: "#F0F4FD" }
                            : {}
                        }
                      >
                        <input
                          type="checkbox"
                          checked={
                            selectedOptions && selectedOptions?.includes(option)
                          }
                          disabled={
                            disableOptions && disableOptions?.includes(option)
                          }
                          value={""}
                          onChange={() => {}}
                        />
                        <button
                          disabled={
                            disableOptions && disableOptions?.includes(option)
                          }
                          style={
                            selectedOptions?.includes(option)
                              ? { color: "#3968ED" }
                              : {}
                          }
                        >
                          {getHighlightedText(option, searchTerm)}
                        </button>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {error && errorText && (
        <div className={styles.error_text}>{errorText}</div>
      )}
    </div>
  );
};

export const SelectBasicWithSelectTag = ({
  label,
  name,
  onChange,
  value,
  type,
  options,
  optionOnclick,
  required,
  disabled,
  style,
  error,
  size,
}) => {
  const [rotate, setRotate] = useState();
  const handleRotate = () => {
    setRotate(!rotate);
  };

  const getSvgSizeSearch = () => {
    switch (size) {
      case "large":
        return 13;
      case "medium":
        return 12;
      case "small":
        return 11;
      default:
        return 13;
    }
  };

  return (
    <div className={styles.selectTagContainer} style={{ width: style?.width }}>
      {label && (
        <div className={styles.label}>
          <label
            className={
              size === "small"
                ? styles.small
                : size === "medium"
                ? styles.medium
                : styles.large
            }
          >
            {label} {required && <sup style={{ color: "red" }}>*</sup>}
          </label>
        </div>
      )}

      <div
        className={`${styles.selectWithTagsWrapper} select`}
        onClick={handleRotate}
      >
        <select
          disabled={disabled}
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          className={`${styles.optionsParentContainer} ${
            styles.selectWithTags
          } ${
            size === "small"
              ? styles.small
              : size === "medium"
              ? styles.medium
              : size === "large"
              ? styles.large
              : null
          }`}
          // style={style}
          style={{
            ...style,
            width: style && style?.width ? style?.width : "100%",
            border: error ? "1px solid red" : "",
            borderRadius: "0.25rem",
            paddingLeft: "5px",
            backgroundColor: error ? "#FFEAEA" : "",
          }}
          required={required}
        >
          {options?.map((option, index) => {
            return (
              <option onClick={optionOnclick} key={index} value={option?.value}>
                {option?.label}
              </option>
            );
          })}
        </select>

        <svg
          className={`${rotate ? styles.up : styles.down}`}
          width={getSvgSizeSearch(size)}
          height={getSvgSizeSearch(size)}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          // className={styles.svgIcon}
          // ref={svgReference}
        >
          <path
            d="M6.95838 10.5625C6.79232 10.5625 6.62123 10.4971 6.49543 10.3713L2.11753 5.99841C1.86089 5.74177 1.86089 5.32411 2.11753 5.06748C2.37416 4.81084 2.79182 4.81084 3.04846 5.06748L6.95838 8.9774L10.8683 5.06748C11.1249 4.81084 11.5376 4.81084 11.7942 5.06748C12.0508 5.32411 12.0508 5.74177 11.7942 5.99338L7.4163 10.3713C7.2905 10.4971 7.1194 10.5625 6.95335 10.5625H6.95838Z"
            fill="#ADADAD"
          />
        </svg>
      </div>
    </div>
  );
};
// =========================================================================
